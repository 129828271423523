import React, { useEffect, useState, Fragment } from "react";
import { withRouter, Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { CATEGORIES_QUERY } from "../../../../Config/Queries";

import styled, { css } from "styled-components";
import { useForm } from "react-hook-form";
import Button from "../../../../Components/Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { ArrowDown2, ArrowUp2 } from "iconsax-react";
import qs from "qs";
import {
    faRedoAlt,
    faAngleDown,
    faAngleUp,
} from "@fortawesome/pro-light-svg-icons";

const SOptionTab = styled.div`
    ${({ theme }) => {
        return css`
            border-bottom: solid 1px #eee;
            .nav {
                display: flex;
                flex-direction: row;
                li {
                    background-color: #fff;
                    position: relative;
                    display: flex;
                    border-right: solid 1px #eee;
                    flex: 1 0 50%;
                    :first-child {
                        border-right: solid 1px #f5f5f5;
                    }
                    button {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        background-color: #fff;
                        padding: 0 15px;
                        width: 100%;
                        height: 40px;
                        line-height: 40px;
                        color: ${theme.colors.black04};
                        font-size: 14px;
                        font-weight: ${theme.fonts.weight.medium};
                        text-align: left;
                        &.active {
                            background-color: #f5f5f5;
                            color: ${theme.colors.black01};
                            font-weight: ${theme.fonts.weight.bold};
                        }
                    }
                }
            }
            .tabpane {
                display: none;
                &.open {
                    display: block;
                }
            }

            .redo-btn {
                background-color: #fff;
                border: solid 1px #eee;
                border-radius: 20px;
                color: ${theme.colors.black02};
                padding: ${theme.paddings.base};
                font-weight: ${theme.fonts.weight.medium};
                svg {
                    margin-right: 5px;
                }
            }
        `;
    }}
`;
const InputGroup = styled.div`
    ${({ theme }) => {
        return css`
            display: flex;
            flex-direction: row;
            padding: 10px 5px;
            .form-control {
                background-color: white;
                width: 1%;
                height: 40px;
                border: solid 1px #eee;
                border-radius: 5px;
                font-size: 14px;
                flex: 1 0 auto;
                padding: 0 8px;
            }
            span {
                flex: 0 0 auto;
                margin: 0 5px;
                height: 40px;
                line-height: 40px;
                font-size: 18px;
                color: ${theme.colors.black04};
                text-align: center;
            }
            button {
                flex: 0 0 auto;
                margin-left: 5px;
                height: 40px;
                border-radius: 5px;
                color: ${theme.colors.black01};
            }
        `;
    }}
`;
const SCategory = styled.div`
    ${({ theme }) => {
        return css`
            background-color: ${theme.colors.lightgray};
            padding: 15px 24px;
            ul {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                li {
                    margin: 0 10px 10px 0;
                    button {
                        background-color: white;
                        border: solid 1px #eee;
                        overflow: hidden;
                        border-radius: 20px;
                        flex: 1;
                        justify-content: center;
                        text-align: center;
                        line-height: 34px;
                        padding: 0 12px;
                        font-size: 14px;
                        color: ${theme.colors.black02};
                        font-weight: ${theme.fonts.weight.medium};
                        &.active {
                            color: ${theme.colors.primary};
                            border: solid 1px ${theme.colors.primary};
                            font-weight: ${theme.fonts.weight.bold};
                        }
                    }
                }
            }
        `;
    }}
`;

function SearchOption({ history, match, location }) {
    const { register, handleSubmit, errors, setValue } = useForm();
    const queryString = qs.parse(location.search.substr(1));
    const priceStart = queryString.priceStart ? queryString.priceStart : null;
    const priceEnd = queryString.priceEnd ? queryString.priceEnd : null;
    const [priceDisplayText, setPriceDisplayText] = useState("전체 가격대");
    const [categoryDisplayText, setCategoryDisplayText] = useState("전체 분류");
    const depth1 = queryString.depth1 ? queryString.depth1 : null;
    const depth2 = queryString.depth2 ? queryString.depth2 : null;
    const orderBy = queryString.orderBy ? queryString.orderBy : null;

    const [activeOption, setActiveOption] = useState("");
    const handleToggle = (index) => {
        if (activeOption === index) {
            setActiveOption(null);
        } else {
            setActiveOption(index);
        }
    };

    const { data, loading } = useQuery(CATEGORIES_QUERY, {
        fetchPolicy: "network-only",
        variables: {
            productType: "Product",
            parentId: null,
        },
    });

    const priceChange = ({ start, end }) => {
        const uri =
            "?" +
            (start ? "priceStart=" + start + "&" : "") +
            (end ? "priceEnd=" + end + "&" : "") +
            (depth1 ? "depth1=" + depth1 + "&" : "") +
            (depth2 ? "depth2=" + depth2 + "&" : "") +
            (orderBy ? "orderBy=" + orderBy + "&" : "");
        history.push(`/products${uri}`);
    };
    const onSubmit = (data) => {
        console.log({ data });
        if (data.priceStart === "") {
            toast.error("최소가격을 입력해주세요.");
            return;
        }
        if (data.priceEnd === "") {
            toast.error("최대가격를 입력해주세요.");
            return;
        }
        if (parseInt(data.priceStart) > parseInt(data.priceEnd)) {
            toast.error("최소가격은 최대 가격보다 적어야 합니다.");
            return;
        }
        const uri =
            "?" +
            (data.priceStart ? "priceStart=" + data.priceStart + "&" : "") +
            (data.priceEnd ? "priceEnd=" + data.priceEnd + "&" : "") +
            (depth1 ? "depth1=" + depth1 + "&" : "") +
            (depth2 ? "depth2=" + depth2 + "&" : "") +
            (orderBy ? "orderBy=" + orderBy + "&" : "");
        history.push(`/products${uri}`);
    };
    const priceInit = () => {
        setPriceDisplayText(`가격대별 분류`);

        const uri =
            "?" +
            (depth1 ? "depth1=" + depth1 + "&" : "") +
            (depth2 ? "depth2=" + depth2 + "&" : "") +
            (orderBy ? "orderBy=" + orderBy + "&" : "");
        history.push(`/products${uri}`);
    };
    const categoryChange = ({ category1, category2 }) => {
        if (category1 === null && category2 === null) {
            setCategoryDisplayText(`지역별 분류`);
        }

        const uri =
            "?" +
            (priceStart ? "priceStart=" + priceStart + "&" : "") +
            (priceEnd ? "priceEnd=" + priceEnd + "&" : "") +
            (category1 ? "depth1=" + category1 + "&" : "") +
            (category2 ? "depth2=" + category2 + "&" : "") +
            (orderBy ? "orderBy=" + orderBy + "&" : "");
        history.push(`/products${uri}`);
    };

    useEffect(() => {
        setValue("priceStart", priceStart);
        setValue("priceEnd", priceEnd);
        if (priceStart && priceEnd) {
            setPriceDisplayText(`${priceStart}드림 ~ ${priceEnd}드림`);
        }
    }, [priceStart, priceEnd]);
    useEffect(() => {
        if (!loading) {
            if (depth1) {
                const category1 =
                    data.GiftProductCategoryList.giftCategories.find(
                        (find) => find.id === depth1
                    );
                if (category1.childrenCount === 0) {
                    setCategoryDisplayText(`${category1.name}`);
                    //   setCategoryDisplayText(`${category1.name}(
                    //         ${category1.giftProductsCount ? category1.giftProductsCount : 0}
                    //         건)`);
                }
                if (category1.childrenCount > 0) {
                    const category2 = category1.children.find(
                        (find) => find.id === depth2
                    );
                    if (category2) {
                        setCategoryDisplayText(
                            `${category1.name} > ${category2.name}`
                        );
                        // setCategoryDisplayText(
                        //   `${category1.name} > ${category2.name}(${
                        //     category2.giftProductsCount ? category2.giftProductsCount : 0
                        //   }건)`
                        // );
                    }
                }
            }
        }
    }, [data, loading, depth1, depth2]);
    const tabs = [
        { id: "Price", name: "가격대별 분류" },
        { id: "Category", name: "지역별 분류" },
    ];
    return (
        <SOptionTab>
            <ul className="nav">
                {tabs.map((item, index) => {
                    return (
                        <>
                            <li>
                                <button
                                    className={`${
                                        activeOption === item.id ? "active" : ""
                                    }`}
                                    onClick={() => handleToggle(item.id)}
                                >
                                    {item.name}
                                    {activeOption === item.id ? (
                                        <FontAwesomeIcon
                                            icon={faAngleUp}
                                            color="#333"
                                            size="lg"
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={faAngleDown}
                                            color="#999"
                                            size="lg"
                                        />
                                    )}
                                </button>
                            </li>
                        </>
                    );
                })}
            </ul>
            <div
                className={`tabpane ${activeOption === "Price" ? "open" : ""}`}
            >
                <SCategory>
                    <ul>
                        <li>
                            <button
                                className={`${
                                    priceStart === "0" && priceEnd === "30000"
                                        ? `active`
                                        : ``
                                }`}
                                onClick={() => {
                                    priceChange({ start: "0", end: "30000" });
                                }}
                            >
                                3만원 이하
                            </button>
                        </li>
                        <li>
                            <button
                                className={`${
                                    priceStart === "30000" &&
                                    priceEnd === "70000"
                                        ? `active`
                                        : ``
                                }`}
                                onClick={() => {
                                    priceChange({
                                        start: "30000",
                                        end: "70000",
                                    });
                                }}
                            >
                                3만원 ~ 7만원
                            </button>
                        </li>
                        <li>
                            <button
                                className={`${
                                    priceStart === "70000" &&
                                    priceEnd === "150000"
                                        ? `active`
                                        : ``
                                }`}
                                onClick={() => {
                                    priceChange({
                                        start: "70000",
                                        end: "150000",
                                    });
                                }}
                            >
                                7만원 ~ 15만원
                            </button>
                        </li>
                        <li>
                            <button
                                className={`${
                                    priceStart === "150000" &&
                                    priceEnd === "300000"
                                        ? `active`
                                        : ``
                                }`}
                                onClick={() => {
                                    priceChange({
                                        start: "150000",
                                        end: "300000",
                                    });
                                }}
                            >
                                15만원 ~ 30만원
                            </button>
                        </li>
                    </ul>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <InputGroup>
                            <input
                                type="number"
                                name="priceStart"
                                placeholder="최소가격 입력"
                                ref={register()}
                                className="form-control"
                            />
                            <span>~</span>
                            <input
                                type="number"
                                name="priceEnd"
                                placeholder="최대가격 입력"
                                ref={register()}
                                className="form-control"
                            />
                            <Button color="white">검색</Button>
                        </InputGroup>
                        <button
                            type="button"
                            className="redo-btn"
                            onClick={() => priceInit()}
                        >
                            <FontAwesomeIcon icon={faRedoAlt} />
                            재설정
                        </button>
                    </form>
                </SCategory>
            </div>
            <div
                className={`tabpane ${
                    activeOption === "Category" ? "open" : ""
                }`}
            >
                {!loading && (
                    <SCategory>
                        <ul>
                            <li>
                                <button
                                    className={`${
                                        depth1 === null && depth2 === null
                                            ? `active`
                                            : ``
                                    }`}
                                    onClick={() => {
                                        categoryChange({
                                            category1: null,
                                            category2: null,
                                        });
                                    }}
                                >
                                    전체
                                </button>
                            </li>
                            {data.GiftProductCategoryList.giftCategories.map(
                                (category1, index1) => {
                                    return (
                                        <Fragment key={index1}>
                                            {category1.childrenCount === 0 && (
                                                <li>
                                                    <button
                                                        className={`${
                                                            depth1 ===
                                                                category1.id &&
                                                            depth2 === null
                                                                ? `active`
                                                                : ``
                                                        }`}
                                                        onClick={() => {
                                                            categoryChange({
                                                                category1:
                                                                    category1.id,
                                                                category2: null,
                                                            });
                                                        }}
                                                    >
                                                        {category1.name}
                                                        {/* (
                                                            {category1.giftProductsCount
                                                            ? category1.giftProductsCount
                                                            : 0}
                                                        건) */}
                                                    </button>
                                                </li>
                                            )}
                                            {category1.childrenCount > 0 &&
                                                category1.children.map(
                                                    (category2, index2) => {
                                                        return (
                                                            <Fragment
                                                                key={index2}
                                                            >
                                                                {category2.childrenCount ===
                                                                    0 && (
                                                                    <li>
                                                                        <button
                                                                            className={`${
                                                                                depth1 ===
                                                                                    category1.id &&
                                                                                depth2 ===
                                                                                    category2.id
                                                                                    ? `active`
                                                                                    : ``
                                                                            }`}
                                                                            onClick={() => {
                                                                                categoryChange(
                                                                                    {
                                                                                        category1:
                                                                                            category1.id,
                                                                                        category2:
                                                                                            category2.id,
                                                                                    }
                                                                                );
                                                                            }}
                                                                        >
                                                                            {
                                                                                category1.name
                                                                            }{" "}
                                                                            &gt;{" "}
                                                                            {
                                                                                category2.name
                                                                            }
                                                                            {/* (
                                    {category2.giftProductsCount
                                      ? category2.giftProductsCount
                                      : 0}
                                    건) */}
                                                                        </button>
                                                                    </li>
                                                                )}
                                                            </Fragment>
                                                        );
                                                    }
                                                )}
                                        </Fragment>
                                    );
                                }
                            )}
                        </ul>
                    </SCategory>
                )}
            </div>
        </SOptionTab>
    );
}

export default withRouter(SearchOption);
