import { useState } from "react"
import { withRouter } from "react-router-dom"

import styled, { css } from "styled-components"
import Button from "../../../Buttons"
import { InputGroup, Select } from "../../../../styles/styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronDown,
  faPlus,
  faMinus,
} from "@fortawesome/pro-regular-svg-icons"
import NumberFormat from "react-number-format"
import { toast } from "react-toastify"
import { ModalLayout } from "../../../Layouts/Layout"
import RowInfo from "../../../RowInfo"

const Selects = styled(Select)`
  ${({ theme }) => {
    return css`
      margin-bottom: 1rem;
      select {
        height: 46px;
      }
    `
  }}
`
const SPayment = styled.div`
  ${({ theme }) => {
    return css`
      padding: ${theme.margins.base} ${theme.paddings.xl};
      .title {
        margin: ${theme.margins.sm} 0;
        font-size: ${theme.fonts.size.sm};
        color: ${theme.colors.black04};
      }
      .price_content {
        margin: ${theme.margins.base} 0;
        .name {
          padding: ${theme.paddings.base} 0;
          font-size: ${theme.fonts.size.base};
        }
        .price_box {
          background-color: ${theme.colors.lightgray};
          border: solid 1px #eee;
          border-top-right-radius: 5px;
          border-top-left-radius: 5px;
          padding: ${theme.paddings.base};
        }
        .price_total {
          background-color: white;
          border: solid 1px #eee;
          padding: ${theme.paddings.sm} ${theme.paddings.base};
          font-size: ${theme.fonts.size.base};
          justify-content: flex-end;
          text-align: right;
          color: #444;
          border-bottom-right-radius: 5px;
          border-bottom-left-radius: 5px;
          b {
            padding-left: 0.5rem;
            font-weight: bold;
          }
        }
      }
      .bottom {
        padding: ${theme.paddings.base} ${theme.paddings.xl};
        a,
        button {
          margin-left: 0 !important;
          height: 60px;
          line-height: 60px;
        }
      }
    `
  }}
`

function Modal({
  history,
  match,
  location,
  isOpen,
  toggleModal,
  id,
  name,
  isDiscount,
  discountPrice,
  price,
  giftProductOptionsCount,
  giftProductOptions,
}) {
  const [productCount, setProductCount] = useState(1)
  const [productPrice, setProductPrice] = useState(price)

  // GiftOrder
  const [totalProductPrice, setTotalProductPrice] = useState(price)
  // const [totalDiscountPrice, setTotalDiscountPrice] = useState(discountPrice);
  // const [totalCount, setTotalCount] = useState(1);
  const [totalPrice, setTotalPrice] = useState(totalProductPrice)

  // GiftOrderProduct
  // isDiscount
  const [giftProductOptionDetail1, setGiftProductOptionDetail1] = useState(null)
  const [giftProductOptionDetailPrice1, setGiftProductOptionDetailPrice1] =
    useState(0)
  const [giftProductOptionDetail2, setGiftProductOptionDetail2] = useState(null)
  const [giftProductOptionDetailPrice2, setGiftProductOptionDetailPrice2] =
    useState(0)
  const [giftProductOptionDetail3, setGiftProductOptionDetail3] = useState(null)
  const [giftProductOptionDetailPrice3, setGiftProductOptionDetailPrice3] =
    useState(0)
  const [giftProductOptionDetail4, setGiftProductOptionDetail4] = useState(null)
  const [giftProductOptionDetailPrice4, setGiftProductOptionDetailPrice4] =
    useState(0)
  const [giftProductOptionDetail5, setGiftProductOptionDetail5] = useState(null)
  const [giftProductOptionDetailPrice5, setGiftProductOptionDetailPrice5] =
    useState(0)
  // price
  const [count, setCount] = useState(1)

  const optionSelect = (index, value) => {
    const giftProductOption = giftProductOptions[
      index
    ].giftProductOptionDetails.find((item) => item.id === value)
    const optionId = giftProductOption ? giftProductOption.id : null
    const optionPrice = giftProductOption ? giftProductOption.price : 0

    eval(`setGiftProductOptionDetail${index + 1}(optionId)`)
    eval(`setGiftProductOptionDetailPrice${index + 1}(optionPrice)`)

    totalSum({
      price,
      optionPrice1: index === 0 ? optionPrice : giftProductOptionDetailPrice1,
      optionPrice2: index === 1 ? optionPrice : giftProductOptionDetailPrice2,
      optionPrice3: index === 2 ? optionPrice : giftProductOptionDetailPrice3,
      optionPrice4: index === 3 ? optionPrice : giftProductOptionDetailPrice4,
      optionPrice5: index === 4 ? optionPrice : giftProductOptionDetailPrice5,
      count,
    })
  }
  const minus = () => {
    if (count <= 1) return
    setCount(count - 1)
    setTotalProductPrice((count - 1) * price)
    // setTotalDiscountPrice((count - 1) * discountPrice);

    setProductCount(count - 1)
    // setTotalCount(count - 1);
    setTotalPrice((count - 1) * price)
    totalSum({
      price,
      optionPrice1: giftProductOptionDetailPrice1,
      optionPrice2: giftProductOptionDetailPrice2,
      optionPrice3: giftProductOptionDetailPrice3,
      optionPrice4: giftProductOptionDetailPrice4,
      optionPrice5: giftProductOptionDetailPrice5,
      count: count - 1,
    })
  }
  const plus = () => {
    setCount(count + 1)
    setTotalProductPrice((count + 1) * price)
    // setTotalDiscountPrice((count + 1) * discountPrice);

    setProductCount(count + 1)
    // setTotalCount(count + 1);
    setTotalPrice((count + 1) * price)
    totalSum({
      price,
      optionPrice1: giftProductOptionDetailPrice1,
      optionPrice2: giftProductOptionDetailPrice2,
      optionPrice3: giftProductOptionDetailPrice3,
      optionPrice4: giftProductOptionDetailPrice4,
      optionPrice5: giftProductOptionDetailPrice5,
      count: count + 1,
    })
  }
  const totalSum = ({
    price,
    optionPrice1,
    optionPrice2,
    optionPrice3,
    optionPrice4,
    optionPrice5,
    count,
  }) => {
    const productPrice =
      price +
      optionPrice1 +
      optionPrice2 +
      optionPrice3 +
      optionPrice4 +
      optionPrice5
    const totalPrice =
      (price +
        optionPrice1 +
        optionPrice2 +
        optionPrice3 +
        optionPrice4 +
        optionPrice5) *
      count

    setProductPrice(productPrice)
    setTotalPrice(totalPrice)
  }

  const onSubmit = () => {
    if (giftProductOptionsCount > 0 && !giftProductOptionDetail1) {
      toast.error(`${giftProductOptions[0].name} 옵션을 선택해주세요.`)
      return
    }
    if (giftProductOptionsCount > 1 && !giftProductOptionDetail2) {
      toast.error(`${giftProductOptions[1].name} 옵션을 선택해주세요.`)
      return
    }
    if (giftProductOptionsCount > 2 && !giftProductOptionDetail3) {
      toast.error(`${giftProductOptions[2].name} 옵션을 선택해주세요.`)
      return
    }
    if (giftProductOptionsCount > 3 && !giftProductOptionDetail4) {
      toast.error(`${giftProductOptions[3].name} 옵션을 선택해주세요.`)
      return
    }
    if (giftProductOptionsCount > 4 && !giftProductOptionDetail5) {
      toast.error(`${giftProductOptions[4].name} 옵션을 선택해주세요.`)
      return
    }

    history.push(`/order`, {
      productType: "Product",
      orderType: "me",
      id,
      giftProductOptionDetail1,
      giftProductOptionDetail2,
      giftProductOptionDetail3,
      giftProductOptionDetail4,
      giftProductOptionDetail5,
      count,
    })
  }

  return (
    <ModalLayout
      isOpen={isOpen}
      toggleModal={toggleModal}
      title={"나에게 선물하기"}
    >
      <SPayment>
        {giftProductOptionsCount > 0 && (
          <>
            <div className="title">옵션선택</div>
            {giftProductOptions.map((option, index) => (
              <Selects>
                <select
                  onChange={(event) => optionSelect(index, event.target.value)}
                >
                  <option value="">{option.name} 선택</option>
                  {option.giftProductOptionDetails.length > 0 && (
                    <>
                      {option.giftProductOptionDetails.map((detail) => (
                        <option
                          key={detail.id}
                          value={detail.id}
                          disabled={detail.isSoldOut ? true : false}
                        >
                          {option.name} : {detail.isSoldOut && "[품절]"}{" "}
                          {detail.name}{" "}
                          {detail.price !== 0 &&
                            `(${
                              detail.price > 0 ? "+" : ""
                            }${detail.price.toLocaleString("ko-KR", {
                              maximumFractionDigits: 4,
                            })}드림)`}
                        </option>
                      ))}
                    </>
                  )}
                </select>
              </Selects>
            ))}
          </>
        )}
        <InputGroup>
          <button onClick={minus}>
            <FontAwesomeIcon icon={faMinus} />
          </button>
          <input value={count} readOnly={true} />
          <button onClick={plus}>
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </InputGroup>
        <div className="price_content">
          <div className="price_box">
            <div className="name">{name}</div>
            <RowInfo
              textLeft={"금액"}
              textRight={
                <>
                  <b>
                    <NumberFormat
                      value={productPrice}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </b>
                  드림
                </>
              }
            />
            <RowInfo
              textLeft={"수량"}
              textRight={
                <>
                  <b>
                    <NumberFormat
                      value={productCount}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </b>
                  개
                </>
              }
            />
          </div>
          <div className="price_total">
            <RowInfo
              total
              textLeft={"총 금액"}
              textRight={
                <>
                  <b>
                    <NumberFormat
                      value={totalPrice}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </b>
                  드림
                </>
              }
            />
          </div>
        </div>
        <div className="bottom">
          <Button onClick={onSubmit} color="warning" width="full">
            구매하기
          </Button>
        </div>
      </SPayment>
    </ModalLayout>
  )
}

export default withRouter(Modal)
