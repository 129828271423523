import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import { useQuery, useMutation } from "@apollo/client"
import {
  INQUIRIES_QUERY,
  INQUIRYDELETE_MUTATION,
  PRODUCT_QUERY,
} from "../../../../../Config/Queries"

import styled, { css } from "styled-components"
import { Container, Ready } from "../../../../../styles/styles"
import Button from "../../../../Buttons"
import Link from "../../../../Links"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPencil, faAngleDown } from "@fortawesome/pro-light-svg-icons"
import Item from "./Item"
import { Edit2 } from "iconsax-react"

const InquiryContainer = styled(Container)`
  ${({ theme }) => {
    return css`
      padding: 0;
      .inquiry_top {
        display: flex;
        flex-direction: row;
        padding: ${theme.paddings.xl};
      }
      .inquiry_bottom {
        padding: ${theme.paddings.base};
      }
      .title {
        padding: ${theme.paddings.base};
        border-bottom: solid 2px #eee;
      }
    `
  }}
`
const InquiryList = styled.div`
  ${({ theme }) => {
    return css`
      padding: 0 ${theme.paddings.xl};
      ul {
        li {
          border-bottom: solid 1px #eee;
          margin-bottom: 15px;
          padding-bottom: 15px;
        }
      }
    `
  }}
`

function Inquiry({ history, match, location, giftProductInquiries, uri }) {
  const id = match.params.id

  const [items, setItems] = useState(giftProductInquiries)
  const [page, setPage] = useState(2)
  const first = 10
  const skip = first * (page - 1)
  const [loadmore, setLoadmore] = useState(false)

  const { data, loading, refetch } = useQuery(INQUIRIES_QUERY, {
    // fetchPolicy: "network-only",
    variables: {
      giftProduct: id,
      orderBy: "id_DESC",
      skip: skip,
      first: first,
    },
  })

  const [inquiryDeleteMutation] = useMutation(INQUIRYDELETE_MUTATION, {
    refetchQueries: () => [
      {
        query: PRODUCT_QUERY,
        variables: {
          productType: "Product",
          id,
        },
      },
    ],
  })

  const onDelete = async (id) => {
    if (!window.confirm("선택한 선물후기를 삭제하시겠습니까?")) {
      return
    }

    try {
      const {
        data: { GiftProductInquiryDelete },
      } = await inquiryDeleteMutation({
        variables: { id },
      })

      if (GiftProductInquiryDelete) {
        setItems([...items.filter((item) => item.id !== id)])
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleLoadMore = () => {
    setLoadmore(true)
    setPage(page + 1)
    refetch()
    if (!loading && data.GiftProductInquiryList.giftProductInquiries) {
      setItems(items.concat(data.GiftProductInquiryList.giftProductInquiries))
    }
    setLoadmore(false)
  }

  return (
    <>
      <InquiryContainer>
        <div className="inquiry_top">
          <Link
            color="primary"
            width="full"
            to={`${uri}/${id}/inquiries/write${location.search}`}
          >
            <Edit2 variant="Linear" size="18" />
            &nbsp; 문의하기
          </Link>
          <Link
            color="primary"
            width="full"
            border="solid"
            to="/account/inquiries"
          >
            내 선물 문의 보기
          </Link>
        </div>
        <InquiryList>
          {items.length > 0 ? (
            <>
              <ul>
                {items.map((item, index) => {
                  return (
                    <li key={index}>
                      <Item item={item} uri={uri} onDelete={onDelete} />
                    </li>
                  )
                })}
              </ul>
              {!loadmore &&
                !loading &&
                data.GiftProductInquiryList.giftProductInquiries.length > 0 && (
                  <div className="inquiry_bottom">
                    <Button
                      color="white"
                      border="radius"
                      width="full"
                      onClick={() => handleLoadMore()}
                    >
                      <FontAwesomeIcon icon={faAngleDown} />
                      &nbsp; 더보기
                    </Button>
                  </div>
                )}
            </>
          ) : (
            <Ready>
              <div className="list_none">등록된 선물문의가 없습니다.</div>
            </Ready>
          )}
        </InquiryList>
      </InquiryContainer>
    </>
  )
}
export default withRouter(Inquiry)
