import { useState } from "react"
import { withRouter } from "react-router-dom"
import { useMutation } from "@apollo/client"
import {
  NAME_BY_USERS_MUTATION,
  ACCOUNT_ORDERPRODUCTSEND_MUTATION,
} from "../../../../../Config/Queries"

import styled, { css } from "styled-components"
import {
  Roundbox,
  Ready,
  SearchContainer,
  SearchList,
  UserList,
} from "../../../../../styles/styles"
import { useForm } from "react-hook-form"
import FormError from "../../../../FormError"
import Button from "../../../../Buttons"
import { graphqlUrl } from "../../../../../Config/Env"
import theme from "../../../../../styles/Theme"
import { toast } from "react-toastify"
import { SearchNormal1 } from "iconsax-react"

function Giftuser({
  history,
  match,
  location,
  user,
  setUser,
  selectUser,
  setSelectUser,
  userSelect,
  userUnSelect,
}) {
  const id = match.params.id

  const [avatarImage, setAvatarImage] = useState(
    "/assets/img/product/product-default.jpg"
  )
  const [searchUsers, setSearchUsers] = useState([])

  const [nameByUsersMutation, { loading: searchLoading }] = useMutation(
    NAME_BY_USERS_MUTATION
  )
  const [orderProductSendMutation, { loading: sendLoading }] = useMutation(
    ACCOUNT_ORDERPRODUCTSEND_MUTATION
  )

  const { register, handleSubmit, errors, setValue } = useForm({
    mode: "onChange",
  })

  const onSubmit = async (data) => {
    try {
      if (searchLoading) {
        return
      }

      const {
        data: {
          GiftProductUserSeach: { users },
        },
      } = await nameByUsersMutation({
        variables: {
          keyword: data.keyword,
        },
      })
      setSearchUsers(users)
      setValue("keyword", "")
      return
    } catch (e) {
      console.log(e)
    }
  }
  const onSend = async () => {
    if (sendLoading) {
      return
    }
    if (!user) {
      toast.error(`선물 받는분을 검색해주세요.`)
      return
    }

    console.log(user)

    const {
      data: { GiftAccountOrderProductSend },
    } = await orderProductSendMutation({
      variables: {
        id,
        user,
      },
    })

    if (GiftAccountOrderProductSend) {
      toast.success(`${selectUser.name}님에게 선물을 보냈습니다.`)
      history.replace(`/account/readyOrderProducts`)
    }
  }

  return (
    <>
      <UserList>
        <ul className="check_list">
          {selectUser ? (
            <li>
              <Roundbox>
                <Button
                  className="close"
                  onClick={() => userUnSelect(selectUser)}
                />
                <div className="img">
                  <img src={avatarImage} alt={selectUser.name} />
                </div>
              </Roundbox>
              <p>{selectUser.name}님</p>
            </li>
          ) : (
            <li className="check_list_done">
              <Roundbox>
                <div className="icon"></div>
              </Roundbox>
              <div className="cont">
                <p>선물 받는분을 선택하세요.</p>
              </div>
            </li>
          )}
        </ul>
      </UserList>
      <SearchContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="inner">
            <Button className="searchBtn">
              <SearchNormal1
                color={theme.colors.black01}
                variant="Linear"
                size={24}
              />
            </Button>
            <input
              type="text"
              name="keyword"
              placeholder="이름 입력"
              ref={register({
                required: "이름를 입력해주세요.",
              })}
            />
          </div>
          <FormError message={errors?.keyword?.message} />
        </form>
      </SearchContainer>
      <SearchList>
        {/* {!loading ? ( */}
        <>
          {searchUsers.length > 0 ? (
            <ul>
              {searchUsers.map((item, index) => {
                const avatarImage =
                  item.avatar === ""
                    ? "/assets/img/user/user-default.jpg"
                    : `${graphqlUrl}${item.avatar}`
                return (
                  <li key={index}>
                    <label>
                      <Roundbox>
                        <div className="img">
                          <img src={avatarImage} alt={item.name} />
                        </div>
                      </Roundbox>
                      <input
                        type="checkbox"
                        onChange={() => {
                          const avatar =
                            item.avatar === ""
                              ? "/assets/img/product/product-default.jpg"
                              : `${graphqlUrl}${item.avatar}`
                          console.log({ avatar, item })
                          userSelect(item)
                          setAvatarImage(avatar)
                        }}
                        checked={user === item.id && true}
                      />
                      <p className="name">
                        {item.role === "User" && (
                          <>
                            {item.name}님<span>({item.userId})</span>
                          </>
                        )}
                        {item.role === "Store" && (
                          <>
                            {item.store.name} - {item.name}님
                            <span>({item.userId})</span>
                          </>
                        )}
                      </p>
                    </label>
                  </li>
                )
              })}
            </ul>
          ) : (
            <Ready>
              <div className="list_none">검색어를 입력해주세요.</div>
            </Ready>
          )}
        </>
        {/* ) : (
          <>
            <Loadings desc={"로딩중입니다."} />
          </>
        )} */}
        <div className="bottom">
          <Button
            disabled={sendLoading}
            onClick={onSend}
            color="primary"
            width="full"
          >
            {sendLoading ? "처리중..." : "확인"}
          </Button>
        </div>
      </SearchList>
    </>
  )
}

export default withRouter(Giftuser)
