import { useState } from "react";
import { withRouter } from "react-router-dom";
import Giftuser from "./Giftuser";
import { ModalLayout } from "../../../../Layouts/Layout";

function Modal({ history, match, location, isOpen, toggleModal }) {
    const [user, setUser] = useState(null);
    const [selectUser, setSelectUser] = useState(null);

    const userSelect = (user) => {
        const userId = user.id;
        const userObject = user;

        setUser(userId);
        setSelectUser(userObject);
    };
    const userUnSelect = (user) => {
        const userIds = null;
        const userObjects = null;

        setUser(userIds);
        setSelectUser(userObjects);
    };

    return (
        <ModalLayout
            isOpen={isOpen}
            toggleModal={toggleModal}
            title={"선물 받는분 선택"}
        >
            <Giftuser
                user={user}
                setUser={setUser}
                selectUser={selectUser}
                setSelectUser={setSelectUser}
                userSelect={userSelect}
                userUnSelect={userUnSelect}
            />
        </ModalLayout>
    );
}

export default withRouter(Modal);
