import React from "react";
import { withRouter, Link } from "react-router-dom";

import { Row, Col, SOrderItem } from "../../../../../styles/styles";
import Links from "../../../../Links";
import Label from "../../../../Labels";
import { graphqlUrl } from "../../../../../Config/Env";
import ReactMoment from "react-moment";
import NumberFormat from "react-number-format";
import renderHTML from "react-render-html";
import { dayOfWeekName } from "../../../../../Config/Util";

function Item({ history, match, location, item }) {
  const CoverImage =
    item.giftProduct.coverImage === null
      ? "/assets/img/product/product-default.jpg"
      : `${graphqlUrl}${item.giftProduct.coverImage}`;

  let Uri = "";
  if (item.giftProduct.productType === "Product") {
    Uri += `/products/${item.giftProduct.id}`;
  }
  if (item.giftProduct.productType === "Ticket") {
    Uri += `/tickets/${item.giftProduct.id}`;
  }

  const ticketDatetime = new Date(
    Date.parse(
      `${item.giftProductOptionDetail1.name} ${item.giftProductOptionDetail2.name}`
    )
  );
  const ticketDay = ticketDatetime.getDay();

  return (
    <SOrderItem>
      <Row className="item_top">
        <Col className="left">
          <span className="date">
            구매일자 :{" "}
            <ReactMoment format="YYYY.MM.DD HH:mm">
              {item.createdAt}
            </ReactMoment>
          </span>
        </Col>
        <Col className="right">
          <Links
            color="primary"
            size="xs"
            border="round"
            to={`/account/orders/${item.id}`}
          >
            상세정보
          </Links>
        </Col>
      </Row>
      <div className="item_content">
        <div className="product_list">
          <Link to={Uri}>
            <div className="img">
              <img src={CoverImage} alt={item.giftProduct.name} />
            </div>
            <div className="cont">
              <span className="category">{item.giftProduct.store.name}</span>
              <strong>{item.giftProduct.name}</strong>
              <div className="option">
                {item.giftProduct.giftProductOptionsCount !== "" && (
                  <>
                    <Label>행사시간</Label>{" "}
                    <ReactMoment format="YYYY. MM. DD">
                      {ticketDatetime}
                    </ReactMoment>{" "}
                    ({dayOfWeekName(ticketDay)}){" "}
                    <ReactMoment format="HH:mm">{ticketDatetime}</ReactMoment>
                  </>
                )}
              </div>
            </div>
          </Link>
        </div>
      </div>
      <Row className="item_bottom">
        <Col left className="left">
          <span>
            To.
            {item.receiver[0]?.name}님
            {renderHTML(
              item.receiver.length > 1
                ? `외 <b>${item.receiver.length - 1}</b>명`
                : ``
            )}
          </span>
        </Col>
        <Col right className="right">
          <b>
            <NumberFormat
              displayType={"text"}
              value={item.totalPrice}
              thousandSeparator={true}
              suffix={"드림"}
            />
          </b>
        </Col>
      </Row>
    </SOrderItem>
  );
}
export default withRouter(Item);
