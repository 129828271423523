import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { STORE_QUERY } from "../../../Config/Queries";

import Presenter from "./Presenter";
import Lodings from "../../../Components/Loading";
import qs from "qs";

function Container({ history, match, location }) {
  const id = match.params.id;

  const orderByOption = [
    {
      value: "id_DESC",
      label: "최신순",
    },
    {
      value: "orderCount_DESC",
      label: "인기순",
    },
    {
      value: "totalPrice_ASC",
      label: "낮은 가격순",
    },
    {
      value: "totalPrice_DESC",
      label: "높은 가격순",
    },
  ];

  const queryString = qs.parse(location.search.substr(1));
  const orderBy = queryString.orderBy
    ? orderByOption.find((find) => find.value === queryString.orderBy)
      ? queryString.orderBy
      : "id_DESC"
    : "id_DESC";

  const { data, loading } = useQuery(STORE_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      id,
      orderBy,
    },
  });
  const [orderByDefault, setOrderByDefault] = useState({
    value: "id_DESC",
    label: "최신순",
  });
  const orderByChange = (item) => {
    setOrderByDefault({
      value: item.value,
      label: item.label,
    });

    const url = "?" + (item.value ? "orderBy=" + item.value + "&" : "");
    history.push(`/stores/${id}${url}`);
  };

  useEffect(() => {
    const orderByValue = orderByOption.find((find) => find.value === orderBy);
    if (orderByValue) {
      setOrderByDefault(orderByValue);
    } else {
      setOrderByDefault({
        value: "id_DESC",
        label: "최신순",
      });
    }
  }, [orderBy]);

  if (!loading) {
    return (
      <Presenter
        data={data}
        orderByOption={orderByOption}
        orderByDefault={orderByDefault}
        orderByChange={orderByChange}
      />
    );
  } else {
    return (
      <>
        <Lodings desc="로딩..." />
      </>
    );
  }
}

export default withRouter(Container);
