import React from "react"
import { withRouter } from "react-router-dom"

import { Row, SOrderItem } from "../../../../../styles/styles"
import styled, { css } from "styled-components"
import ReactMoment from "react-moment"
import { graphqlUrl } from "../../../../../Config/Env"

const SSOrderItem = styled(SOrderItem)`
  ${({ theme }) => {
    return css`
      .img {
        width: 90px;
        height: 90px;
        border-radius: 8px;
      }
    `
  }}
`
function Item({ history, match, location, item }) {
  const CoverImage =
    item.coverImage === null
      ? "/assets/img/product/product-default.jpg"
      : `${graphqlUrl}${item.coverImage}`

  return (
    <>
      <SSOrderItem>
        <div className="item_content">
          <Row className="product_list">
            <div className="img">
              <img src={CoverImage} alt={item.name} />
            </div>
            <div className="cont">
              <span className="category">{item.store.name}</span>
              <strong>{item.name}</strong>
              <div className="info">
                <span>
                  <ReactMoment format="YYYY.MM.DD HH:mm:ss">
                    {item.createdAt}
                  </ReactMoment>
                </span>
              </div>
            </div>
          </Row>
        </div>
      </SSOrderItem>
    </>
  )
}
export default withRouter(Item)
