import React from "react"
import styled, { css } from "styled-components"
import { withRouter } from "react-router-dom"
import NumberFormat from "react-number-format"
import { graphqlUrl } from "../../../../Config/Env"

export const SItem = styled.div`
  ${({ theme }) => {
    return css`
      flex: 1 0 100%;
      padding: 10px 0;
      .productItem {
        display: flex;
        flex-direction: row;
        border-radius: 8px;
        border: solid 1px #eee;
        align-items: center;
      }
      .img {
        position: relative;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        width: 110px;
        height: 110px;
        overflow: hidden;
        :before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          border: 1px solid rgba(0, 0, 0, 0.02);
        }
        img {
          max-width: 100%;
          display: block;
          width: 100%;
          height: auto;
        }
      }
      .cont {
        display: flex;
        flex-direction: column;
        padding: 6px 10px;
        .category {
          margin-top: 0;
          color: ${theme.colors.black03};
          font-size: ${theme.fonts.size.sm};
          font-weight: 400;
        }
        h3 {
          margin-top: ${theme.margins.sm};
          overflow: hidden;
          font-size: ${theme.fonts.size.base};
          color: ${theme.colors.black01};
          font-weight: ${theme.fonts.weight.bold};
          line-height: 1.3;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 200px;
        }
      }
      .discount {
        margin-top: 8px;
        color: ${theme.colors.danger};
        font-size: ${theme.fonts.size.sm};
        span {
          color: ${theme.colors.black04};
          text-decoration: line-through;
        }
      }
      .price {
        font-size: ${theme.fonts.size.base};
        color: ${theme.colors.black01};
        font-weight: ${theme.fonts.weight.base};
        b {
          font-weight: ${theme.fonts.weight.base};
        }
        .percent {
          color: ${theme.colors.danger};
        }
      }
      .rank {
        span {
          font-size: ${theme.fonts.size.lg};
          color: ${theme.colors.black01};
          font-weight: ${theme.fonts.weight.bold};
        }
      }
      .complete_icon {
        position: absolute;
        background-color: rgba(255, 255, 255, 0.8);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 99;
        display: flex;
        justify-content: center;
        align-items: center;
        div {
          position: relative;
          transform: rotate(-30deg);
          width: 64px;
          height: 64px;
          border: solid 2px #555;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          color: #777;
          ::before {
            content: "";
            position: absolute;
            width: 54px;
            height: 54px;
            z-index: 99;
            border-radius: 50%;
            border: solid 1px #bbb;
          }
        }
      }
    `
  }}
`

function Item({ history, match, location, rank, item }) {
  const CoverImage =
    item.coverImage === null
      ? "/assets/img/product/product-default.jpg"
      : `${graphqlUrl}${item.coverImage}`
  const Percent = parseInt((item.discountPrice / item.price) * 100)

  return (
    <SItem>
      <div className="rank">
        <span>{rank}위</span>
      </div>
      <div className="productItem">
        <div className="img">
          <img src={CoverImage} alt={item.name} />
          {item.isSoldOut && (
            <div className="complete_icon">
              <div>
                <span>품절</span>
              </div>
            </div>
          )}
        </div>
        <div className="cont">
          <span className="category">{item.store.name}</span>
          <h3>{item.name}</h3>
          {item.isDiscount ? (
            <>
              <div className="discount">
                <span>
                  <NumberFormat
                    value={item.price}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                  드림
                </span>
              </div>
              <div className="price">
                <b className="percent">{Percent}% </b>
                <b>
                  <NumberFormat
                    value={item.price - item.discountPrice}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </b>
                드림
              </div>
            </>
          ) : (
            <>
              <div className="price">
                <b>
                  <NumberFormat
                    value={item.price}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </b>
                드림
              </div>
            </>
          )}
        </div>
      </div>
    </SItem>
  )
}
export default withRouter(Item)
