import { useState } from "react";
import { useMutation } from "@apollo/client";
import { NAME_BY_USERS_MUTATION } from "../../../../Config/Queries";

import styled, { css } from "styled-components";
import { Roundbox, Col } from "../../../../styles/styles";
import { useForm } from "react-hook-form";
import FormError from "../../../FormError";
import Button from "../../../Buttons";
import { graphqlUrl } from "../../../../Config/Env";
import { toast } from "react-toastify";
import { maskingName } from "../../../../Config/Util";

const Search = styled.div`
  ${({ theme }) => {
    return css`
      .check_list {
        height: 100px;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        ::-webkit-scrollbar {
          display: none;
        }
        display: flex;
        flex-direction: row;
        li {
          display: flex;
          flex-direction: column;
          padding: ${theme.paddings.sm};
          align-items: center;
          justify-content: center;
          p {
            margin-top: ${theme.margins.xs};
            font-size: ${theme.fonts.size.sm};
            font-weight: normal;
            color: ${theme.colors.default};
          }
          .close {
            background: rgba(0, 0, 0, 0.5);
            position: absolute;
            z-index: 11;
            top: -7px;
            right: -7px;
            display: flex;
            width: 12px;
            height: 12px;
            border-radius: 16px;
            align-items: center;
            justify-content: center;
            :hover {
              background: rgba(0, 0, 0, 0.6);
            }
            ::before {
              content: "\f00d";
              font-family: ${theme.fonts.family.fontAwesomeL};
              font-size: 13px;
              line-height: 0;
              color: #fff;
            }
          }
        }
        .check_list_done {
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          p {
            margin: 0 0 0 0.5rem;
            font-size: 1.3rem;
            font-weight: normal;
            color: #777;
          }
        }
      }
      .search_top {
        form {
          padding: ${theme.paddings.sm};
          border-top: solid 1px #eee;
          border-bottom: solid 1px #eee;
          flex-direction: row;
          .inner {
            flex: 1 0 auto;
            display: flex;
            flex-direction: row;
            overflow: hidden;
            .left {
              display: flex;
              flex: 1 0 auto;
              position: relative;
              input {
                background-color: #f5f5f5 !important;
                border: solid 1px #eee;
                height: 40px;
                line-height: 40px;
                width: 100%;
                padding-left: 1rem;
                border-radius: 4px;
                font-size: ${theme.fonts.size.sm};
              }
              .search_btn {
                position: absolute;
                top: 0;
                right: 0;
                background-color: transparent;
                width: 40px;
                height: 40px;
                ::before {
                  content: "\f002";
                  font-family: ${theme.fonts.family.fontAwesome};
                  color: ${theme.colors.gray};
                }
                :hover:before {
                  color: ${theme.colors.primary};
                }
              }
            }
            .right {
              flex: 0 0 auto;
              display: inline-flex;
              margin-left: 10px;
              button {
                background-color: transparent;
                color: ${theme.colors.primary};
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
      }
    `;
  }}
`;
const SearchList = styled.div`
  ${({ theme }) => {
    return css`
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      ::-webkit-scrollbar {
        display: none;
      }
      border-bottom: solid 1px #eee;
      ul {
        display: flex;
        height: 120px;
        flex-direction: row;
        border-bottom: solid 1px #eee;
        padding: ${theme.paddings.base} 0;
        li {
          display: flex;
          padding: 0 ${theme.paddings.sm};
          align-items: center;
          justify-content: center;
          text-align: center;
          label {
            display: flex;
            align-items: center;
            flex-direction: column;
            color: #666;
            input {
              position: relative;
            }
            input[type="checkbox"] {
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 42px;
              height: 42px;
              border-radius: 16px;
              ::before {
                color: #bbb;
              }
              :checked {
                background-color: rgba(0, 0, 0, 0.5);
                ::before {
                  content: "\f00c";
                  font-family: ${theme.fonts.family.fontAwesome};
                  color: white;
                }
              }
            }
            .name {
              margin: ${theme.margins.sm} 0 0;
              font-size: ${theme.fonts.size.sm};
              color: ${theme.colors.default};
              span {
                display: block;
                margin-top: 5px;
                color: #777;
                font-size: 1.1rem;
              }
            }
          }
        }
      }
      .bottom {
        padding: ${theme.paddings.sm};
      }
    `;
  }}
`;
const GiftuserBox = styled.div`
  border-radius: 16px;
  background-color: white;
`;

function Giftuser({
  users,
  setUsers,
  selectUsers,
  setSelectUsers,
  activeModal,
  setActiveModal,
  userSelect,
  userUnSelect,
}) {
  const [searchUsers, setSearchUsers] = useState([]);

  const [nameByUsersMutation, { loading }] = useMutation(
    NAME_BY_USERS_MUTATION
  );

  const { register, handleSubmit, errors, setValue } = useForm({
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    try {
      if (loading) {
        return;
      }

      const {
        data: {
          GiftProductUserSeach: { users },
        },
      } = await nameByUsersMutation({
        variables: {
          keyword: data.keyword,
        },
      });
      setSearchUsers(users);
      setValue("keyword", "");
      return;
    } catch (e) {
      console.log(e);
    }
  };

  const nextStep = () => {
    if (users.length === 0) {
      toast.error(`선물 받는분을 추가해주세요.`);
      return;
    }
    setActiveModal("Payment");
  };

  return (
    <>
      <GiftuserBox>
        <Search
          style={
            activeModal === "Giftuser"
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <ul className="check_list">
            {selectUsers.length > 0 ? (
              <>
                {selectUsers.map((item, index) => {
                  const avatarImage =
                    item.avatar === ""
                      ? "/assets/img/product/product-default.jpg"
                      : `${graphqlUrl}${item.avatar}`;
                  return (
                    <li key={index}>
                      <Roundbox>
                        <Button
                          className="close"
                          onClick={() => userUnSelect(item)}
                        ></Button>
                        <div className="img">
                          <img src={avatarImage} alt={item.name} />
                        </div>
                      </Roundbox>
                      <p>{item.name}님</p>
                    </li>
                  );
                })}
              </>
            ) : (
              <li className="check_list_done">
                <Roundbox>
                  <div className="icon"></div>
                </Roundbox>
                <div className="cont">
                  <p>선물 받는분을 검색해주세요.</p>
                </div>
              </li>
            )}
          </ul>

          <div className="search_top">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="inner">
                <div className="left">
                  <input
                    type="text"
                    name="keyword"
                    placeholder="이름 입력"
                    ref={register({
                      required: "이름를 입력해주세요.",
                    })}
                  />
                  <Button className="search_btn" />
                </div>
              </div>
              <FormError message={errors?.keyword?.message} />
            </form>
          </div>
        </Search>
        <SearchList
          style={
            activeModal === "Giftuser"
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <ul>
            {searchUsers.map((item, index) => {
              const avatarImage =
                item.avatar === ""
                  ? "/assets/img/product/product-default.jpg"
                  : `${graphqlUrl}${item.avatar}`;
              return (
                <li key={index}>
                  <label>
                    <Roundbox>
                      <div className="img">
                        <img src={avatarImage} alt={item.name} />
                      </div>
                    </Roundbox>
                    <input
                      type="checkbox"
                      onChange={() => userSelect(item)}
                      checked={users.indexOf(item.id) > -1 && true}
                    />
                    <p className="name">
                      {item.name}님<span>({maskingName(item.userId)})</span>
                    </p>
                  </label>
                </li>
              );
            })}
          </ul>
          <Col className="bottom">
            <Button onClick={nextStep} color="lightprimary" width="full">
              다음
            </Button>
          </Col>
        </SearchList>
      </GiftuserBox>
    </>
  );
}

export default Giftuser;
