import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { CATEGORIES_QUERY } from "../../../../../Config/Queries";

import styled, { css } from "styled-components";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import qs from "qs";

const ProductOption = styled.div`
  border-bottom: solid 2px #eee;
`;
const DropdownOption = styled.div`
  ${({ theme }) => {
    return css`
      position: relative;
      display: flex;
      .Dropdown-root {
        position: static;
        flex: 0 0 50%;
        .Dropdown-control {
          flex: 1;
          display: flex;
          height: 40px;
          align-items: center;
          border: 0;
          .Dropdown-placeholder {
            font-size: 1.35rem;
            color: #777;
          }
          .Dropdown-arrow {
            border: 0;
            position: absolute;
            right: 20px;
            top: 15px;
            width: 0;
            ::before {
              content: "\f107";
              font-family: ${theme.fonts.family.fontAwesome};
              color: ${theme.colors.gray};
            }
          }
        }
        .Dropdown-menu {
          left: 0;
          display: flex;
          border-color: #ddd;
          border-left: 0;
          border-right: 0;
          flex-wrap: wrap;
          .Dropdown-option {
            display: flex;
            flex: 0 0 33.333%;
            font-size: 1.35rem;
            padding: 1.2rem 0.6rem;
            border-bottom: solid 1px #eee;
            border-left: solid 1px #eee;
            &:hover {
              background-color: ${theme.colors.lightgray};
            }
          }
          .Dropdown-option.is-selected {
            background-color: ${theme.colors.lightgray};
            color: #333;
          }
        }
      }
      .Dropdown-root + .Dropdown-root {
        border-left: solid 1px #eee;
      }
      .Dropdown-root.is-open {
        .Dropdown-placeholder {
          font-size: 1.35rem;
          color: ${theme.colors.primary};
          font-weight: ${theme.fonts.weight.medium};
        }
        .Dropdown-arrow {
          ::before {
            content: "\f106";
            color: ${theme.colors.primary};
          }
        }
      }
    `;
  }}
`;

function Category({ history, match, location, productType, uri }) {
  // const productType = "Product";
  // const uri = "/products";

  const queryString = qs.parse(location.search.substr(1));

  const depth1 = queryString.depth1 ? queryString.depth1 : null;
  const depth2 = queryString.depth2 ? queryString.depth2 : null;
  const orderBy = queryString.orderBy ? queryString.orderBy : null;

  const { data, loading } = useQuery(CATEGORIES_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      productType: productType,
      parentId: null,
    },
  });

  const [depth1Option, setDepth1Option] = useState([
    {
      value: null,
      label: "전체보기",
    },
  ]);
  const [depth1Default, setDepth1Default] = useState({
    value: null,
    label: "전체보기",
  });
  const [depth2Option, setDepth2Option] = useState([
    {
      value: null,
      label: "전체보기",
    },
  ]);
  const [depth2Default, setDepth2Default] = useState({
    value: null,
    label: "전체보기",
  });

  const depth1Change = (item) => {
    if (item.value) {
      setDepth1Default({
        value: item.value,
        label: item.label,
      });
      setDepth2Default({
        value: null,
        label: "전체보기",
      });

      const category1 = data.GiftProductCategoryList.giftCategories.find(
        (find) => find.id === item.value
      );
      if (category1.childrenCount > 0) {
        const depth2CategoryOption = category1.children.map((depth2) => {
          return { value: depth2.id, label: depth2.name };
        });
        setDepth2Option([
          {
            value: null,
            label: "전체보기",
          },
          ...depth2CategoryOption,
        ]);
      }
    } else {
      setDepth2Default({
        value: null,
        label: "전체보기",
      });
      setDepth2Option([
        {
          value: null,
          label: "전체보기",
        },
      ]);
    }

    const url =
      "?" +
      (item.value ? "depth1=" + item.value + "&" : "") +
      (orderBy ? "orderBy=" + orderBy + "&" : "");
    history.push(`${uri}${url}`);
  };

  const depth2Change = (item) => {
    setDepth2Default({
      value: item.value,
      label: item.label,
    });

    const url =
      "?" +
      (depth1 ? "depth1=" + depth1 + "&" : "") +
      (item.value ? "depth2=" + item.value + "&" : "") +
      (orderBy ? "orderBy=" + orderBy + "&" : "");
    history.push(`${uri}${url}`);
  };

  useEffect(() => {
    if (!loading) {
      const depth1CategoryOption =
        data.GiftProductCategoryList.giftCategories.map((item) => {
          return {
            value: item.id,
            label: `${item.name}(${item.giftProductsCount})`,
          };
        });

      setDepth1Option([
        {
          value: null,
          label: "전체보기",
        },
        ...depth1CategoryOption,
      ]);

      if (depth1) {
        const category1 = data.GiftProductCategoryList.giftCategories.find(
          (find) => find.id === depth1
        );
        if (category1.childrenCount > 0) {
          const depth2CategoryOption = category1.children.map((depth2) => {
            return {
              value: depth2.id,
              label: `${depth2.name}(${depth2.giftProductsCount})`,
            };
          });
          setDepth2Option([
            {
              value: null,
              label: "전체보기",
            },
            ...depth2CategoryOption,
          ]);
          const category2 = category1.children.find(
            (find) => find.id === depth2
          );
          if (category2) {
            setDepth2Default({
              value: category2.id,
              label: `${category2.name}(${category2.giftProductsCount})`,
            });
          }
        }
        setDepth1Default({
          value: category1.id,
          label: `${category1.name}(${category1.giftProductsCount})`,
        });
      }
    }
  }, [data, loading, depth1, depth2]);

  if (!loading) {
    return (
      <>
        <ProductOption>
          <DropdownOption>
            <Dropdown
              options={depth1Option}
              onChange={(item) => {
                depth1Change(item);
              }}
              value={depth1Default}
            />
            <Dropdown
              options={depth2Option}
              onChange={(item) => {
                depth2Change(item);
              }}
              value={depth2Default}
            />
          </DropdownOption>
        </ProductOption>
      </>
    );
  } else {
    return <div></div>;
  }
}
export default withRouter(Category);
