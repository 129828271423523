import React from "react";
import { withRouter, Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { ACCOUNT_COMPLETEORDERPRODUCTS_QUERY } from "../../../Config/Queries";

import styled, { css } from "styled-components";
import {
    Container,
    TabsNav,
    Ready,
    MainText,
    MainTitle,
} from "../../../styles/styles";
import { Layout } from "../../../Components/Layouts/Layout";
import PageTitle from "../../../Components/Layouts/PageTitle";
import ProductItem from "../../../Components/OrderProduct/Account/OrderProduct/List/ProductItem";
import TicketItem from "../../../Components/OrderProduct/Account/OrderProduct/List/TicketItem";
import Pagination from "../../../Components/Pagination";
import Loadings from "../../../Components/Loading";
import qs from "qs";

const SContainer = styled(Container)`
    ${({ theme }) => {
        return css`
            padding: 0 ${theme.paddings.xl};
        `;
    }}
`;
const SItemlist = styled.div`
    ${({ theme }) => {
        return css`
            margin-top: ${theme.margins.base};
            ul {
                display: flex;
                flex-wrap: wrap;
                li {
                    display: flex;
                    ${theme.device.desktop} {
                        flex: 0 0 33.3%;
                    }
                    ${theme.device.mobile} {
                        flex: 0 0 50%;
                    }
                    padding: 0 ${theme.paddings.sm};
                    margin-bottom: ${theme.paddings.sm};
                }
            }
        `;
    }}
`;
function List({ history, match, location }) {
    const queryString = qs.parse(location.search.substr(1));
    const page = queryString.page ? queryString.page : 1;
    const blockSize = 5;
    const first = 12;
    const skip = first * (page - 1);

    const baseUrl = "?";

    const { data, loading } = useQuery(ACCOUNT_COMPLETEORDERPRODUCTS_QUERY, {
        fetchPolicy: "network-only",
        variables: {
            orderBy: "usedAt_DESC",
            skip: skip,
            first: first,
        },
    });

    return (
        <Layout isNav={true} isFooter={true}>
            <PageTitle title="받은 선물함" />
            <SContainer>
                <MainTitle>
                    <MainText>사용완료한 상품/서비스가</MainText>
                    <MainText>
                        <span className="TextBlue">
                            {!loading &&
                                data.GiftAccountOrderProductCompleteList.count}
                            개
                        </span>{" "}
                        있습니다.
                    </MainText>
                </MainTitle>
                <TabsNav>
                    <ul>
                        <li>
                            <Link to="/account/readyOrderProducts">
                                <span>사용가능</span>
                            </Link>
                        </li>
                        <li className="active">
                            <Link to="/account/completeOrderProducts">
                                <span>사용완료</span>
                            </Link>
                        </li>
                    </ul>
                </TabsNav>
                {!loading ? (
                    <>
                        {data.GiftAccountOrderProductCompleteList.count > 0 ? (
                            <>
                                <SItemlist>
                                    <ul>
                                        {data.GiftAccountOrderProductCompleteList.giftOrderProducts.map(
                                            (item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <Link
                                                            to={`/account/orderProducts/${item.id}`}
                                                        >
                                                            {item.giftProduct
                                                                .productType ===
                                                                "Product" && (
                                                                <ProductItem
                                                                    item={item}
                                                                />
                                                            )}
                                                            {item.giftProduct
                                                                .productType ===
                                                                "Ticket" && (
                                                                <TicketItem
                                                                    item={item}
                                                                />
                                                            )}
                                                        </Link>
                                                    </li>
                                                );
                                            }
                                        )}
                                    </ul>
                                </SItemlist>
                                <Pagination
                                    totalRecord={
                                        data.GiftAccountOrderProductCompleteList
                                            .count
                                    }
                                    blockSize={blockSize}
                                    pageSize={first}
                                    currentPage={page}
                                    baseUrl={baseUrl}
                                />
                            </>
                        ) : (
                            <Ready>
                                <div>받은선물이 없습니다.</div>
                            </Ready>
                        )}
                    </>
                ) : (
                    <>
                        <Loadings desc="로딩..." />
                    </>
                )}
            </SContainer>
        </Layout>
    );
}

export default withRouter(List);
