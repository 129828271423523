import React from "react";
import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router-dom";

// 홈
import Home from "../Routes/Home/index";

//상품
import ProductList from "../Routes/Product/List/index";
import ProductDetail from "../Routes/Product/Detail/index";

//상점
import StoreList from "../Routes/Store/List/index";
import StoreDetail from "../Routes/Store/Detail/index";

//상품
import TicketList from "../Routes/Ticket/List/index";
import TicketDetail from "../Routes/Ticket/Detail/index";

//문의
import InquiryWrite from "../Routes/Inquiry/Write";
import InquiryEdit from "../Routes/Inquiry/Edit";

//주문
import OrderInit from "../Routes/Order/Init";
import OrderAuthPassword from "../Routes/Order/AuthPassword";
import OrderResult from "../Routes/Order/Result";

// 검색
import SearchInit from "../Routes/Search/Init";
import SearchResult from "../Routes/Search/Result";

// 마이페이지
// 마이페이지 > 홈
import AccountHome from "../Routes/Account/Home";

// 마이페이지 > 받은 선물함
import AccountOrderProductReadyList from "../Routes/Account/OrderProduct/ReadyList";
import AccountOrderProductCompleteList from "../Routes/Account/OrderProduct/CompleteList";
import AccountOrderProductDetail from "../Routes/Account/OrderProduct/Detail";

// 마이페이지 > wishlist
import AccountWishlistList from "../Routes/Account/Wishlist";

// 마이페이지 > 선물후기
import AccountReviewReadyList from "../Routes/Account/Review/ReadyList";
import AccountReviewWrite from "../Routes/Account/Review/Write";
import AccountReviewCompleteList from "../Routes/Account/Review/CompleteList";
import AccountReviewEdit from "../Routes/Account/Review/Edit";

// 마이페이지 > 선물문의
import AccountInquiry from "../Routes/Account/Inquiry";

// 마이페이지 > 구매내역
import AccountOrderList from "../Routes/Account/Order/List";
import AccountOrderDetail from "../Routes/Account/Order/Detail";

// 공지사항
import NoticeList from "../Routes/Notice/List";

// 선물하기 이용약관
import Provision from "../Routes/Provision/Provision";
import Privacy from "../Routes/Provision/Privacy";
import ThirdParty from "../Routes/Provision/ThirdParty";

// 로그인
import Login from "../Routes/Auth/Login";

// 404 Errorç
import NotFound from "../Routes/NotFound";

const AppRouter = ({ isLoggedIn }) => {
  return (
    <Switch>
      <Route exact path={"/"}>
        <Redirect to={"/home"} />
      </Route>
      <Route exact path={"/home"}>
        <Home />
      </Route>

      <Route exact path="/products">
        <ProductList />
      </Route>
      <Route exact path="/products/:id">
        <ProductDetail />
      </Route>

      <Route exact path="/stores">
        <StoreList />
      </Route>
      <Route exact path="/stores/:id">
        <StoreDetail />
      </Route>

      <Route exact path="/products/:id/inquiries/write">
        {isLoggedIn ? <InquiryWrite /> : <Redirect to={"/login"} />}
      </Route>
      <Route exact path="/products/:id/inquiries/:inquiryId/edit">
        {isLoggedIn ? <InquiryEdit /> : <Redirect to={"/login"} />}
      </Route>

      <Route exact path="/tickets">
        <TicketList />
      </Route>
      <Route exact path="/tickets/:id">
        <TicketDetail />
      </Route>

      <Route exact path="/tickets/:id/inquiries/write">
        {isLoggedIn ? <InquiryWrite /> : <Redirect to={"/login"} />}
      </Route>
      <Route exact path="/products/:id/inquiries/:inquiryId/edit">
        {isLoggedIn ? <InquiryEdit /> : <Redirect to={"/login"} />}
      </Route>

      <Route exact path="/order">
        <OrderInit />
      </Route>
      <Route exact path="/order/authPassword">
        <OrderAuthPassword />
      </Route>
      <Route exact path="/order/result">
        <OrderResult />
      </Route>
      <Route exact path="/search">
        <SearchInit />
      </Route>
      <Route exact path="/search/result">
        <SearchResult />
      </Route>
      <Route exact path="/notices">
        <NoticeList />
      </Route>
      <Route exact path="/provision/provision">
        <Provision />
      </Route>
      <Route exact path="/provision/privacy">
        <Privacy />
      </Route>
      <Route exact path="/provision/thirdParty">
        <ThirdParty />
      </Route>
      <Route exact path="/account">
        {isLoggedIn ? <AccountHome /> : <Redirect to={"/login"} />}
      </Route>
      <Route exact path="/account/readyOrderProducts">
        {isLoggedIn ? (
          <AccountOrderProductReadyList />
        ) : (
          <Redirect to={"/login"} />
        )}
      </Route>
      <Route exact path="/account/completeOrderProducts">
        {isLoggedIn ? (
          <AccountOrderProductCompleteList />
        ) : (
          <Redirect to={"/login"} />
        )}
      </Route>
      <Route exact path="/account/orderProducts/:id">
        {isLoggedIn ? (
          <AccountOrderProductDetail />
        ) : (
          <Redirect to={"/login"} />
        )}
      </Route>
      <Route exact path="/account/wishlist">
        {isLoggedIn ? <AccountWishlistList /> : <Redirect to={"/login"} />}
      </Route>
      <Route exact path="/account/readyReviews">
        {isLoggedIn ? <AccountReviewReadyList /> : <Redirect to={"/login"} />}
      </Route>
      <Route exact path="/account/readyReviews/:giftOrderProductId/write">
        {isLoggedIn ? <AccountReviewWrite /> : <Redirect to={"/login"} />}
      </Route>
      <Route exact path="/account/completeReviews">
        {isLoggedIn ? (
          <AccountReviewCompleteList />
        ) : (
          <Redirect to={"/login"} />
        )}
      </Route>
      <Route exact path="/account/completeReviews/:id/edit">
        {isLoggedIn ? <AccountReviewEdit /> : <Redirect to={"/login"} />}
      </Route>
      <Route exact path="/account/inquiries">
        {isLoggedIn ? <AccountInquiry /> : <Redirect to={"/login"} />}
      </Route>
      <Route exact path="/account/orders">
        {isLoggedIn ? <AccountOrderList /> : <Redirect to={"/login"} />}
      </Route>
      <Route exact path="/account/orders/:id">
        {isLoggedIn ? <AccountOrderDetail /> : <Redirect to={"/login"} />}
      </Route>
      <Route exact path="/login">
        {isLoggedIn ? <Redirect to={"/home"} /> : <Login />}
      </Route>

      {/* <Route>
        <NotFound />
      </Route> */}
    </Switch>
  );
};

AppRouter.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};

export default AppRouter;
