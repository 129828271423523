import React from "react";
import { withRouter } from "react-router-dom";

import styled, { css } from "styled-components";
import { Row, Col, SOrderItem, SOrderInfo, Dot } from "../../../styles/styles";
import Alter from "../../Alter";
import NumberFormat from "react-number-format";
import ReactMoment from "react-moment";
import { graphqlUrl } from "../../../Config/Env";
import RowInfo from "../../RowInfo";

const SSOrderItem = styled(SOrderItem)`
    ${({ theme }) => {
        return css`
            padding: ${theme.paddings.lg} ${theme.paddings.xl};
            border: solid 1px #fff;
            .product_list {
                border: 1px solid #eee;
                border-radius: 8px;
                padding: ${theme.paddings.base};
                .date {
                    font-size: ${theme.fonts.size.sm};
                    color: ${theme.colors.black03};
                }
                .img {
                    border-radius: 8px;
                }
                ::after {
                    display: none;
                }
            }
        `;
    }}
`;
const SSOrderInfo = styled(SOrderInfo)`
    ${({ theme }) => {
        return css`
            padding: 0 ${theme.paddings.xl};
            .flextext {
                ul {
                    padding: 0;
                    li {
                        border-bottom: solid 1px #eee;
                        :nth-child(1) {
                            border-bottom: 0;
                        }
                    }
                }
            }
        `;
    }}
`;

function Product({
    history,
    match,
    location,
    users,
    giftProductOptionDetail1,
    giftProductOptionDetail2,
    giftProductOptionDetail3,
    giftProductOptionDetail4,
    giftProductOptionDetail5,
    count,

    data,
}) {
    const {
        GiftOrderInit: { giftProduct },
    } = data;

    const CoverImage =
        giftProduct.coverImage === "" || giftProduct.coverImage === null
            ? "/assets/img/product/product-default.jpg"
            : `${graphqlUrl}${giftProduct.coverImage}`;

    let OptionString = "";
    let productPrice = giftProduct.isDiscount
        ? giftProduct.price - giftProduct.discountPrice
        : giftProduct.price;
    for (let i = 0; i < giftProduct.giftProductOptions.length; i++) {
        const option = giftProduct.giftProductOptions[i];
        for (let j = 0; j < option.giftProductOptionDetails.length; j++) {
            const detail = option.giftProductOptionDetails[j];
            if (eval(`giftProductOptionDetail${i + 1}`) === detail.id) {
                OptionString += ` ${detail.name}(${
                    detail.price > 0 ? "+" : ""
                }${detail.price.toLocaleString("ko-KR", {
                    maximumFractionDigits: 4,
                })}드림) ${
                    giftProduct.giftProductOptions.length > i + 1 ? `/` : ``
                }`;
                productPrice += detail.price;
            }
        }
    }

    return (
        <>
            <SSOrderItem>
                <div className="product_list">
                    <div className="img">
                        <img src={CoverImage} alt={giftProduct.name} />
                    </div>
                    <div className="cont">
                        <Row className="between">
                            <span className="category">
                                {giftProduct.store.name}
                            </span>
                            <span>
                                <ReactMoment format="YYYY. MM. DD"></ReactMoment>
                            </span>
                        </Row>
                        <strong>{giftProduct.name}</strong>
                        <div className="option">
                            {giftProduct.giftProductOptionsCount > 0 && (
                                <>
                                    <span>추가옵션 :</span> {OptionString}
                                </>
                            )}
                        </div>
                        <div className="info">
                            <span>
                                <b>
                                    <NumberFormat
                                        displayType={"text"}
                                        className="price"
                                        value={productPrice}
                                        thousandSeparator={true}
                                        suffix={"드림"}
                                    />
                                </b>
                            </span>
                            <Dot />
                            <span>
                                <b>{count}</b>개
                            </span>
                        </div>
                    </div>
                </div>
            </SSOrderItem>
            <SSOrderInfo>
                <RowInfo
                    textLeft={"수량"}
                    textRight={
                        <>
                            <b>{count}</b>개
                        </>
                    }
                />
                <RowInfo
                    textLeft={"선물 받는 인원"}
                    border={"bottom"}
                    textRight={
                        <>
                            <b>{users.length}</b>명
                        </>
                    }
                />
                <RowInfo
                    textLeft={"결제금액"}
                    border={"bottom"}
                    total={"total"}
                    textRight={
                        <>
                            <b>
                                <NumberFormat
                                    value={productPrice * count * users.length}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                />
                            </b>
                        </>
                    }
                />
                <Alter style={{ marginTop: 16 }}>
                    <strong>선물구입 안내</strong>
                    <p>
                        - 구매하신 상품은 <b>선물함 &gt; 구매내역</b>에서 확인
                        가능합니다.
                    </p>
                </Alter>
            </SSOrderInfo>
        </>
    );
}

export default withRouter(Product);
