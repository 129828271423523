import React, { useState } from "react"
import { withRouter } from "react-router-dom"

import styled, { css } from "styled-components"
import { Col, Row } from "../../styles/styles"
import ReactMoment from "react-moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons"
import nl2br from "react-nl2br"

const SItem = styled.div`
  ${({ theme }) => {
    return css`
      border-bottom: solid 1px #eee;
      margin-bottom: ${theme.paddings.sm};
      .board_top {
        flex: 1 0 auto;
        flex-direction: row;
        padding: ${theme.paddings.base} 0;
        h3 {
          font-size: ${theme.fonts.size.lg};
          font-weight: ${theme.fonts.weight.bold};
          margin-bottom: ${theme.margins.sm};
          color: ${theme.colors.black01};
        }
        .date {
          font-size: ${theme.fonts.size.sm};
          color: ${theme.colors.black04};
        }
        svg {
          font-size: ${theme.fonts.size.lg};
        }
      }
      .board_body {
        margin: 0 0 ${theme.paddings.base};
        background-color: #f5f5f5;
        border-radius: 4px;
        .box {
          padding: ${theme.paddings.base};
          font-size: ${theme.fonts.size.base};
          color: ${theme.colors.black02};
        }
      }
      .hide {
        display: none;
      }
    `
  }}
`

function Item({ history, match, location, item }) {
  const [isDetail, setIsDetail] = useState(false)
  const toggleView = () => {
    setIsDetail(!isDetail)
  }

  return (
    <SItem>
      <div className="board_top" onClick={toggleView}>
        <h3>{item.title}</h3>
        <Row>
          <Col left>
            <span className="date">
              <ReactMoment format="YYYY. MM. DD">{item.createdAt}</ReactMoment>
            </span>
          </Col>
          <Col right>
            <FontAwesomeIcon
              icon={isDetail ? faAngleUp : faAngleDown}
              color={isDetail ? "#333" : "#999"}
            />
          </Col>
        </Row>
      </div>
      <div className={`board_body ${!isDetail && `hide`}`}>
        <div className="box">{nl2br(item.contents)}</div>
      </div>
    </SItem>
  )
}
export default withRouter(Item)
