import { useState } from "react"
import { useMutation } from "@apollo/client"
import { NAME_BY_USERS_MUTATION } from "../../../../Config/Queries"

import styled, { css } from "styled-components"
import {
  Roundbox,
  Col,
  Ready,
  SearchContainer,
  UserList,
  SearchList,
} from "../../../../styles/styles"
import { useForm } from "react-hook-form"
import FormError from "../../../FormError"
import Button from "../../../Buttons"
import { graphqlUrl } from "../../../../Config/Env"
import { toast } from "react-toastify"
import { SearchNormal1 } from "iconsax-react"
import theme from "../../../../styles/Theme"

const GiftuserBox = styled.div`
  ${({ theme }) => {
    return css``
  }}
`

function PushUser({
  users,
  setUsers,
  selectUsers,
  setSelectUsers,
  activeModal,
  setActiveModal,
  userSelect,
  userUnSelect,
}) {
  const [searchUsers, setSearchUsers] = useState([])

  const [nameByUsersMutation, { loading }] = useMutation(NAME_BY_USERS_MUTATION)

  const { register, handleSubmit, errors, setValue } = useForm({
    mode: "onChange",
  })

  const onSubmit = async (data) => {
    try {
      if (loading) {
        return
      }

      const {
        data: {
          GiftProductUserSeach: { users },
        },
      } = await nameByUsersMutation({
        variables: {
          keyword: data.keyword,
        },
      })
      setSearchUsers(users)
      setValue("keyword", "")
      return
    } catch (e) {
      console.log(e)
      var error = e.toString()
      error = error.replace("Error: GraphQL error:", "")
      toast.error(error)
    }
  }

  return (
    <>
      <GiftuserBox>
        <UserList>
          <ul className="check_list">
            {selectUsers.length > 0 ? (
              <>
                {selectUsers.map((item, index) => {
                  const avatarImage =
                    item.avatar === ""
                      ? "/assets/img/user/user-default.jpg"
                      : `${graphqlUrl}${item.avatar}`
                  return (
                    <li key={index}>
                      <Roundbox>
                        <Button
                          className="close"
                          onClick={() => userUnSelect(item)}
                        ></Button>
                        <div className="img">
                          <img src={avatarImage} alt={item.name} />
                        </div>
                      </Roundbox>
                      <p>{item.name}님</p>
                    </li>
                  )
                })}
              </>
            ) : (
              <li className="check_list_done">
                <Roundbox>
                  <div className="icon"></div>
                </Roundbox>
                <div className="cont">
                  <p>수신자를 검색해주세요.</p>
                </div>
              </li>
            )}
          </ul>
        </UserList>
        <SearchContainer>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="inner">
              <Button className="searchBtn">
                <SearchNormal1
                  color={theme.colors.black01}
                  variant="Linear"
                  size={20}
                />
              </Button>
              <input
                type="text"
                name="keyword"
                placeholder="이름 입력"
                ref={register({
                  required: "이름를 입력해주세요.",
                })}
              />
            </div>
          </form>
        </SearchContainer>
        <SearchList>
          <>
            {searchUsers.length > 0 ? (
              <ul>
                {searchUsers.map((item, index) => {
                  const avatarImage =
                    item.avatar === ""
                      ? "/assets/img/user/user-default.jpg"
                      : `${graphqlUrl}${item.avatar}`
                  return (
                    <li key={index}>
                      <label>
                        <Roundbox>
                          <div className="img">
                            <img src={avatarImage} alt={item.name} />
                          </div>
                        </Roundbox>
                        <input
                          type="checkbox"
                          onChange={() => userSelect(item)}
                          checked={users.indexOf(item.id) > -1 && true}
                        />
                        <p className="name">
                          {item.role === "User" && (
                            <>
                              {item.name}님<span>({item.userId})</span>
                            </>
                          )}
                          {item.role === "Store" && (
                            <>
                              {item.store.name} - {item.name}님
                              <span>({item.userId})</span>
                            </>
                          )}
                        </p>
                      </label>
                    </li>
                  )
                })}
              </ul>
            ) : (
              <Ready>
                <div className="list_none">검색어를 입력해주세요.</div>
              </Ready>
            )}
          </>
        </SearchList>
      </GiftuserBox>
    </>
  )
}

export default PushUser
