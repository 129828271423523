import React from "react";
import { withRouter, Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { ACCOUNT_READYREVIEWS_QUERY } from "../../../Config/Queries";

import { Layout } from "../../../Components/Layouts/Layout";
import PageTitle from "../../../Components/Layouts/PageTitle";
import styled, { css } from "styled-components";
import {
    TabsNav,
    Container,
    Ready,
    MainTitle,
    MainText,
} from "../../../styles/styles";
import ProductItem from "../../../Components/Review/Account/Review/List/ProductItem";
import TicketItem from "../../../Components/Review/Account/Review/List/TicketItem";
import Pagination from "../../../Components/Pagination";
import SLoading from "../../../Components/Loading";
import qs from "qs";

const SContainer = styled(Container)`
    ${({ theme }) => {
        return css`
            padding: 0 ${theme.paddings.xl};
        `;
    }}
`;
const SItemlist = styled.div`
    ${({ theme }) => {
        return css`
            li {
                position: relative;
                overflow: hidden;
                margin-bottom: ${theme.margins.base};
                .product_list {
                    padding: ${theme.paddings.base};
                    border: 0;
                }
                &.complete {
                    .product_list {
                        opacity: 0.15;
                        background-color: ${theme.colors.lightgray};
                    }
                }
                .complete_icon {
                    position: absolute;
                    background-color: rgba(0, 0, 0, 0.6);
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    z-index: 99;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 8px;
                    div {
                        position: relative;
                        width: 86px;
                        height: 86px;
                        border: solid 2px white;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: ${theme.fonts.size.base};
                        line-height: 1.4;
                        color: white;
                    }
                }
            }
        `;
    }}
`;

function ReadyList({ history, match, location }) {
    const queryString = qs.parse(location.search.substr(1));
    const page = queryString.page ? queryString.page : 1;
    const blockSize = 5;
    const first = 10;
    const skip = first * (page - 1);

    const baseUrl = "?";

    const { data, loading } = useQuery(ACCOUNT_READYREVIEWS_QUERY, {
        fetchPolicy: "network-only",
        variables: {
            orderBy: "id_DESC",
            skip,
            first,
        },
    });

    return (
        <Layout isNav={true} isFooter={true}>
            <PageTitle title="선물후기" />
            <SContainer>
                <MainTitle>
                    <MainText>작성 가능한 후기가</MainText>
                    <MainText>
                        <span className="TextBlue">
                            {!loading && data.GiftAccountReviewReadyList.count}
                            개
                        </span>{" "}
                        있어요.
                    </MainText>
                </MainTitle>
                <TabsNav>
                    <ul>
                        <li className="active">
                            <Link to="/account/readyReviews">
                                <span>작성 가능한 상품</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/account/completeReviews">
                                <span>작성 완료한 상품</span>
                            </Link>
                        </li>
                    </ul>
                </TabsNav>
                {!loading ? (
                    <>
                        {data.GiftAccountReviewReadyList.count > 0 ? (
                            <>
                                <SItemlist>
                                    <ul>
                                        {data.GiftAccountReviewReadyList.giftOrderProducts.map(
                                            (item, index) => {
                                                if (
                                                    !item.giftProductReviews
                                                        .length
                                                ) {
                                                    return (
                                                        <li key={index}>
                                                            <Link
                                                                to={`/account/readyReviews/${item.id}/write`}
                                                            >
                                                                {item
                                                                    .giftProduct
                                                                    .productType ===
                                                                    "Product" && (
                                                                    <ProductItem
                                                                        item={
                                                                            item
                                                                        }
                                                                    />
                                                                )}
                                                                {item
                                                                    .giftProduct
                                                                    .productType ===
                                                                    "Ticket" && (
                                                                    <TicketItem
                                                                        item={
                                                                            item
                                                                        }
                                                                    />
                                                                )}
                                                            </Link>
                                                        </li>
                                                    );
                                                } else {
                                                    return (
                                                        <li
                                                            key={index}
                                                            className="complete"
                                                        >
                                                            <div className="complete_icon">
                                                                <div>
                                                                    작성
                                                                    <br />
                                                                    완료
                                                                </div>
                                                            </div>
                                                            <ProductItem
                                                                key={index}
                                                                item={item}
                                                            />
                                                        </li>
                                                    );
                                                }
                                            }
                                        )}
                                    </ul>
                                </SItemlist>
                                <Pagination
                                    totalRecord={
                                        data.GiftAccountReviewReadyList.count
                                    }
                                    blockSize={blockSize}
                                    pageSize={first}
                                    currentPage={page}
                                    baseUrl={baseUrl}
                                />
                            </>
                        ) : (
                            <Ready>
                                <div className="list_none">
                                    작성 가능한 상품이 없습니다.
                                </div>
                            </Ready>
                        )}
                    </>
                ) : (
                    <SLoading />
                )}
            </SContainer>
        </Layout>
    );
}
export default withRouter(ReadyList);
