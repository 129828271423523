import React, { useState, useEffect } from "react"
import { withRouter, Link } from "react-router-dom"

import { Layout } from "../../Components/Layouts/Layout"
import PageTitle from "../../Components/Layouts/PageTitle"
import { Col, Row, Ready, SearchContainer } from "../../styles/styles"
import { useForm } from "react-hook-form"
import FormError from "../../Components/FormError"
import Button from "../../Components/Buttons"
import ReactMoment from "react-moment"
import styled, { css } from "styled-components"
import Category from "../../Components/Category/Search/Init/Category.js"
import { SearchNormal1 } from "iconsax-react"

const SearchWord = styled.div`
  ${({ theme }) => {
    return css`
      border-bottom: solid 5px #eee;
      padding: ${theme.paddings.base} ${theme.paddings.xl};
      .searchword_top {
        align-items: center;
      }
      h1 {
        display: flex;
        font-size: ${theme.fonts.size.base};
        color: ${theme.colors.black01};
        font-weight: ${theme.fonts.weight.medium};
      }
      .all_delete {
        background: transparent;
        font-size: ${theme.fonts.size.sm};
        padding: ${theme.paddings.sm} 0;
        color: ${theme.colors.black04};
      }
      .searchword_body {
        ul {
          flex-direction: column;
          li {
            flex: 1;
            display: flex;
            float: none;
            border-bottom: solid 1px #eee;
            a {
              flex: 1;
              display: flex;
              flex-direction: row;
              padding: ${theme.paddings.base} 0;
              font-size: ${theme.fonts.size.sm};
              color: ${theme.colors.black01};
              border: 0;
              &:hover {
                color: ${theme.colors.primary};
              }
            }
            .left {
              color: #555;
              font-size: ${theme.fonts.size.sm};
            }
            .right {
              color: ${theme.colors.black04};
              font-size: ${theme.fonts.size.sm};
              .date {
                color: ${theme.colors.black04};
              }
            }
          }
          .none {
            padding: ${theme.paddings.lg};
          }
        }
      }
    `
  }}
`

function Init({ history, match, location }) {
  const { register, handleSubmit, errors } = useForm()
  const [displayKeywords, setDisplayKeywords] = useState([])

  const getItem = async () => {
    const keywords = await localStorage.getItem("keywords")
    setDisplayKeywords(keywords ? JSON.parse(keywords) : [])
    // setLoaded(true);
  }

  const setItem = async (text) => {
    const date = new Date()
    const newKeyword = { keyword: text, date: date.toString() }

    let keywords = await localStorage.getItem("keywords")

    if (keywords) {
      keywords = JSON.parse(keywords)

      let tempKeywords = keywords.filter((item) => text !== item.keyword)

      tempKeywords.map((item) => {
        return { keyword: item.keyword, date: item.date }
      })
      tempKeywords = tempKeywords ? [newKeyword, ...tempKeywords] : [newKeyword]
      await localStorage.removeItem("keywords")
      localStorage.setItem("keywords", JSON.stringify(tempKeywords))
      setDisplayKeywords(tempKeywords)
    } else {
      const tempKeywords = [newKeyword]
      localStorage.setItem("keywords", JSON.stringify(tempKeywords))
      setDisplayKeywords(tempKeywords)
    }
  }

  const removeItem = async () => {
    await localStorage.removeItem("keywords")
    setDisplayKeywords([])
  }

  const onSubmit = (data) => {
    setItem(data.keyword)
    history.push(`/search/result?keyword=${data.keyword}`)
  }

  useEffect(() => {
    getItem()
  }, [])

  return (
    <Layout isNav={true} isFooter={true}>
      <PageTitle title="검색" />
      <SearchContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="inner">
            <Button className="searchBtn">
              <SearchNormal1 color={"#333"} variant="Linear" size={24} />
            </Button>
            <input
              type="text"
              name="keyword"
              placeholder="검색어(상품명, 가맹점명)를 입력해주세요."
              ref={register({
                required: "검색어(상품명, 가맹점명)를 입력해주세요.",
              })}
              autoComplete="off"
              autoFocus
            />
          </div>
          <FormError message={errors?.keyword?.message} />
        </form>
        <SearchWord>
          <Row className="searchword_top">
            <Col left>
              <h1>최근 검색어</h1>
            </Col>
            <Col right className="right">
              <Button className="all_delete" onClick={removeItem}>
                전체삭제
              </Button>
            </Col>
          </Row>
          <div className="searchword_body">
            {displayKeywords.length > 0 ? (
              <>
                <ul>
                  {displayKeywords.map((item, index) => {
                    return (
                      <li key={index}>
                        <Link to={`/search/result?keyword=${item.keyword}`}>
                          <Col left className="left">
                            {item.keyword}
                          </Col>
                          <Col right className="right">
                            <ReactMoment
                              className="date"
                              format="MM.DD HH:mm:ss"
                            >
                              {item.date}
                            </ReactMoment>
                          </Col>
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </>
            ) : (
              <Ready>
                <div className="list_none">최근 검색 내역이 없습니다.</div>
              </Ready>
            )}
          </div>
        </SearchWord>
        <Category productType="Product" />
        {/* <Category productType="Ticket" /> */}
      </SearchContainer>
    </Layout>
  )
}

export default withRouter(Init)
