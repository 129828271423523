import React from "react";

import { Layout } from "../../../Components/Layouts/Layout";
import PageTitle from "../../../Components/Layouts/PageTitle";
import styled, { css } from "styled-components";
import { Row, Col } from "../../../styles/styles";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import SLoading from "../../../Components/Loading";
import Category from "../../../Components/Category/Product/Common/List/Category";
import Item from "../../../Components/Product/Ticket/List/Item";
import Pagination from "../../../Components/Pagination";

const SContainer = styled.div`
    ${({ theme }) => {
        return css``;
    }}
`;
const Sort = styled(Row)`
    ${({ theme }) => {
        return css`
            padding: ${theme.paddings.lg} ${theme.paddings.base};
            font-size: ${theme.fonts.size.base};
            .left {
                align-items: center;
                span {
                    font-weight: ${theme.fonts.weight.medium};
                    color: ${theme.colors.default};
                    b {
                        color: ${theme.colors.primary};
                    }
                }
            }
            .right {
                flex: 0 0 auto;
                justify-content: flex-end;
            }
        `;
    }}
`;
const SortOption = styled.div`
    ${({ theme }) => {
        return css`
            position: relative;
            display: flex;
            .Dropdown-arrow {
                display: none;
            }
            .Dropdown-root {
                .Dropdown-control {
                    align-items: center;
                    padding: 0;
                    border: 0;
                    :hover {
                        box-shadow: none;
                    }
                    .Dropdown-placeholder {
                        font-size: 1.35rem;
                        color: #777;
                        :hover {
                            box-shadow: none;
                            color: ${theme.colors.primary};
                            ::before {
                                color: ${theme.colors.primary};
                                font-weight: ${theme.fonts.weight.medium};
                            }
                        }
                        ::before {
                            content: "\f883";
                            font-family: ${theme.fonts.family.fontAwesome};
                            color: ${theme.colors.gray};
                            margin-right: 5px;
                        }
                    }
                }
                .Dropdown-menu {
                    background-color: white;
                    width: 100px;
                    right: 0;
                    border-radius: 5px;
                    overflow: hidden;
                    .Dropdown-option {
                        display: flex;
                        justify-content: center;
                        font-size: 1.35rem;
                        padding: 1rem 0.6rem;
                        border-bottom: solid 1px #eee;
                        color: #777;
                        :last-child {
                            border-bottom: 0;
                        }
                        &:hover {
                            background-color: ${theme.colors.lightgray};
                        }
                    }
                    .Dropdown-option.is-selected {
                        background-color: ${theme.colors.lightgray};
                        color: #333;
                    }
                }
            }
            .Dropdown-root.is-open {
                .Dropdown-placeholder {
                    font-size: 1.35rem;
                    color: ${theme.colors.primary};
                    font-weight: ${theme.fonts.weight.medium};
                    ::before {
                        color: ${theme.colors.primary};
                    }
                }
            }
        `;
    }}
`;
const SItemlist = styled.div`
    ${({ theme }) => {
        return css`
            border-top: solid 1px #eee;
            ul {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                li {
                    display: flex;
                    flex: 1;
                }
            }
        `;
    }}
`;

function List({
    loading,
    data,
    page,
    orderByOption,
    orderByDefault,
    orderByChange,
    blockSize,
    first,
    baseUrl,
}) {
    return (
        <>
            <Layout isNav={true} isFooter={true}>
                <PageTitle title="상품" />
                <Category productType="Ticket" uri="/tickets" />
                <>
                    <Sort>
                        <Col className="left">
                            <span>
                                티켓{" "}
                                <b>{!loading && data.GiftProductList.count}</b>
                                건
                            </span>
                        </Col>
                        <Col className="right">
                            <SortOption>
                                <Dropdown
                                    options={orderByOption}
                                    value={orderByDefault}
                                    onChange={(item) => {
                                        orderByChange(item);
                                    }}
                                />
                            </SortOption>
                        </Col>
                    </Sort>
                    <SContainer>
                        {!loading ? (
                            <>
                                {data.GiftProductList.count > 0 ? (
                                    <>
                                        <SItemlist>
                                            <ul>
                                                {data.GiftProductList.giftProducts.map(
                                                    (item, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <Item
                                                                    item={item}
                                                                />
                                                            </li>
                                                        );
                                                    }
                                                )}
                                            </ul>
                                        </SItemlist>
                                        <Pagination
                                            totalRecord={
                                                data.GiftProductList.count
                                            }
                                            blockSize={blockSize}
                                            pageSize={first}
                                            currentPage={page}
                                            baseUrl={baseUrl}
                                        />
                                    </>
                                ) : (
                                    <>등록된 선물이 없습니다.</>
                                )}
                            </>
                        ) : (
                            <>
                                <SLoading />
                            </>
                        )}
                    </SContainer>
                </>
            </Layout>
        </>
    );
}
export default List;
