import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ApolloProvider, useReactiveVar } from "@apollo/client";
import { client, isLoggedInVar } from "./apollo";

// import { gql } from "apollo-boost";
// import { useQuery } from "react-apollo-hooks";

import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./styles/styles";
import { FontStyles } from "./styles/fonts";
import Theme from "./styles/Theme";

import Routes from "./Config/Routes";
import { HelmetProvider } from "react-helmet-async";

function App() {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  return (
    <ApolloProvider client={client}>
      <HelmetProvider>
        <ThemeProvider theme={Theme}>
          <GlobalStyles />
          <FontStyles />
          <Router>
            <Routes isLoggedIn={isLoggedIn} />
          </Router>
        </ThemeProvider>
      </HelmetProvider>
      <ToastContainer position={toast.POSITION.TOP_CENTER} />
    </ApolloProvider>
  );
}

export default App;
