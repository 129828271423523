import { createGlobalStyle } from "styled-components";

import FontAwesomeRegular1 from "@fortawesome/fontawesome-pro/webfonts/fa-regular-400.woff2";
import FontAwesomeRegular2 from "@fortawesome/fontawesome-pro/webfonts/fa-regular-400.ttf";
import FontAwesomeLight1 from "@fortawesome/fontawesome-pro/webfonts/fa-light-300.woff2";
import FontAwesomeLight2 from "@fortawesome/fontawesome-pro/webfonts/fa-light-300.ttf";
import FontAwesomeSolid1 from "@fortawesome/fontawesome-pro/webfonts/fa-solid-900.woff2";
import FontAwesomeSolid2 from "@fortawesome/fontawesome-pro/webfonts/fa-solid-900.ttf";
export const FontStyles = createGlobalStyle`
    @font-face {
        font-family:'Font Awesome 5 Regular' ;
        font-weight: 400;
        src: url('${FontAwesomeRegular1}') format('woff2'),
            url('${FontAwesomeRegular2}') format('ttf');
    }
    @font-face {
        font-family:'Font Awesome 5 Light' ;
        font-weight: 300;
        src: url('${FontAwesomeLight1}') format('woff2'),
            url('${FontAwesomeLight2}') format('ttf');
    }
    @font-face {
        font-family:'Font Awesome 5 Solid' ;
        font-weight: 900;
        src: url('${FontAwesomeSolid1}') format('woff2'),
            url('${FontAwesomeSolid2}') format('ttf');
    }
    @font-face {
        font-family: 'Pretendard';
        font-weight: 300;
        src: url('pretendard/dist/web/static/woff2/Pretendard-Light.woff2') format('woff2'),
            url('pretendard/dist/web/static/woff/Pretendard-Light.woff') format('woff'),
            url('pretendard/dist/public/static/Pretendard-Light.otf') format('opentype');
    }

    @font-face {
        font-family: 'Pretendard';
        font-weight: 400;
        src: url('pretendard/dist/web/static/woff2/Pretendard-Regular.woff2') format('woff2'),
            url('pretendard/dist/web/static/woff/Pretendard-Regular.woff') format('woff'),
            url('pretendard/dist/public/static/Pretendard-Regular.otf') format('opentype');
    }
    @font-face {
        font-family: 'Pretendard';
        font-weight: 500;
        src: url('pretendard/dist/web/static/woff2/Pretendard-Medium.woff2') format('woff2'),
            url('pretendard/dist/web/static/woff/Pretendard-Medium.woff') format('woff'),
            url('pretendard/dist/public/static/Pretendard-Medium.otf') format('opentype');
    }
    @font-face {
        font-family: 'Pretendard';
        font-weight: 700;
        src: url('pretendard/dist/web/static/woff2/Pretendard-Bold.woff2') format('woff2'),
            url('pretendard/dist/web/static/woff/Pretendard-Bold.woff') format('woff'),
            url('pretendard/dist/public/static/Pretendard-Bold.otf') format('opentype');
    }
`;
