export const dayOfWeekName = (day) => {
  const dayOfWeek = ["일", "월", "화", "수", "목", "금", "토"];
  return dayOfWeek[day];
};

export const maskingName = (strName) => {
  if (strName === undefined || strName === "") {
    return "";
  }
  // var pattern = /.{3}$/; // 정규식
  // return strName.replace(pattern, "***");
  return strName;
};
