import React from "react"
import { withRouter } from "react-router-dom"

import styled, { css } from "styled-components"
import { SOrderItem } from "../../../../../styles/styles"
import ReactMoment from "react-moment"
import { graphqlUrl } from "../../../../../Config/Env"

const SSOrderItem = styled(SOrderItem)`
  ${({ theme }) => {
    return css`
      box-shadow: none;
      .product_list {
        padding: 0 ${theme.paddings.base};
        border: 0;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        .img {
          position: relative;
          background-color: ${theme.colors.lightgray};
          width: 72px;
          height: 72px;
          border-radius: 8px;
        }
        .cont {
          flex: 1;
          .info {
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
            span {
              font-size: 13px;
              color: ${theme.colors.black02};
            }
          }
        }
      }
    `
  }}
`
function Item({ history, match, location, item }) {
  const CoverImage =
    item.giftProduct.coverImage === null
      ? "/assets/img/product/product-default.jpg"
      : `${graphqlUrl}${item.giftProduct.coverImage}`

  let OptionString = ""
  if (item.giftProductOptionDetail1) {
    OptionString += ` ${item.giftProductOptionDetail1.name}`
    if (item.giftProductOptionDetail2) {
      OptionString += ` / `
    }
  }
  if (item.giftProductOptionDetail2) {
    OptionString += ` ${item.giftProductOptionDetail2.name}`
    if (item.giftProductOptionDetail3) {
      OptionString += ` / `
    }
  }
  if (item.giftProductOptionDetail3) {
    OptionString += ` ${item.giftProductOptionDetail3.name}`
    if (item.giftProductOptionDetail4) {
      OptionString += ` / `
    }
  }
  if (item.giftProductOptionDetail4) {
    OptionString += ` ${item.giftProductOptionDetail4.name}`
    if (item.giftProductOptionDetail5) {
      OptionString += ` / `
    }
  }
  if (item.giftProductOptionDetail5) {
    OptionString += ` ${item.giftProductOptionDetail5.name}`
  }
  return (
    <>
      <SSOrderItem className="complate">
        <div className="product_list">
          <div className="img">
            <img src={CoverImage} alt={item.giftProduct.name} />
          </div>
          <div className="cont">
            <span className="category">{item.giftProduct.store.name}</span>
            <strong>{item.giftProduct.name}</strong>
            <div className="option">
              {OptionString !== "" && <>추가옵션 :{OptionString}</>}
            </div>
            <div className="info">
              <span>From. {item.user.name}</span>
              <span>
                <ReactMoment format="YYYY.MM.DD HH:mm:ss">
                  {item.createdAt}
                </ReactMoment>
              </span>
            </div>
          </div>
        </div>
      </SSOrderItem>
    </>
  )
}
export default withRouter(Item)
