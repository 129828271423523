import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import { useQuery, useMutation } from "@apollo/client"
import {
  REVIEWS_QUERY,
  ACCOUNT_REVIEWDELETE_MUTATION,
  PRODUCT_QUERY,
} from "../../../../../Config/Queries"

import styled, { css } from "styled-components"
import Button from "../../../../Buttons"
import Link from "../../../../Links"
import { Container, Ready } from "../../../../../styles/styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons"
import Item from "./Item"

const SContainer = styled(Container)`
  ${({ theme }) => {
    return css`
      .title {
        padding: ${theme.paddings.base};
        border-bottom: solid 2px #eee;
      }
      .review_top {
        display: flex;
        padding: ${theme.paddings.xl} ${theme.paddings.xl};
      }
      .review_bottom {
        border-top: solid 1px #eee;
        padding: ${theme.paddings.base};
      }
    `
  }}
`
const ReviewList = styled.div`
  ${({ theme }) => {
    return css`
      padding: 0 ${theme.paddings.xl};
      ul {
        li {
          border-bottom: solid 1px #eee;
          margin-bottom: 15px;
          padding-bottom: 15px;
          :last-child {
            border: 0;
          }
        }
      }
    `
  }}
`

function Review({ history, match, location, giftProductReviews, uri }) {
  const id = match.params.id

  const [items, setItems] = useState(giftProductReviews)
  const [page, setPage] = useState(2)
  const first = 10
  const skip = first * (page - 1)
  const [loadmore, setLoadmore] = useState(false)

  const { data, loading, refetch } = useQuery(REVIEWS_QUERY, {
    // fetchPolicy: "network-only",
    variables: {
      giftProduct: id,
      orderBy: "id_DESC",
      skip: skip,
      first: first,
    },
  })

  const [reviewDeleteMutation] = useMutation(ACCOUNT_REVIEWDELETE_MUTATION, {
    refetchQueries: () => [
      {
        query: PRODUCT_QUERY,
        variables: {
          productType: "Product",
          id,
        },
      },
    ],
  })

  const onDelete = async (id) => {
    if (!window.confirm("선택한 선물후기를 삭제하시겠습니까?")) {
      return
    }

    try {
      const {
        data: { GiftAccountReviewDelete },
      } = await reviewDeleteMutation({
        variables: { id },
      })

      if (GiftAccountReviewDelete) {
        setItems([...items.filter((item) => item.id !== id)])
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleLoadMore = () => {
    setLoadmore(true)
    setPage(page + 1)
    refetch()
    if (!loading && data.GiftProductReviewList.giftProductReviews) {
      setItems(items.concat(data.GiftProductReviewList.giftProductReviews))
    }
    setLoadmore(false)
  }

  return (
    <>
      <SContainer>
        <div className="review_top">
          <Link
            color="primary"
            width="full"
            border="solid"
            to="/account/completeReviews"
          >
            내 선물 후기 보기
          </Link>
        </div>
        <ReviewList>
          <>
            {items.length > 0 ? (
              <>
                <ul>
                  {items.map((item, index) => {
                    return (
                      <li key={index}>
                        <Item item={item} uri={uri} onDelete={onDelete} />
                      </li>
                    )
                  })}
                </ul>
                {!loadmore &&
                  !loading &&
                  data.GiftProductReviewList.giftProductReviews.length > 0 && (
                    <div className="review_bottom">
                      <Button
                        color="white"
                        border="radius"
                        width="full"
                        onClick={() => handleLoadMore()}
                      >
                        <FontAwesomeIcon icon={faAngleDown} />
                        &nbsp; 더보기
                      </Button>
                    </div>
                  )}
              </>
            ) : (
              <Ready>
                <div className="list_none">등록된 선물후기가 없습니다.</div>
              </Ready>
            )}
          </>
        </ReviewList>
      </SContainer>
    </>
  )
}
export default withRouter(Review)
