import React from "react";
import { withRouter } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { INQUIRYWRITE_MUTATION, PRODUCT_QUERY } from "../../Config/Queries";

import { Layout } from "../../Components/Layouts/Layout";
import PageTitle from "../../Components/Layouts/PageTitle";
import styled, { css } from "styled-components";
import {
    Container,
    FooterButton,
    MainText,
    MainTitle,
} from "../../styles/styles";
import { useForm } from "react-hook-form";
import FormError from "../../Components/FormError";
import Alter from "../../Components/Alter";
import Button from "../../Components/Buttons";
import { Checkbox } from "@createnl/grouped-checkboxes";

const SContainer = styled(Container)`
    ${({ theme }) => {
        return css`
            padding: 0 ${theme.paddings.xl};
        `;
    }}
`;
const SAlter = styled(Alter)`
    ${({ theme }) => {
        return css`
            margin-top: ${theme.margins.base};
            padding: ${theme.paddings.base};
            background-color: #fafafa;
            border-radius: 4px;
            font-weight: ${theme.fonts.weight.medium};
            p {
                margin-bottom: 0;
            }
        `;
    }}
`;
const SFormbox = styled.div`
    ${({ theme }) => {
        return css`
            form {
                display: flex;
                flex-direction: column;
                .title {
                    display: block;
                    margin-bottom: ${theme.margins.base};
                }
                .form_group {
                    label {
                        display: flex;
                        color: ${theme.colors.black03};
                        font-size: ${theme.fonts.size.sm};
                        margin-bottom: ${theme.margins.sm};
                    }
                    textarea {
                        width: 100%;
                        height: 200px;
                        padding: ${theme.paddings.base};
                        border: solid 1px #eee;
                        border-radius: 8px;
                        font-size: ${theme.fonts.size.base};
                    }
                }
                .button {
                    margin-top: ${theme.margins.sm};
                }
            }
            .checkList {
                margin-top: ${theme.margins.base};
                background-color: ${theme.colors.lightgray};
                border-radius: 8px;
                label {
                    display: flex;
                    padding: ${theme.paddings.base};
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    color: #666;
                    span {
                        margin-left: 10px;
                        flex: 1;
                        display: flex;
                        color: #666;
                        font-size: ${theme.fonts.size.base};
                        font-weight: ${theme.fonts.weight.medium};
                    }
                    .check {
                        display: flex;
                        justify-content: flex-end;
                    }
                    input {
                        display: flex;
                        position: relative;
                        justify-content: flex-end;
                    }
                    input[type="checkbox"] {
                        background-color: white;
                        border: solid 1px #ddd;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        :checked {
                            background-color: ${theme.colors.primary};
                            border: solid 1px ${theme.colors.primary};
                            ::before {
                                content: "\f00c";
                                font-family: ${theme.fonts.family.fontAwesome};
                                color: #fff;
                                font-size: 16px;
                            }
                        }
                    }
                    a {
                        display: flex;
                        align-items: center;
                        font-size: ${theme.fonts.size.sm};
                        color: #999;
                        svg {
                            margin-left: 0.5rem;
                        }
                    }
                }
            }
        `;
    }}
`;

function Write({ history, match, location }) {
    const id = match.params.id;

    const [inquiryWriteMutation, { loading }] = useMutation(
        INQUIRYWRITE_MUTATION,
        {
            refetchQueries: () => [
                {
                    query: PRODUCT_QUERY,
                    variables: {
                        productType: "Product",
                        id,
                    },
                },
            ],
        }
    );

    const { register, handleSubmit, errors } = useForm();

    const onSubmit = async (data) => {
        console.log(data);
        try {
            const {
                data: { GiftProductInquiryWrite },
            } = await inquiryWriteMutation({
                variables: {
                    giftProduct: id,
                    isSecret: data.isSecret,
                    question: data.question,
                },
            });

            if (GiftProductInquiryWrite) {
                history.push(`/products/${id}${location.search}`);
            }
        } catch (e) {
            console.log(e);
        }
    };
    // console.log(errors);

    return (
        <>
            <Layout isNav={true}>
                <PageTitle title="상품문의하기" />
                <SContainer>
                    <MainTitle>
                        <MainText>문의내용을</MainText>
                        <MainText>작성해주세요</MainText>
                    </MainTitle>
                    <SFormbox>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form_group">
                                <label>
                                    <textarea
                                        id="question"
                                        name="question"
                                        placeholder="문의내용을 입력해주세요."
                                        ref={register({
                                            required:
                                                "문의내용을 입력해주세요.",
                                        })}
                                        rows="10"
                                    ></textarea>
                                </label>
                            </div>
                            <FormError message={errors?.question?.message} />
                            <div className="checkList">
                                <label>
                                    <Checkbox
                                        name="isSecret"
                                        className="check"
                                        ref={register()}
                                    />
                                    <span>비밀글</span>
                                </label>
                            </div>
                            <SAlter color="white">
                                <p style={{ marginBottom: 5 }}>
                                    * 비밀글로 설정하지 않은 경우 문의 및 답변
                                    내용이 상품 상세 페이지에서 공개 게시됩니다.
                                </p>
                                <p>
                                    * 문의하신 내용은 상품판매 가게에서 빠른시일
                                    이내 확인 후 답변드립니다.
                                </p>
                            </SAlter>

                            <FooterButton>
                                <div>
                                    <Button
                                        type="submit"
                                        disabled={loading}
                                        color="primary"
                                        width="full"
                                    >
                                        {loading ? "처리중..." : "문의하기"}
                                    </Button>
                                </div>
                            </FooterButton>
                        </form>
                    </SFormbox>
                </SContainer>
            </Layout>
        </>
    );
}

export default withRouter(Write);
