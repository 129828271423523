import styled, { css } from "styled-components";
import { Col } from "../../styles/styles";
import theme from "../../styles/Theme";
import { Link, useHistory } from "react-router-dom";
import { ArrowLeft2, SearchNormal1 } from "iconsax-react";

const SHeader = styled.header`
    width: 100%;
    height: 51px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const HButton = styled(Link)`
    display: flex;
    background-color: white;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    ${({ theme }) => {
        return css`
            font-size: ${theme.fonts.size.lg};
            color: #777;
            &:hover {
                color: ${theme.colors.black01};
            }
        `;
    }}
`;
const Title = styled.div`
    display: flex;
    ${({ theme }) => {
        return css`
            font-size: ${theme.fonts.family.base};
            font-size: ${theme.fonts.size.base};
            font-weight: ${theme.fonts.weight.bold};
        `;
    }}
`;

function Back() {
    let history = useHistory();

    function handleClick() {
        history.goBack();
    }

    return (
        <HButton to="#" onClick={handleClick}>
            <ArrowLeft2
                color={theme.colors.black02}
                variant="Linear"
                size={24}
            />
        </HButton>
    );
}
function Search() {
    return (
        <HButton to="/search">
            <SearchNormal1
                color={theme.colors.black02}
                variant="Linear"
                size={24}
            />
        </HButton>
    );
}
function Header({ title }) {
    return (
        <SHeader>
            <Wrapper>
                <Col>
                    <Back />
                </Col>
                <Title>
                    <Link to="/home">{title}</Link>
                </Title>
                <Col right>
                    <Search />
                </Col>
            </Wrapper>
        </SHeader>
    );
}
export default Header;
