import styled, { css } from "styled-components";
import { withRouter } from "react-router-dom";
import { Roundbox, Col, Select, InputGroup } from "../../../../styles/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faTimes } from "@fortawesome/pro-regular-svg-icons";
import Button from "../../../Buttons";
import { graphqlUrl } from "../../../../Config/Env";
import NumberFormat from "react-number-format";

const Selects = styled(Select)`
    ${({ theme }) => {
        return css`
            margin-bottom: 1rem;
            select {
                height: 46px;
            }
        `;
    }}
`;
const SPayment = styled.div`
    ${({ theme }) => {
        return css`
            padding: ${theme.paddings.base} ${theme.paddings.xl};
            .title {
                font-size: ${theme.fonts.size.sm};
                color: ${theme.colors.black03};
                margin-bottom: ${theme.margins.sm};
            }
            .price_content {
                margin-top: ${theme.paddings.base};
                border-radius: 8px;
                .flextext {
                    flex: 1;
                    background-color: ${theme.colors.lightgray};
                    border: solid 1px #eee;
                    border-top-right-radius: 5px;
                    border-top-left-radius: 5px;
                    padding: ${theme.paddings.base};
                    ul {
                        li {
                            display: flex;
                            flex-direction: row;
                            padding: ${theme.paddings.sm} 0;
                            .left {
                                flex: 1;
                                font-size: ${theme.fonts.size.base};
                                color: #666;
                            }
                            .right {
                                flex: 1;
                                text-align: right;
                                justify-content: flex-end;
                                font-size: ${theme.fonts.size.base};
                            }
                            &.title {
                                padding: ${theme.paddings.base} 0;
                                text-overflow: ellipsis;
                                font-size: ${theme.fonts.size.lg};
                                color: ${theme.colors.black01};
                                font-weight: ${theme.fonts.weight.medium};
                            }
                        }
                    }
                }
                .price_total {
                    display: flex;
                    background-color: white;
                    border: solid 1px #eee;
                    padding: ${theme.paddings.lg} ${theme.paddings.base};
                    font-size: ${theme.fonts.size.base};
                    flex-direction: row;
                    justify-content: space-between;
                    text-align: right;
                    color: #444;
                    border-bottom-right-radius: 5px;
                    border-bottom-left-radius: 5px;
                    b {
                        padding-left: 0.5rem;
                        font-weight: bold;
                    }
                }
            }
            .bottom {
                margin-top: ${theme.paddings.base};
                a,
                button {
                    margin-left: 0 !important;
                    height: 60px;
                    line-height: 60px;
                }
            }
            .check_list {
                margin-top: ${theme.margins.xs};
                overflow-x: scroll;
                overflow-y: hidden;
                white-space: nowrap;
                ::-webkit-scrollbar {
                    display: none;
                }
                display: flex;
                flex-direction: row;
                li {
                    display: flex;
                    flex-direction: column;
                    padding: ${theme.paddings.base};
                    align-items: center;
                    justify-content: center;
                    p {
                        margin-top: ${theme.margins.xs};
                        font-size: ${theme.fonts.size.sm};
                        font-weight: normal;
                        color: ${theme.colors.black01};
                    }
                    .close {
                        background: ${theme.colors.black01};
                        position: absolute;
                        z-index: 11;
                        top: -7px;
                        right: -7px;
                        display: flex;
                        width: 12px;
                        height: 12px;
                        border-radius: 16px;
                        align-items: center;
                        justify-content: center;
                        :hover {
                            background: rgba(0, 0, 0, 0.6);
                        }
                        ::before {
                            content: "\f00d";
                            font-family: ${theme.fonts.family.fontAwesomeL};
                            font-size: 13px;
                            line-height: 0;
                            color: #fff;
                        }
                    }
                }
                .check_list_done {
                    flex: 1;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    p {
                        margin: 0 0 0 0.5rem;
                        font-size: 1.3rem;
                        font-weight: normal;
                        color: #777;
                    }
                }
            }
        `;
    }}
`;
function Payment({
    history,
    match,
    location,
    users,
    setUsers,
    selectUsers,
    setSelectUsers,
    activeModal,
    setActiveModal,
    id,
    name,
    isDiscount,
    discountPrice,
    price,
    productCount,
    productPrice,
    giftProductOptionsCount,
    giftProductOptions,
    totalPrice,
    count,
    optionSelect,
    minus,
    plus,
    onSubmit,
}) {
    return (
        <SPayment
            style={
                activeModal === "Payment"
                    ? { display: "block" }
                    : { display: "none" }
            }
        >
            <div className="price_top">
                <ul
                    className="check_list"
                    onClick={() => setActiveModal("Giftuser")}
                >
                    {selectUsers.length > 0 ? (
                        <>
                            {selectUsers.map((item, index) => {
                                const avatarImage =
                                    item.avatar === ""
                                        ? "/assets/img/user/user-default.jpg"
                                        : `${graphqlUrl}${item.avatar}`;
                                return (
                                    <li key={index}>
                                        <Roundbox>
                                            <Button className="close"></Button>
                                            <div className="img">
                                                <img
                                                    src={avatarImage}
                                                    alt={item.name}
                                                />
                                            </div>
                                        </Roundbox>
                                        <p>{item.name}님</p>
                                    </li>
                                );
                            })}
                        </>
                    ) : (
                        <li className="check_list_done">
                            <Roundbox>
                                <div className="icon"></div>
                            </Roundbox>
                            <div className="cont">
                                <p>선물 받는분을 검색해주세요.</p>
                            </div>
                        </li>
                    )}
                </ul>
                {giftProductOptionsCount > 0 && (
                    <>
                        <div className="title">옵션선택</div>
                        {giftProductOptions.map((option, index) => (
                            <Selects>
                                <select
                                    onChange={(event) =>
                                        optionSelect(index, event.target.value)
                                    }
                                >
                                    <option value="">{option.name} 선택</option>
                                    {option.giftProductOptionDetails.length >
                                        0 && (
                                        <>
                                            {option.giftProductOptionDetails.map(
                                                (detail) => (
                                                    <option
                                                        key={detail.id}
                                                        value={detail.id}
                                                        disabled={
                                                            detail.isSoldOut
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        {option.name} :{" "}
                                                        {detail.isSoldOut &&
                                                            "[품절]"}{" "}
                                                        {detail.name}{" "}
                                                        {detail.price !== 0 &&
                                                            `(${
                                                                detail.price > 0
                                                                    ? "+"
                                                                    : ""
                                                            }${detail.price.toLocaleString(
                                                                "ko-KR",
                                                                {
                                                                    maximumFractionDigits: 4,
                                                                }
                                                            )}드림)`}
                                                    </option>
                                                )
                                            )}
                                        </>
                                    )}
                                </select>
                            </Selects>
                        ))}
                    </>
                )}
                <InputGroup>
                    <button onClick={minus}>
                        <FontAwesomeIcon icon={faMinus} />
                    </button>
                    <input value={count} readOnly={true} />
                    <button onClick={plus}>
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                </InputGroup>
            </div>
            <div className="price_content">
                <div className="flextext">
                    <ul>
                        <li className="title">
                            <h3>{name}</h3>
                        </li>
                        <li>
                            <div className="left">금액</div>
                            <div className="right">
                                <b>
                                    <NumberFormat
                                        value={productPrice}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                    />
                                </b>
                                드림
                            </div>
                        </li>
                        <li>
                            <div className="left">수량(수신인원 X 수량)</div>
                            <div className="right">
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    color={"#777"}
                                />{" "}
                                <b>
                                    <NumberFormat
                                        value={productCount}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                    />
                                </b>
                                개
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="price_total">
                    <div className="left">총 금액</div>
                    <div className="right">
                        <b>
                            <NumberFormat
                                value={totalPrice}
                                displayType={"text"}
                                thousandSeparator={true}
                            />
                        </b>
                        드림
                    </div>
                </div>
            </div>
            <Col className="bottom">
                <Button onClick={onSubmit} color="primary" width="full">
                    선물하기
                </Button>
            </Col>
        </SPayment>
    );
}

export default withRouter(Payment);
