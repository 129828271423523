import React from "react"
import styled, { css } from "styled-components"
import { withRouter } from "react-router-dom"
import ReactMoment from "react-moment"
import { graphqlUrl } from "../../../../../Config/Env"
import Label from "../../../../Labels"

export const SItem = styled.div`
  ${({ theme }) => {
    return css`
      margin-bottom: ${theme.margins.base};
      .img {
        position: relative;
        height: 0;
        padding-bottom: 100%;
        border-radius: 8px;
        overflow: hidden;
        :before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          border: 1px solid rgba(0, 0, 0, 0.02);
        }
        img {
          max-width: 100%;
          display: block;
          width: 100%;
          height: auto;
        }
        .day {
          position: absolute;
          background-color: ${theme.colors.black02};
          left: 8px;
          top: 8px;
          border-radius: 4px;
          padding: 0 5px;
          line-height: 24px;
          font-size: 13px;
          color: white;
          font-weight: ${theme.fonts.weight.medium};
        }
      }
      .complete_icon {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.6);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 99;
        display: flex;
        justify-content: center;
        align-items: center;
        div {
          position: relative;
          width: 100px;
          height: 100px;
          border: solid 2px white;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: ${theme.fonts.size.base};
          line-height: 1.4;
          color: white;
        }
      }
      .status_ready {
        position: absolute;
        top: 5px;
        left: 5px;
        color: ${theme.colors.black04};
      }
      .cont {
        margin-top: 10px;
        .category {
          color: ${theme.colors.black03};
          font-size: 13px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          display: block;
        }
        h3 {
          margin-top: ${theme.margins.sm};
          display: block;
          height: 40px;
          overflow: hidden;
          font-size: 15px;
          color: ${theme.colors.black01};
          font-weight: ${theme.fonts.weight.medium};
          line-height: 1.3;
        }
      }
      .name {
        margin-top: ${theme.margins.sm};
        font-size: ${theme.fonts.size.sm};
        color: #555;
      }
      .date {
        margin-top: ${theme.margins.xs};
        color: ${theme.colors.black04};
        font-size: ${theme.fonts.size.sm};
        font-weight: ${theme.fonts.weight.base};
      }
    `
  }}
`

function Item({ history, match, location, item }) {
  const CoverImage =
    item.giftProduct.coverImage === null
      ? "/assets/img/product/product-default.jpg"
      : `${graphqlUrl}${item.giftProduct.coverImage}`

  const toDay = new Date()
  const endDateObj = new Date(item.endDate)
  const gap = endDateObj.getTime() - toDay.getTime()
  const dDay = Math.ceil(gap / (1000 * 60 * 60 * 24))

  // console.log({ toDay, endDateObj });

  return (
    <SItem>
      <div className="img">
        <img src={CoverImage} alt={item.giftProduct.name} />
        {item.commonStatus === "S" && (
          <>
            {toDay <= endDateObj ? (
              <span className="day">잔여일 {dDay}일</span>
            ) : (
              <div className="complete_icon">
                <div>
                  <span>
                    기간
                    <br />
                    만료
                  </span>
                </div>
              </div>
            )}
          </>
        )}
        {item.commonStatus === "C" && (
          <div className="complete_icon">
            <div>
              {item.isRefund === true && (
                <span>
                  환불
                  <br />
                  완료
                </span>
              )}
              {item.isRefund === false && (
                <span>
                  사용
                  <br />
                  완료
                </span>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="cont">
        <span className="category">{item.giftProduct.store.name}</span>
        <h3>{item.giftProduct.name}</h3>
        <div className="info">
          <div className="name">From. {item.sender.name}</div>
          <div className="date">
            <ReactMoment format="YYYY.MM.DD HH:mm:ss">
              {item.createdAt}
            </ReactMoment>
          </div>
        </div>
      </div>
    </SItem>
  )
}
export default withRouter(Item)
