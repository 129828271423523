import React, { useState } from "react";
import { withRouter } from "react-router-dom";

import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faCheck } from "@fortawesome/pro-regular-svg-icons";
import Giftuser from "./Giftuser";
import Payment from "./Payment";
import { toast } from "react-toastify";

const SModal = styled.div`
  ${({ theme }) => {
    return css`
      .modal {
        display: none;
        position: fixed;
        bottom: 0;
        left: 0;
        right: -0;
        z-index: 99;
        section {
          box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.1);
          width: 100%;
          max-width: 750px;
          margin: 0 auto;
          border-radius: 0.3rem;
          background-color: #fff;
          /* 팝업이 열릴때 스르륵 열리는 효과 */
          animation: modal-show 0.3s;
          overflow: hidden;
          main {
            border-bottom: 1px solid #dee2e6;
            border-top: 1px solid #dee2e6;
          }
        }
        header {
          position: relative;
          height: 40px;
          font-weight: 700;
          justify-content: center;
          align-items: center;
          display: flex;
          border-bottom: 1px solid #eee;
          button {
            font-size: 21px;
            font-weight: 700;
            text-align: center;
            border: 0;
            color: #999;
            background-color: transparent;
          }
        }
      }
      .modal.openModal {
        display: flex;
        align-items: center;
      }
      @keyframes modal-show {
        from {
          margin-top: -100px;
        }
        to {
          margin-top: 0;
        }
      }
    `;
  }}
`;
const Stepbox = styled.div`
  ${({ theme }) => {
    return css`
      .btn {
        background-color: ${theme.colors.lightgray};
        display: flex;
        width: 100%;
        flex-direction: row;
        padding: 1.2rem ${theme.paddings.sm};
        border-bottom: solid 1px #ddd;
        font-size: 1.4rem;
        color: #bbb;
        span {
          flex: 1;
          text-align: left;
        }
        &.active {
          background-color: ${theme.colors.lightprimary};
          font-weight: ${theme.fonts.weight.medium};
          color: ${theme.colors.primary};
          .icon {
            color: ${theme.colors.primary};
          }
        }
      }
    `;
  }}
`;

function Modal({
  history,
  match,
  location,
  isOpen,
  toggleModal,
  id,
  name,
  isDiscount,
  discountPrice,
  price,
  limit,
  giftOrderProductCount,
  giftProductOptionsCount,
  giftProductOptions,
}) {
  const [activeModal, setActiveModal] = useState("Giftuser");

  const [users, setUsers] = useState([]);
  const [selectUsers, setSelectUsers] = useState([]);

  const [productCount, setProductCount] = useState(1);
  const [productPrice, setProductPrice] = useState(price);

  // GiftOrder
  const [totalProductPrice, setTotalProductPrice] = useState(price);
  // const [totalDiscountPrice, setTotalDiscountPrice] = useState(discountPrice);
  // const [totalCount, setTotalCount] = useState(1);
  const [totalPrice, setTotalPrice] = useState(totalProductPrice);

  // GiftOrderProduct
  // isDiscount
  const giftProductOptionDetail1 = giftProductOptions[0]
    ? giftProductOptions[0].giftProductOptionDetails[0].id
    : null;
  const giftProductOptionDetailPrice1 = giftProductOptions[0]
    ? giftProductOptions[0].giftProductOptionDetails[0].price
    : 0;
  const giftProductOptionDetail2 = giftProductOptions[1]
    ? giftProductOptions[1].giftProductOptionDetails[0].id
    : null;
  const giftProductOptionDetailPrice2 = giftProductOptions[1]
    ? giftProductOptions[1].giftProductOptionDetails[0].price
    : 0;
  const giftProductOptionDetail3 = giftProductOptions[2]
    ? giftProductOptions[2].giftProductOptionDetails[0].id
    : null;
  const giftProductOptionDetailPrice3 = giftProductOptions[2]
    ? giftProductOptions[2].giftProductOptionDetails[0].price
    : 0;
  const giftProductOptionDetail4 = giftProductOptions[3]
    ? giftProductOptions[3].giftProductOptionDetails[0].id
    : null;
  const giftProductOptionDetailPrice4 = giftProductOptions[3]
    ? giftProductOptions[3].giftProductOptionDetails[0].price
    : 0;
  const giftProductOptionDetail5 = giftProductOptions[4]
    ? giftProductOptions[4].giftProductOptionDetails[0].id
    : null;
  const giftProductOptionDetailPrice5 = giftProductOptions[4]
    ? giftProductOptions[4].giftProductOptionDetails[0].price
    : 0;
  // price
  const [count, setCount] = useState(1);

  const userSelect = (user) => {
    const userIds =
      users.indexOf(user.id) > -1
        ? [...users.filter((item) => item !== user.id)]
        : [...users, user.id];
    const userObjects =
      users.indexOf(user.id) > -1
        ? [...selectUsers.filter((item) => item.id !== user.id)]
        : [...selectUsers, user];

    setUsers(userIds);
    setSelectUsers(userObjects);
    setProductCount(userIds.length);

    totalSum({
      userLength: userIds.length,
      optionPrice1: giftProductOptionDetailPrice1,
      optionPrice2: giftProductOptionDetailPrice2,
      optionPrice3: giftProductOptionDetailPrice3,
      optionPrice4: giftProductOptionDetailPrice4,
      optionPrice5: giftProductOptionDetailPrice5,
      price,
      count,
    });
  };
  const userUnSelect = (user) => {
    const userIds = [...users.filter((item) => item !== user.id)];
    const userObjects = [...selectUsers.filter((item) => item.id !== user.id)];

    setUsers(userIds);
    setSelectUsers(userObjects);
    setProductCount(userIds.length);

    totalSum({
      userLength: userIds.length,
      optionPrice1: giftProductOptionDetailPrice1,
      optionPrice2: giftProductOptionDetailPrice2,
      optionPrice3: giftProductOptionDetailPrice3,
      optionPrice4: giftProductOptionDetailPrice4,
      optionPrice5: giftProductOptionDetailPrice5,
      price,
      count,
    });
  };
  const minus = () => {
    if (count <= 1) return;
    setCount(count - 1);
    setTotalProductPrice((count - 1) * price);
    // setTotalDiscountPrice((count - 1) * discountPrice);

    setProductCount((count - 1) * users.length);
    // setTotalCount(count - 1);
    setTotalPrice((count - 1) * price);
    totalSum({
      userLength: users.length,
      optionPrice1: giftProductOptionDetailPrice1,
      optionPrice2: giftProductOptionDetailPrice2,
      optionPrice3: giftProductOptionDetailPrice3,
      optionPrice4: giftProductOptionDetailPrice4,
      optionPrice5: giftProductOptionDetailPrice5,
      price,
      count: count - 1,
    });
  };
  const plus = () => {
    setCount(count + 1);
    setTotalProductPrice((count + 1) * price);
    // setTotalDiscountPrice((count + 1) * discountPrice);

    setProductCount((count + 1) * users.length);
    // setTotalCount(count + 1);
    setTotalPrice((count + 1) * price);
    totalSum({
      userLength: users.length,
      optionPrice1: giftProductOptionDetailPrice1,
      optionPrice2: giftProductOptionDetailPrice2,
      optionPrice3: giftProductOptionDetailPrice3,
      optionPrice4: giftProductOptionDetailPrice4,
      optionPrice5: giftProductOptionDetailPrice5,
      price,
      count: count + 1,
    });
  };
  const totalSum = ({
    userLength,
    optionPrice1,
    optionPrice2,
    optionPrice3,
    optionPrice4,
    optionPrice5,
    price,
    count,
  }) => {
    const productPrice =
      price +
      optionPrice1 +
      optionPrice2 +
      optionPrice3 +
      optionPrice4 +
      optionPrice5;
    const totalPrice =
      (price +
        optionPrice1 +
        optionPrice2 +
        optionPrice3 +
        optionPrice4 +
        optionPrice5) *
      count *
      userLength;

    setProductPrice(productPrice);
    setTotalPrice(totalPrice);
  };

  const onSubmit = () => {
    if (giftProductOptionsCount > 0 && !giftProductOptionDetail1) {
      toast.error(`${giftProductOptions[0].name} 옵션을 선택해주세요.`);
      return;
    }
    if (giftProductOptionsCount > 1 && !giftProductOptionDetail2) {
      toast.error(`${giftProductOptions[1].name} 옵션을 선택해주세요.`);
      return;
    }
    if (giftProductOptionsCount > 2 && !giftProductOptionDetail3) {
      toast.error(`${giftProductOptions[2].name} 옵션을 선택해주세요.`);
      return;
    }
    if (giftProductOptionsCount > 3 && !giftProductOptionDetail4) {
      toast.error(`${giftProductOptions[3].name} 옵션을 선택해주세요.`);
      return;
    }
    if (giftProductOptionsCount > 4 && !giftProductOptionDetail5) {
      toast.error(`${giftProductOptions[4].name} 옵션을 선택해주세요.`);
      return;
    }

    if (limit - (giftOrderProductCount + productCount) < 0) {
      const orderAbleCount = limit - giftOrderProductCount;
      toast.error(`${orderAbleCount}매 이상 예매할 수 없습니다.`);
      return;
    }

    history.push(`/order`, {
      productType: "Ticket",
      orderType: "gift",
      users,
      selectUsers,
      id,
      giftProductOptionDetail1,
      giftProductOptionDetail2,
      giftProductOptionDetail3,
      giftProductOptionDetail4,
      giftProductOptionDetail5,
      count,
    });
  };

  return (
    <SModal>
      <div className={isOpen ? "openModal modal" : "modal"}>
        {isOpen ? (
          <section>
            <header>
              <button onClick={toggleModal}>
                <FontAwesomeIcon icon={faChevronDown} />
              </button>
            </header>
            <main>
              <Stepbox>
                <button
                  onClick={() => setActiveModal("Giftuser")}
                  className={`btn ${
                    activeModal === "Giftuser" ? "active" : ""
                  }`}
                >
                  <span>수신자</span>
                  <FontAwesomeIcon className="icon" icon={faCheck} />
                </button>
                <div>
                  <Giftuser
                    users={users}
                    setUsers={setUsers}
                    selectUsers={selectUsers}
                    setSelectUsers={setSelectUsers}
                    activeModal={activeModal}
                    setActiveModal={setActiveModal}
                    userSelect={userSelect}
                    userUnSelect={userUnSelect}
                  />
                </div>
              </Stepbox>
              <Stepbox>
                <button
                  className={`btn ${activeModal === "Payment" ? "active" : ""}`}
                >
                  <span>옵션선택</span>
                  <FontAwesomeIcon icon={faCheck} />
                </button>
                <div>
                  <Payment
                    users={users}
                    setUsers={setUsers}
                    selectUsers={selectUsers}
                    setSelectUsers={setSelectUsers}
                    activeModal={activeModal}
                    setActiveModal={setActiveModal}
                    id={id}
                    name={name}
                    isDiscount={isDiscount}
                    discountPrice={discountPrice}
                    price={price}
                    productCount={productCount}
                    productPrice={productPrice}
                    limit={limit}
                    giftOrderProductCount={giftOrderProductCount}
                    giftProductOptionsCount={giftProductOptionsCount}
                    giftProductOptions={giftProductOptions}
                    totalPrice={totalPrice}
                    count={count}
                    minus={minus}
                    plus={plus}
                    onSubmit={onSubmit}
                  />
                </div>
              </Stepbox>
            </main>
          </section>
        ) : null}
      </div>
    </SModal>
  );
}

export default withRouter(Modal);
