const Menu = [
  {
    path: ["/home"],
    title: "홈",
  },
  {
    path: ["/products", "/products/:id", "/products/:id/inquiry"],
    title: "상품",
  },
  {
    path: ["/stores", "/stores/:id"],
    title: "가게",
  },
  // {
  //   path: ["/tickets", "/tickets/:id", "/tickets/:id/inquiry"],
  //   title: "티켓",
  // },
  {
    path: [
      "/account",
      "/account/orderProducts",
      "/account/wish",
      "/account/reviews",
      "/account/reviews/:id",
      "/account/inpuiries",
      "/account/inpuiries/:id",
      "/account/orders",
      "/account/orders/:id",
      "/notices",
    ],
    title: "선물함",
  },
];

export default Menu;
