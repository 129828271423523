import React from "react"
import { Link } from "react-router-dom"

import styled, { css } from "styled-components"
import { graphqlUrl } from "../../../Config/Env"

const SItem = styled.div`
  ${({ theme }) => {
    return css`
      a {
        display: flex;
        flex-direction: column;
        text-align: center;
      }
      .img {
        display: flex;
        border-radius: 8px;
        border: solid 1px #eee;
        overflow: hidden;
        img {
          max-width: 100%;
        }
      }
      .cont {
        margin-top: 10px;
        text-align: center;
        h3 {
          height: 36px;
          overflow: hidden;
          font-size: ${theme.fonts.size.sm};
          font-family: ${theme.fonts.family.base};
          font-weight: ${theme.fonts.weight.medium};
          line-height: 1.3;
        }
      }
      .discount {
        margin-top: 10px;
        color: ${theme.colors.danger};
        font-size: ${theme.fonts.size.sm};
        span {
          color: ${theme.colors.black04};
          text-decoration: line-through;
        }
      }
      .price {
        margin-top: 5px;
        font-size: 16px;
        color: #333;
        font-weight: 500;
      }
    `
  }}
`

function Item({ history, match, location, item }) {
  const storeLogoImage =
    item.storeLogo === "" || item.storeLogo === null
      ? "./assets/img/store-default.png"
      : `${graphqlUrl}${item.storeLogo}`
  return (
    <SItem>
      <div className="img">
        <img src={storeLogoImage} alt={item.name} />
      </div>
      <div className="cont">
        <h3>{item.name}</h3>
      </div>
    </SItem>
  )
}
export default Item
