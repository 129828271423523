import { Link } from "react-router-dom";

import { Ready } from "../../../styles/styles";
import styled, { css } from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";
import { graphqlUrl } from "../../../Config/Env";

const SSwiper = styled(Swiper)`
    ${({ theme }) => {
        return css`
            padding: ${theme.paddings.sm} ${theme.paddings.lg};
        `;
    }}
`;
const CateItems = styled.div`
    ${({ theme }) => {
        return css`
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                .round_box {
                    border: solid 1px #eee;
                    display: flex;
                    width: 96px;
                    height: 96px;
                    align-items: center;
                    justify-content: center;
                    border-radius: 8px;
                    overflow: hidden;
                    img {
                        max-width: 100%;
                    }
                }
                p {
                    margin-top: 8px;
                    color: ${theme.colors.black01};
                    font-size: 13px;
                    text-align: center;
                    height: 30px;
                    overflow: hidden;
                    line-height: 1.3;
                }
            }
        `;
    }}
`;
SwiperCore.use([Pagination, Navigation]);

function Store({ stores }) {
    return (
        <SSwiper spaceBetween={10} slidesPerView={3}>
            <>
                {stores ? (
                    <>
                        {stores &&
                            stores.map((item, index) => {
                                const storeLogoImage =
                                    item.storeLogo === "" ||
                                    item.storeLogo === null
                                        ? "./assets/img/store-default.png"
                                        : `${graphqlUrl}${item.storeLogo}`;
                                return (
                                    <SwiperSlide key={item.id}>
                                        <CateItems>
                                            <Link to={`/stores/${item.id}`}>
                                                <div className="round_box">
                                                    <img
                                                        src={storeLogoImage}
                                                        alt={item.name}
                                                    />
                                                </div>
                                                <p>{item.name}</p>
                                            </Link>
                                        </CateItems>
                                    </SwiperSlide>
                                );
                            })}
                    </>
                ) : (
                    <Ready>
                        <div className="list_none">등록된 상품이 없습니다.</div>
                    </Ready>
                )}
            </>
        </SSwiper>
    );
}

export default Store;
