import styled, { css } from "styled-components"
import { Link } from "react-router-dom"
import { Col } from "../../styles/styles"

import { Layout } from "../../Components/Layouts/Layout"
import PageTitle from "../../Components/Layouts/PageTitle"
import Banner from "../../Components/Banner/Home/Banner"
import Category from "../../Components/Category/Home/Category"
import Product from "../../Components/Product/Product/Home/Product"
import Store from "../../Components/Store/Home/Store"
import Search from "../../Components/Search/Home/Search"
import { ArrowRight2 } from "iconsax-react"
// import Ticket from "../../Components/Product/Ticket/Home/Ticket";

const MainSection = styled.div`
  ${({ theme }) => {
    return css`
      padding: ${theme.paddings.base} 0;
      .category {
        margin: ${theme.margins.lg} 0 0;
      }
      .product {
        padding: 0 ${theme.paddings.xl} ${theme.paddings.lg};
      }
      .store {
        padding: 0 0 ${theme.paddings.lg};
      }
    `
  }}
`
const STitle = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-direction: row;
      height: 40px;
      justify-content: space-between;
      align-items: center;
      padding: ${theme.paddings.base} ${theme.paddings.xl} 0;
      .left {
      }
      .right {
        justify-content: flex-end;
      }
      h3 {
        font-size: 18px;
        font-family: ${theme.fonts.family.base};
        font-weight: ${theme.fonts.weight.bold};
        b {
          color: ${theme.colors.warning};
        }
      }
      .more {
        display: flex;
        align-items: center;
        position: relative;
        font-size: 13px;
        color: ${theme.colors.black02};
      }
    `
  }}
`

function Home({ loading, data }) {
  return (
    <Layout isNav={true} isFooter={true}>
      <PageTitle title="홈" />
      {!loading ? (
        <>
          <Banner giftBanners={data?.GiftMain?.giftBanners} />
          <MainSection>
            <div className="category">
              <Category giftCategories={data?.GiftMain?.giftCategories} />
            </div>
            <STitle>
              <h3>
                상품 BEST <b>10</b>
              </h3>
              <Link to={`/products?orderBy=orderCount_DESC`} className="more">
                더보기
                <ArrowRight2 size="14" color="#777" />
              </Link>
            </STitle>
            <div className="product">
              <Product giftProducts={data?.GiftMain?.giftProducts} />
            </div>
            <STitle>
              <h3>
                신규가게 <b>10</b>
              </h3>
              <Link to={`/stores`} className="more">
                더보기
                <ArrowRight2 size="14" color="#777" />
              </Link>
            </STitle>
            <div className="store">
              <Store stores={data?.GiftMain?.stores} />
            </div>
            {/* <div className="ticket">
              <STitle>티켓정보</STitle>
              <Ticket giftTickets={data.GiftMain.giftTickets} />
            </div> */}
          </MainSection>
        </>
      ) : (
        <div className="panel-loader">
          <span className="spinner-small"></span>
        </div>
      )}
    </Layout>
  )
}

export default Home
