import { useState } from "react";
import { withRouter } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
  ACCOUNT_ORDERREFUND_MUTATION,
  ACCOUNT_ORDER_QUERY,
} from "../../../../../Config/Queries";

import styled, { css } from "styled-components";
import { Row, Col, Roundbox, SearchList } from "../../../../../styles/styles";
import Buttons from "../../../../Buttons";
import { graphqlUrl } from "../../../../../Config/Env";
import { toast } from "react-toastify";
import { maskingName } from "../../../../../Config/Util";

const SSearchList = styled(SearchList)`
  ${({ theme }) => {
    return css`
      margin: ${theme.margins.base};
      border: solid 1px #eee;
      border-radius: 5px;
      overflow: hidden;
      .title {
        font-weight: ${theme.fonts.weight.medium};
        font-size: 14px;
      }
      .search_top {
        padding: ${theme.paddings.base};
        border-bottom: solid 1px #eee;
      }
      .search_content {
        ul {
          padding: ${theme.paddings.base} 0;
          background-color: #fafafa;
          border-bottom: 0;
        }
      }
      .search_bottom {
        padding: ${theme.paddings.base};
        font-size: ${theme.fonts.size.sm};
        line-height: 1.4;
        color: #555;
      }
    `;
  }}
`;

function TicketOrderProduct({
  history,
  match,
  location,
  data,
  ticketDatetime,
  refundAbleDate,
}) {
  const id = match.params.id;

  const {
    GiftAccountOrderDetail: { giftOrderProducts },
  } = data;

  const [orderProducts, setOrderProducts] = useState([]);

  const orderProductSelectToggle = (orderProduct) => {
    const orderProductIds =
      orderProducts.indexOf(orderProduct.id) > -1
        ? [...orderProducts.filter((item) => item !== orderProduct.id)]
        : [...orderProducts, orderProduct.id];

    setOrderProducts(orderProductIds);
  };

  const [orderProductRejectMutation] = useMutation(
    ACCOUNT_ORDERREFUND_MUTATION,
    {
      refetchQueries: () => [
        {
          query: ACCOUNT_ORDER_QUERY,
          variables: {
            id,
          },
        },
      ],
    }
  );
  const onSubmit = async () => {
    if (orderProducts.length === 0) {
      toast.error(`선물내역에 취소하려는 선물을 선택해주세요.`);
      return;
    }

    if (!window.confirm("선택한 선물을 환불하시겠습니까?")) {
      return;
    }

    try {
      const {
        data: { GiftAccountOrderRefund },
      } = await orderProductRejectMutation({
        variables: { id, giftOrderProductIds: orderProducts },
      });

      if (GiftAccountOrderRefund) {
        // refetch();
        // setItems([...items.filter((item) => item.id !== id)]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <SSearchList>
      <Row className="search_top">
        <Col left>
          <div className="title">
            선물내역 <b>{giftOrderProducts.length}</b>건
          </div>
        </Col>
        <Col right>
          <Buttons onClick={onSubmit} color="danger" border="solid" size="xs">
            환불하기
          </Buttons>
        </Col>
      </Row>
      <div className="search_content">
        <ul>
          {giftOrderProducts.map((item, index) => {
            const avatarImage =
              item.user.avatar === "" || item.user.avatar === null
                ? "/assets/img/user/user-default.jpg"
                : `${graphqlUrl}${item.user.avatar}`;

            const toDay = new Date();

            return (
              <li key={index}>
                <label>
                  <Roundbox>
                    <div className="img">
                      <img src={avatarImage} alt={item.user.name} />
                    </div>
                  </Roundbox>
                  {item.commonStatus === "S" && (
                    <>
                      {toDay <= refundAbleDate ? (
                        <input
                          type="checkbox"
                          onChange={() => orderProductSelectToggle(item)}
                          checked={orderProducts.indexOf(item.id) > -1}
                        />
                      ) : (
                        <div className="option">
                          <span>
                            기간
                            <br />
                            만료
                          </span>
                        </div>
                      )}
                    </>
                  )}
                  {item.commonStatus === "C" && (
                    <div className="option">
                      {item.isRefund === true && (
                        <>
                          {item.meRefund === true && (
                            <span>
                              사용
                              <br />
                              결정
                            </span>
                          )}
                          {(item.meRefund === false ||
                            item.meRefund === null) && <span>환불</span>}
                        </>
                      )}
                      {item.isRefund === false && (
                        <span>
                          사용
                          <br />
                          완료
                        </span>
                      )}
                    </div>
                  )}
                  <div className="name">
                    {item.user.name}
                    <span>({maskingName(item.user.userId)})</span>
                  </div>
                </label>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="search_bottom">
        <p>* 사용여부를 확인 할 수 있습니다.</p>
        <p>* 수신자 선택 후 환불하기를 해주세요.</p>
        <p>* 소유권은 행사/공연일 까지이며, 당일 환불은 불가합니다.</p>
      </div>
    </SSearchList>
  );
}

export default withRouter(TicketOrderProduct);
