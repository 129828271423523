import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import {
    TOGGLEWISHLIST_MUTATION,
    ISWISHLIST_QUERY,
} from "../../../../../Config/Queries";

import { toast } from "react-toastify";
import styled, { css } from "styled-components";
import SLoading from "../../../../Loading";
import { Heart } from "iconsax-react";

function Container({ history, match, location, isLoggedIn }) {
    const id = match.params.id;

    const [toggleWishlistMutation] = useMutation(TOGGLEWISHLIST_MUTATION, {
        refetchQueries: () => [{ query: ISWISHLIST_QUERY, variables: { id } }],
    });

    const { data, loading } = useQuery(ISWISHLIST_QUERY, {
        variables: {
            id,
        },
    });

    const toggleWishlist = async () => {
        if (!isLoggedIn) {
            toast.error("로그인 후 이용해주세요.");
            history.push(`/login`);
            return;
        }
        try {
            const {
                data: { GiftProductToggleWishlist },
            } = await toggleWishlistMutation({
                variables: {
                    id,
                },
            });
            console.log(GiftProductToggleWishlist);
        } catch (e) {
            console.log(e);
        }
    };

    if (!loading) {
        const { GiftProductIsWishlist: isWishlist } = data;
        return (
            <button
                className={isWishlist ? "like active" : "like"}
                onClick={() => {
                    toggleWishlist();
                }}
            >
                {isWishlist ? (
                    <Heart size="20" variant="Bold" />
                ) : (
                    <Heart size="20" variant="Linear" />
                )}
                <span>위시리스트</span>
            </button>
        );
    } else {
        return <SLoading />;
    }
}
export default withRouter(Container);
