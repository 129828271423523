import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { ACCOUNT_WISHLISTS_QUERY } from "../../Config/Queries";

import { Layout } from "../../Components/Layouts/Layout";
import PageTitle from "../../Components/Layouts/PageTitle";
import styled, { css } from "styled-components";
import {
    Ready,
    Container,
    ListTitle,
    MainTitle,
    MainText,
} from "../../styles/styles";
import Item from "../../Components/Wishlist/Account/Wishlist/Item";
import Pagination from "../../Components/Pagination";
import SLoading from "../../Components/Loading";
import qs from "qs";

const SContainer = styled(Container)`
    ${({ theme }) => {
        return css`
            padding: 0 ${theme.paddings.xl};
        `;
    }}
`;
const SItemlist = styled.div`
    ${({ theme }) => {
        return css`
            li {
                margin-bottom: ${theme.margins.base};
            }
        `;
    }}
`;

function Wishlist({ history, match, location }) {
    const queryString = qs.parse(location.search.substr(1));
    const page = queryString.page ? queryString.page : 1;
    const blockSize = 5;
    const first = 12;
    const skip = first * (page - 1);

    const baseUrl = "?";

    const { data, loading } = useQuery(ACCOUNT_WISHLISTS_QUERY, {
        fetchPolicy: "network-only",
        variables: {
            orderBy: "id_DESC",
            skip,
            first,
        },
    });

    return (
        <>
            <Layout isNav={true} isFooter={true}>
                <PageTitle title="위시리스트" />
                <SContainer>
                    <MainTitle>
                        <MainText>위시리스트가</MainText>
                        <MainText>
                            <span className="TextBlue">
                                {!loading && data.GiftAccountWishlistList.count}
                            </span>
                            건 입니다.
                        </MainText>
                    </MainTitle>
                    {!loading ? (
                        <>
                            {data.GiftAccountWishlistList.count > 0 ? (
                                <>
                                    <SItemlist>
                                        <ul>
                                            {data.GiftAccountWishlistList.giftWishlists.map(
                                                (item, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <Item item={item} />
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    </SItemlist>
                                    <Pagination
                                        totalRecord={
                                            data.GiftAccountWishlistList.count
                                        }
                                        blockSize={blockSize}
                                        pageSize={first}
                                        currentPage={page}
                                        baseUrl={baseUrl}
                                    />
                                </>
                            ) : (
                                <Ready>
                                    <div>등록된 선물이 없습니다.</div>
                                </Ready>
                            )}
                        </>
                    ) : (
                        <SLoading />
                    )}
                </SContainer>
            </Layout>
        </>
    );
}

export default withRouter(Wishlist);
