import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { ORDERINIT_QUERY } from "../../Config/Queries";

import { Layout } from "../../Components/Layouts/Layout";
import PageTitle from "../../Components/Layouts/PageTitle";
import styled, { css } from "styled-components";
import Link from "../../Components/Links";
import Loading from "../../Components/Loading";
import { Roundbox, FooterButton } from "../../styles/styles";
import { graphqlUrl } from "../../Config/Env";
import renderHTML from "react-render-html";
import Product from "../../Components/Order/Result/Product";
import Ticket from "../../Components/Order/Result/Ticket";

const ComplationBox = styled.div`
    ${({ theme }) => {
        return css`
            .complation_top {
                flex: 1;
                display: flex;
                flex-direction: column;
                background-color: ${theme.colors.lightgray};
                padding: 5rem 0;
                justify-content: center;
                align-items: center;
                .user_photo {
                    ul {
                        display: flex;
                        justify-content: center;
                        li {
                            margin-left: -10px;
                            :first-child {
                                margin-left: 0;
                            }
                        }
                    }
                }
                .round_box {
                    .img {
                        width: 52px;
                        height: 52px;
                    }
                }
                .text {
                    text-align: center;
                    margin-top: ${theme.margins.base};
                    line-height: 1.3;
                    font-size: ${theme.fonts.size.lg};
                    color: ${theme.colors.black01};
                    b {
                    }
                }
            }
        `;
    }}
`;

function Result({ history, match, location }) {
    const productType = location?.state?.productType;
    // const orderType = location?.state?.orderType;
    const users = location?.state?.users;
    const selectUsers = location?.state?.selectUsers;
    const id = location?.state?.id;
    const giftProductOptionDetail1 = location?.state?.giftProductOptionDetail1;
    const giftProductOptionDetail2 = location?.state?.giftProductOptionDetail2;
    const giftProductOptionDetail3 = location?.state?.giftProductOptionDetail3;
    const giftProductOptionDetail4 = location?.state?.giftProductOptionDetail4;
    const giftProductOptionDetail5 = location?.state?.giftProductOptionDetail5;
    const count = location?.state?.count;
    // const message = location?.state?.message;

    const { data, loading, error } = useQuery(ORDERINIT_QUERY, {
        variables: {
            id,
        },
    });

    if (!loading && !error) {
        return (
            <Layout isNav={false} isFooterButton={true}>
                <ComplationBox>
                    <div className="complation_top">
                        <div className="user_photo">
                            <ul>
                                {selectUsers.map((item, index) => {
                                    const avatarImage =
                                        item.avatar === ""
                                            ? "/assets/img/user/user-default.jpg"
                                            : `${graphqlUrl}${item.avatar}`;
                                    return (
                                        <li key={index}>
                                            <Roundbox className="round_box">
                                                <div className="img">
                                                    <img
                                                        src={avatarImage}
                                                        alt={item.name}
                                                    />
                                                </div>
                                            </Roundbox>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div className="text">
                            <b>
                                {selectUsers[0]?.name}님
                                {renderHTML(
                                    users.length > 1
                                        ? `외 <b>${users.length - 1}</b>명`
                                        : ``
                                )}
                            </b>
                            에게
                            <br />
                            선물을 보냈습니다.
                        </div>
                    </div>

                    {productType === "Product" && (
                        <Product
                            users={users}
                            giftProductOptionDetail1={giftProductOptionDetail1}
                            giftProductOptionDetail2={giftProductOptionDetail2}
                            giftProductOptionDetail3={giftProductOptionDetail3}
                            giftProductOptionDetail4={giftProductOptionDetail4}
                            giftProductOptionDetail5={giftProductOptionDetail5}
                            count={count}
                            data={data}
                        />
                    )}
                    {productType === "Ticket" && (
                        <Ticket
                            users={users}
                            giftProductOptionDetail1={giftProductOptionDetail1}
                            giftProductOptionDetail2={giftProductOptionDetail2}
                            giftProductOptionDetail3={giftProductOptionDetail3}
                            giftProductOptionDetail4={giftProductOptionDetail4}
                            giftProductOptionDetail5={giftProductOptionDetail5}
                            count={count}
                            data={data}
                        />
                    )}

                    <FooterButton>
                        <div>
                            <Link
                                to="/account/orders"
                                color="lightprimary"
                                width="full"
                                border="radius"
                            >
                                구매내역
                            </Link>
                            <Link
                                to="/home"
                                color="primary"
                                width="full"
                                border="radius"
                            >
                                홈으로
                            </Link>
                        </div>
                    </FooterButton>
                </ComplationBox>
            </Layout>
        );
    } else {
        return <Loading />;
    }
}

export default withRouter(Result);
