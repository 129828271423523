import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import {
    INQUIRY_QUERY,
    INQUIRYEDIT_MUTATION,
    INQUIRIES_QUERY,
} from "../../Config/Queries";

import { Layout } from "../../Components/Layouts/Layout";
import PageTitle from "../../Components/Layouts/PageTitle";
import styled, { css } from "styled-components";
import {
    Container,
    FooterButton,
    MainText,
    MainTitle,
} from "../../styles/styles";
import { useForm } from "react-hook-form";
import FormError from "../../Components/FormError";
import Alter from "../../Components/Alter";
import Button from "../../Components/Buttons";
import { Checkbox } from "@createnl/grouped-checkboxes";
import SLoading from "../../Components/Loading";
import qs from "qs";

const SContainer = styled(Container)`
    ${({ theme }) => {
        return css`
            padding: 0 ${theme.paddings.xl};
        `;
    }}
`;
const SAlter = styled(Alter)`
    ${({ theme }) => {
        return css`
            margin-top: ${theme.margins.base};
            padding: ${theme.paddings.base};
            background-color: #fafafa;
            border-radius: 4px;
            font-weight: ${theme.fonts.weight.medium};
            p {
                margin-bottom: 0;
            }
        `;
    }}
`;
const SFormbox = styled.div`
    ${({ theme }) => {
        return css`
            form {
                display: flex;
                flex-direction: column;
                .title {
                    display: block;
                    margin-bottom: ${theme.margins.base};
                }
                .form_group {
                    label {
                        display: flex;
                        color: ${theme.colors.black03};
                        font-size: ${theme.fonts.size.sm};
                        margin-bottom: ${theme.margins.sm};
                    }
                    textarea {
                        width: 100%;
                        height: 200px;
                        padding: ${theme.paddings.base};
                        border: solid 1px #eee;
                        border-radius: 8px;
                        font-size: ${theme.fonts.size.base};
                    }
                }
                .button {
                    margin-top: ${theme.margins.sm};
                }
            }
            .checkList {
                margin-top: ${theme.margins.base};
                background-color: ${theme.colors.lightgray};
                border-radius: 8px;
                label {
                    display: flex;
                    padding: ${theme.paddings.base};
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    color: #666;
                    span {
                        margin-left: 10px;
                        flex: 1;
                        display: flex;
                        color: #666;
                        font-size: ${theme.fonts.size.base};
                        font-weight: ${theme.fonts.weight.medium};
                    }
                    .check {
                        display: flex;
                        justify-content: flex-end;
                    }
                    input {
                        display: flex;
                        position: relative;
                        justify-content: flex-end;
                    }
                    input[type="checkbox"] {
                        background-color: white;
                        border: solid 1px #ddd;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        :checked {
                            background-color: ${theme.colors.primary};
                            border: solid 1px ${theme.colors.primary};
                            ::before {
                                content: "\f00c";
                                font-family: ${theme.fonts.family.fontAwesome};
                                color: #fff;
                                font-size: 16px;
                            }
                        }
                    }
                    a {
                        display: flex;
                        align-items: center;
                        font-size: ${theme.fonts.size.sm};
                        color: #999;
                        svg {
                            margin-left: 0.5rem;
                        }
                    }
                }
            }
        `;
    }}
`;

function Edit({ history, match, location }) {
    const id = match.params.id;
    const inquiryId = match.params.inquiryId;

    const queryString = qs.parse(location.search.substr(1));
    const redirect = queryString.redirect ? queryString.redirect : "/products";

    const [giftProductId, setGiftProductId] = useState(null);

    const { data, loading } = useQuery(INQUIRY_QUERY, {
        fetchPolicy: "network-only",
        variables: {
            id: inquiryId,
        },
    });

    const [inquiryEditMutation, { editLoading }] = useMutation(
        INQUIRYEDIT_MUTATION,
        {
            refetchQueries: () => [
                {
                    query: INQUIRIES_QUERY,
                    variables: {
                        giftProduct: id,
                        orderBy: "id_DESC",
                        skip: 0,
                        first: 10,
                    },
                },
            ],
        }
    );
    const { register, handleSubmit, errors, setValue } = useForm();

    const onSubmit = async (data) => {
        console.log(data);
        try {
            const {
                data: { GiftProductInquiryEdit },
            } = await inquiryEditMutation({
                variables: {
                    id: inquiryId,
                    isSecret: data.isSecret,
                    question: data.question,
                },
            });

            if (GiftProductInquiryEdit) {
                let uri = ``;
                if (redirect === "/products") {
                    uri = `${redirect}/${giftProductId}`;
                }
                if (redirect === "/account/inquiries") {
                    uri = `${redirect}`;
                }
                history.push(uri);
            }
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        if (!loading) {
            const {
                GiftProductInquiryDetail: { giftProduct, isSecret, question },
            } = data;
            setGiftProductId(giftProduct.id);
            setValue("isSecret", isSecret);
            setValue("question", question);
        }
    }, [data, loading, setValue]);

    if (!loading) {
        const {
            GiftProductInquiryDetail: { isSecret },
        } = data;
        return (
            <>
                <Layout isNav={false}>
                    <PageTitle title="상품문의하기" />
                    <SContainer>
                        <MainTitle>
                            <MainText>문의내용을</MainText>
                            <MainText>수정해주세요</MainText>
                        </MainTitle>
                        <SFormbox>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form_group">
                                    <label>
                                        <textarea
                                            name="question"
                                            placeholder="문의내용을 입력해주세요."
                                            ref={register({
                                                required:
                                                    "문의내용을 입력해주세요.",
                                            })}
                                            rows="10"
                                        ></textarea>
                                    </label>
                                </div>
                                <FormError
                                    message={errors?.question?.message}
                                />

                                <div className="checkList">
                                    <ul>
                                        <li>
                                            <label>
                                                <Checkbox
                                                    name="isSecret"
                                                    className="check"
                                                    ref={register()}
                                                    checked={isSecret}
                                                />
                                                <span>비밀글</span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>

                                <SAlter color="white">
                                    <p>
                                        * 문의하신 내용은 상품판매 가게에서
                                        빠른시일 이내 확인 후 답변드립니다.
                                    </p>
                                </SAlter>
                                <FooterButton>
                                    <div>
                                        <Button
                                            type="submit"
                                            disabled={editLoading}
                                            color="primary"
                                            width="full"
                                        >
                                            {editLoading
                                                ? "처리중..."
                                                : "문의수정"}
                                        </Button>
                                    </div>
                                </FooterButton>
                            </form>
                        </SFormbox>
                    </SContainer>
                </Layout>
            </>
        );
    } else {
        return <SLoading />;
    }
}

export default withRouter(Edit);
