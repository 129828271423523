import React from "react";
import { withRouter, Link } from "react-router-dom";

import styled, { css } from "styled-components";
import { Row, Col, SOrderItem } from "../../../../../styles/styles";
import Label from "../../../../Labels";
import ReactMoment from "react-moment";
import NumberFormat from "react-number-format";
import renderHTML from "react-render-html";
import TicketOrderProduct from "./TicketOrderProduct";
import { graphqlUrl } from "../../../../../Config/Env";
import { dayOfWeekName } from "../../../../../Config/Util";

const SOrderDetail = styled(SOrderItem)`
  ${({ theme }) => {
    return css`
      .item_top {
      }
    `;
  }}
`;

function Product({ history, match, location, data }) {
  const {
    GiftAccountOrderDetail: {
      receiver,
      giftProduct,

      isDiscount,
      discountPrice,
      price,

      giftProductOptionDetail1,
      giftProductOptionDetailPrice1,
      giftProductOptionDetail2,
      giftProductOptionDetailPrice2,
      giftProductOptionDetailPrice3,
      giftProductOptionDetailPrice4,
      giftProductOptionDetailPrice5,

      count,
      createdAt,
    },
  } = data;

  let productPrice = isDiscount ? price - discountPrice : price;
  productPrice += giftProductOptionDetailPrice1;
  productPrice += giftProductOptionDetailPrice2;
  productPrice += giftProductOptionDetailPrice3;
  productPrice += giftProductOptionDetailPrice4;
  productPrice += giftProductOptionDetailPrice5;

  const Uri =
    (giftProduct.productType === "Product" && `/products/${giftProduct.id}`) ||
    (giftProduct.productType === "Ticket" && `/tickets/${giftProduct.id}`);

  const CoverImage =
    giftProduct.coverImage === null
      ? "../../assets/img/product/product-default.jpg"
      : `${graphqlUrl}${giftProduct.coverImage}`;

  const ticketDatetime = new Date(
    Date.parse(
      `${giftProductOptionDetail1.name} ${giftProductOptionDetail2.name}`
    )
  );
  const ticketDay = ticketDatetime.getDay();

  let refundAbleDate = ticketDatetime;
  refundAbleDate.setDate(refundAbleDate.getDate() - 1);
  console.log({ refundAbleDate });
  return (
    <SOrderDetail>
      <Row className="item_top">
        <Col left className="left">
          <span className="date">
            구매일자 :
            <ReactMoment format="YYYY. MM. DD HH:mm">{createdAt}</ReactMoment>
          </span>
        </Col>
      </Row>
      <div className="item_content">
        <div className="product_list">
          <Link to={Uri}>
            <div className="img">
              <img src={CoverImage} alt={giftProduct.name} />
            </div>
            <div className="cont">
              <span className="category">{giftProduct.store.name}</span>
              <strong>{giftProduct.name}</strong>
              <div className="option">
                {giftProduct.giftProductOptionsCount > 0 && (
                  <>
                    <Label>행사시간</Label>{" "}
                    <ReactMoment format="YYYY. MM. DD">
                      {ticketDatetime}
                    </ReactMoment>{" "}
                    ({dayOfWeekName(ticketDay)}){" "}
                    <ReactMoment format="HH:mm">{ticketDatetime}</ReactMoment>
                  </>
                )}
              </div>
            </div>
          </Link>
        </div>
      </div>
      <Row className="item_bottom">
        <Col left className="left">
          <span>
            To.
            {receiver[0].name}님
            {renderHTML(
              receiver.length > 1 ? `외 <b>${receiver.length - 1}</b>명` : ``
            )}
          </span>
        </Col>
        <Col right className="right">
          <span>
            <b>
              <NumberFormat
                value={productPrice}
                displayType={"text"}
                thousandSeparator={true}
              />
            </b>
            드림
          </span>
          <span>
            <b>{count}</b>개
          </span>
        </Col>
      </Row>
      <TicketOrderProduct
        data={data}
        ticketDatetime={ticketDatetime}
        refundAbleDate={refundAbleDate}
      />
    </SOrderDetail>
  );
}

export default withRouter(Product);
