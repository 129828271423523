import React from "react";
import styled, { css } from "styled-components";

const colorStyles = css`
  ${({ theme, color }) => {
    const selected = theme.colors[color];
    return css`
      background: ${selected};
    `;
  }}
`;
const borderStyles = css`
  ${({ theme }) => {
    return css`
      ${(props) =>
        props.border === "radius" &&
        css`
          border-radius: 5px;
        `}
    `;
  }}
`;
const StyledAlter = styled.div`
  /* 공통 스타일 */
  color: #777;
  line-height: 1;
  ${({ theme }) => {
    return css`
      padding: ${theme.paddings.base};
      font-size: ${theme.fonts.size.sm};
      line-height: 1.4;
      strong {
        display: block;
        font-weight: ${theme.fonts.weight.bold};
        font-size: 14px;
        margin-bottom: ${theme.margins.sm};
        color: #333;
        ::before {
          content: "\f05a";
          font-family: ${theme.fonts.family.fontAwesomeL};
          color: ${theme.colors.primary};
          padding-right: ${theme.paddings.sm};
          font-size: ${theme.fonts.size.base};
        }
      }
      p {
        font-size: 14px;
      }
    `;
  }}
  /* 색상 */
  ${colorStyles}
  /* 색상 */
  ${borderStyles}
`;

function Alter({ children, color, border, ...rest }) {
  return (
    <StyledAlter color={color} border={border} {...rest}>
      {children}
    </StyledAlter>
  );
}
Alter.defaultProps = {
  color: "lightgray",
  size: "medium",
};

export default Alter;
