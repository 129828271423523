import styled, { css } from "styled-components"
import { ProductInfo } from "../../../../../styles/styles"
import NumberFormat from "react-number-format"
import RowInfo from "../../../../RowInfo"

const SProductInfo = styled(ProductInfo)`
  ${({ theme }) => {
    return css`
      padding: 0;
      .flextext {
        margin: 0;
        ul {
          padding: 0;
          li {
          }
        }
      }
    `
  }}
`

function Refund({ data }) {
  const {
    GiftAccountOrderDetail: { totalPrice, refundTotalCount, refundTotalPrice },
  } = data
  return (
    <>
      <SProductInfo>
        <RowInfo
          textLeft={"총 결제금액"}
          textRight={
            <>
              <b>
                <NumberFormat
                  value={totalPrice}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </b>
              드림
            </>
          }
        />
        <RowInfo
          border={"bottom"}
          textLeft={"수량"}
          textRight={
            <>
              <b>{refundTotalCount}</b>개
            </>
          }
        />
        <RowInfo
          total={"total"}
          textLeft={"총 환불금액"}
          textRight={
            <>
              <b>
                <NumberFormat
                  value={refundTotalPrice}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </b>
              드림
            </>
          }
        />
      </SProductInfo>
    </>
  )
}

export default Refund
