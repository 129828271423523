import React, { useState } from "react"
import { withRouter, Link } from "react-router-dom"
import { useMutation } from "@apollo/client"
import {
  ACCOUNT_ORDERPRODUCT_QUERY,
  ACCOUNT_ORDERPRODUCTEXTENSION_MUTATION,
  ACCOUNT_ORDERPRODUCTREFUND_MUTATION,
} from "../../../../../Config/Queries"

import styled, { css } from "styled-components"
import {
  Comment,
  FooterButton,
  SOrderItem,
  SOrderInfo,
  Roundbox,
} from "../../../../../styles/styles"
import SLabel from "../../../../Labels"
import Alter from "../../../../Alter"
import Button from "../../../../Buttons"
import SendOrderProductModal from "../../../../OrderProduct/Account/OrderProduct/Detail/SendOrderProductModal"
import ReactMoment from "react-moment"
import { graphqlUrl, orderinfo } from "../../../../../Config/Env"
import { toast } from "react-toastify"
import RowInfo from "../../../../RowInfo"
import Labels from "../../../../Labels"

const SSOrderItem = styled(SOrderItem)`
  ${({ theme }) => {
    return css`
      padding: ${theme.paddings.xl} ${theme.paddings.xl} ${theme.paddings.base};
      box-shadow: none;
      border: 0;
      .product_list {
        border: 1px solid #eee;
        border-radius: 8px;
        .img {
          border-top-left-radius: 8px !important;
          border-bottom-left-radius: 8px !important;
          border-radius: 0px;
          width: 100px;
          height: 100px;
        }
      }
    `
  }}
`
const SSOrderInfo = styled(SOrderInfo)`
  ${({ theme }) => {
    return css`
      padding: 0 ${theme.paddings.xl} 0;
      p {
        margin-top: 5px;
        font-size: 13px;
        color: ${theme.colors.black01};
      }
    `
  }}
`

function Product({ history, match, location, isApp, data }) {
  const [orderProductExtensionMutation] = useMutation(
    ACCOUNT_ORDERPRODUCTEXTENSION_MUTATION,
    {
      refetchQueries: () => [
        {
          query: ACCOUNT_ORDERPRODUCT_QUERY,
          variables: {
            id,
          },
        },
      ],
    }
  )
  const onExtension = async () => {
    console.log("onExtension")
    if (!window.confirm("선물의 유효기간을 연장하시겠습니까?")) {
      return
    }

    try {
      const {
        data: { GiftAccountOrderProductExtension },
      } = await orderProductExtensionMutation({
        variables: { id },
      })

      if (GiftAccountOrderProductExtension) {
        //   history.replace(`/account/completeOrderProducts`);
      }
    } catch (e) {
      console.log(e)
    }
  }

  const [orderProductRefundMutation] = useMutation(
    ACCOUNT_ORDERPRODUCTREFUND_MUTATION,
    {
      refetchQueries: () => [
        {
          query: ACCOUNT_ORDERPRODUCT_QUERY,
          variables: {
            id,
          },
        },
      ],
    }
  )
  const onRefund = async () => {
    console.log("onRefund")
    if (!window.confirm("선물을 환불하시겠습니까?")) {
      return
    }

    try {
      const {
        data: { GiftAccountOrderProductRefund },
      } = await orderProductRefundMutation({
        variables: { id },
      })

      if (GiftAccountOrderProductRefund) {
        history.replace(`/account/completeOrderProducts`)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const [sendOrderProductModalIsOpen, setSendOrderProductModalIsOpen] =
    useState(false)
  const sendOrderProductToggleModal = () => {
    setSendOrderProductModalIsOpen(!sendOrderProductModalIsOpen)
  }

  const {
    GiftAccountOrderProductDetail: {
      id,
      commonStatus,
      user,
      giftOrder,
      giftProduct,
      giftProductOptionDetail1,
      giftProductOptionDetail2,
      giftProductOptionDetail3,
      giftProductOptionDetail4,
      giftProductOptionDetail5,

      startDate,
      endDate,
      extensionCount,

      isRefund,

      cashier,

      usedAt,
      createdAt,
    },
  } = data

  const ProductUri =
    (giftProduct.productType === "Product" && `/products/${giftProduct.id}`) ||
    (giftProduct.productType === "Ticket" && `/tickets/${giftProduct.id}`)

  const AvatarImage =
    giftOrder.user.avatar === null
      ? "/assets/img/user/user-default.jpg"
      : `${graphqlUrl}${giftOrder.user.avatar}`

  const CoverImage =
    giftProduct.coverImage === null
      ? "/assets/img/product/product-default.jpg"
      : `${graphqlUrl}${giftProduct.coverImage}`

  let OptionString = ""
  for (let i = 0; i < giftProduct.giftProductOptions.length; i++) {
    const option = giftProduct.giftProductOptions[i]
    for (let j = 0; j < option.giftProductOptionDetails.length; j++) {
      const detail = option.giftProductOptionDetails[j]
      if (
        eval(`giftProductOptionDetail${i + 1}`) &&
        eval(`giftProductOptionDetail${i + 1}.id`) === detail.id
      ) {
        OptionString += ` ${detail.name} ${
          giftProduct.giftProductOptions.length > i + 1 ? `/` : ``
        }`
      }
    }
  }

  const toDay = new Date()
  const endDateObj = new Date(endDate)
  let extensionAbleDate = new Date(endDate)
  extensionAbleDate.setDate(extensionAbleDate.getDate() - 31)

  // console.log({ toDay, endDate, endDateObj, extensionAbleDate });

  return (
    <>
      {giftOrder.receiver.find(
        (item) => item.id === user.id && giftOrder.message !== ""
      ) && (
        <Comment>
          <ul className="user_photo">
            <li>
              <Roundbox className="round_box">
                <div className="img">
                  <img src={AvatarImage} alt={giftOrder.user.name} />
                </div>
              </Roundbox>
            </li>
          </ul>
          <h4>
            <b>{giftOrder.user.name}님</b>의 선물
          </h4>
          <div className="massge_write">
            <div className="inner">{giftOrder.message}</div>
          </div>
        </Comment>
      )}

      <SSOrderItem>
        <div className="product_list">
          <Link to={ProductUri}>
            <div className="img">
              <img src={CoverImage} alt={giftProduct.name} />
            </div>
            <div className="cont">
              <span className="category">{giftProduct.store.name}</span>
              <strong>{giftProduct.name}</strong>
              <div className="option">
                {OptionString !== "" &&
                  giftProduct.giftProductOptionsCount > 0 && (
                    <>추가옵션 : {OptionString}</>
                  )}
              </div>
            </div>
          </Link>
        </div>
      </SSOrderItem>

      <SSOrderInfo>
        <RowInfo
          border={"bottom"}
          textLeft={<>유효기간(연장:{extensionCount}회)</>}
          textRight={
            <>
              <ReactMoment format="YYYY. MM. DD">{endDate}</ReactMoment>
            </>
          }
        />
        {commonStatus === "S" &&
          extensionCount < 2 &&
          toDay <= endDateObj &&
          toDay >= extensionAbleDate && (
            <RowInfo
              border={"bottom"}
              textLeft={"연장"}
              textRight={
                <>
                  <Button
                    onClick={onExtension}
                    color="warning"
                    border="solid"
                    size="xs"
                  >
                    연장하기
                  </Button>
                </>
              }
            />
          )}
        <RowInfo
          border={"bottom"}
          textLeft={"주문번호"}
          textRight={
            <>
              <ReactMoment format="YYMMDD-HHmmssSSS">{createdAt}</ReactMoment>
            </>
          }
        />
        <RowInfo
          border={"bottom"}
          textLeft={"교환처"}
          textRight={giftProduct.store.name}
        />
        <RowInfo
          border={"bottom"}
          textLeft={"선물수신일자"}
          textRight={
            <>
              <ReactMoment format="YYYY. MM. DD">{startDate}</ReactMoment>
            </>
          }
        />
        <RowInfo
          border={"bottom"}
          textLeft={"상태"}
          textRight={
            <>
              {commonStatus === "S" && (
                <>
                  {toDay <= endDateObj ? (
                    <Labels color="lightprimary" bg="none">
                      사용가능
                    </Labels>
                  ) : (
                    <Labels color="lightwarning" bg="none">
                      기간만료
                    </Labels>
                  )}
                </>
              )}
              {commonStatus === "C" && (
                <>
                  {isRefund === true && (
                    <Labels color="lightdanger" bg="none">
                      환불완료
                    </Labels>
                  )}
                  {isRefund === false && (
                    <Labels color="lightprimary" bg="none">
                      사용완료
                    </Labels>
                  )}
                </>
              )}
            </>
          }
        />
        {commonStatus === "C" && (
          <>
            {isRefund === false && (
              <RowInfo
                border={"bottom"}
                textLeft={"사용완료일자"}
                textRight={
                  <>
                    <ReactMoment format="YYYY. MM. DD">{usedAt}</ReactMoment>
                  </>
                }
              />
            )}
          </>
        )}
        {commonStatus === "S" &&
          extensionCount >= 1 &&
          extensionCount <= 2 &&
          toDay <= endDateObj && (
            <RowInfo
              border={"bottom"}
              textLeft={"환불"}
              textRight={
                <>
                  <Button
                    onClick={() => onRefund()}
                    color="danger"
                    border="solid"
                    size="xs"
                  >
                    환불하기
                  </Button>
                </>
              }
            />
          )}
        <p>*반드시 방문교환 해주세요. (배달불가)</p>

        {giftProduct.productType === "Product" && ""}

        <Alter border="radius" style={{ marginTop: 16 }}>
          <strong>환불 안내</strong>
          {orderinfo.Refund.smallcontent}
        </Alter>
      </SSOrderInfo>

      {commonStatus === "S" && extensionCount <= 2 && toDay <= endDateObj && (
        <>
          <FooterButton>
            <div className="inner">
              <Button
                color="lightprimary"
                onClick={() => {
                  if (isApp) {
                    const data = {
                      method: "payment",
                      data: { id },
                    }
                    window.ReactNativeWebView.postMessage(JSON.stringify(data))
                  } else {
                    toast.error("선물사용은 앱을 이용하여 사용할수 있습니다.")
                    return
                  }
                }}
                width="full"
              >
                사용하기
              </Button>
              <Button
                color="lightwarning"
                onClick={sendOrderProductToggleModal}
                width="full"
              >
                보내기
              </Button>
            </div>
          </FooterButton>
          <SendOrderProductModal
            isOpen={sendOrderProductModalIsOpen}
            toggleModal={sendOrderProductToggleModal}
          />
        </>
      )}

      {commonStatus === "C" && isRefund === false && (
        <>
          <FooterButton>
            <div className="inner">
              <Button
                color="lightprimary"
                onClick={() => {
                  if (isApp) {
                    const data = {
                      method: "addPayment",
                      data: { cashierId: cashier.id },
                    }
                    window.ReactNativeWebView.postMessage(JSON.stringify(data))
                  } else {
                    toast.error("추가결제 앱을 이용하여 사용할수 있습니다.")
                    return
                  }
                }}
                width="full"
              >
                추가결제
              </Button>
            </div>
          </FooterButton>
          <SendOrderProductModal
            isOpen={sendOrderProductModalIsOpen}
            toggleModal={sendOrderProductToggleModal}
          />
        </>
      )}
    </>
  )
}

export default withRouter(Product)
