import React from "react"
import { withRouter } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { ACCOUNT_ORDERPRODUCT_QUERY } from "../../../Config/Queries"

import { Layout } from "../../../Components/Layouts/Layout"
import PageTitle from "../../../Components/Layouts/PageTitle"
import styled from "styled-components"
import { Comment, PageLocation } from "../../../styles/styles"
import Product from "../../../Components/OrderProduct/Account/OrderProduct/Detail/Product"
import Ticket from "../../../Components/OrderProduct/Account/OrderProduct/Detail/Ticket"
import SLoading from "../../../Components/Loading"

const SComment = styled(Comment)`
  padding: 8px 10px;
  margin-bottom: 0;
  .pagelocation {
    margin-bottom: 0;
  }
`

function Detail({ history, match, location }) {
  const isApp = localStorage.getItem("isApp")
  const id = match.params.id

  const { data, loading } = useQuery(ACCOUNT_ORDERPRODUCT_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      id,
    },
  })

  if (!loading) {
    const {
      GiftAccountOrderProductDetail: { commonStatus, giftProduct, isRefund },
    } = data

    return (
      <Layout
        isNav={true}
        isFooter={true}
        isFooterButton={commonStatus === "C" && isRefund ? false : true}
      >
        <PageTitle title="받은 선물함" />

        {giftProduct.productType === "Product" && (
          <Product isApp={isApp} data={data} />
        )}

        {giftProduct.productType === "Ticket" && (
          <Ticket isApp={isApp} data={data} />
        )}
      </Layout>
    )
  } else {
    return <SLoading />
  }
}

export default withRouter(Detail)
