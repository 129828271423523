import React from "react"
import { withRouter } from "react-router-dom"

import styled, { css } from "styled-components"
import { SOrderItem, SOrderInfo, Dot } from "../../../styles/styles"
import Alter from "../../Alter"
import Label from "../../Labels"
import NumberFormat from "react-number-format"
import { graphqlUrl } from "../../../Config/Env"
import Labels from "../../Labels"

const SSOrderItem = styled(SOrderItem)`
  ${({ theme }) => {
    return css`
      padding: ${theme.paddings.base} ${theme.paddings.lg};
      box-shadow: none;
      border: 0;
      .product_list {
        padding: ${theme.paddings.base};
        border: 1px solid #eee;
        border-radius: 5px;
      }
    `
  }}
`
const SSOrderInfo = styled(SOrderInfo)`
  ${({ theme }) => {
    return css`
      padding: ${theme.paddings.base} ${theme.paddings.lg};
      .flextext {
        ul {
          padding: 0;
          li {
            :nth-child(2),
            :nth-child(4) {
              border-bottom: 0;
            }
            :last-child {
              border-bottom: 0;
            }
          }
        }
      }
    `
  }}
`

function Product({
  history,
  match,
  location,
  users,
  giftProductOptionDetail1,
  giftProductOptionDetail2,
  giftProductOptionDetail3,
  giftProductOptionDetail4,
  giftProductOptionDetail5,
  count,

  data,
}) {
  const {
    GiftOrderInit: { giftProduct, account },
  } = data

  const CoverImage =
    giftProduct.coverImage === null
      ? "/assets/img/product/product-default.jpg"
      : `${graphqlUrl}${giftProduct.coverImage}`

  let OptionString = ""
  let productPrice = giftProduct.isDiscount
    ? giftProduct.price - giftProduct.discountPrice
    : giftProduct.price
  for (let i = 0; i < giftProduct.giftProductOptions.length; i++) {
    const option = giftProduct.giftProductOptions[i]
    for (let j = 0; j < option.giftProductOptionDetails.length; j++) {
      const detail = option.giftProductOptionDetails[j]
      if (eval(`giftProductOptionDetail${i + 1}`) === detail.id) {
        OptionString += ` ${detail.name}${
          detail.price !== 0
            ? `(${detail.price > 0 ? "+" : ""}${detail.price.toLocaleString(
                "ko-KR",
                {
                  maximumFractionDigits: 4,
                }
              )}드림)`
            : ``
        } ${giftProduct.giftProductOptions.length > i + 1 ? `/` : ``}`
        productPrice += detail.price
      }
    }
  }
  const trustline = account.trustlines.find(
    (trustline) => trustline.currency === "DRM"
  )
  const totalAmount = account.isCms
    ? trustline.amount + account.cmsValue
    : trustline.amount
  const totalPrice =
    (giftProduct.isDiscount
      ? giftProduct.price - giftProduct.discountPrice
      : giftProduct.price) *
    count *
    users.length

  return (
    <>
      <SSOrderItem>
        <div className="title">선물내역</div>
        <div className="product_list">
          <div className="img">
            <img src={CoverImage} alt={giftProduct.name} />
          </div>
          <div className="cont">
            <span className="category">{giftProduct.store.name}</span>
            <strong>{giftProduct.name}</strong>
            <div className="option">
              {giftProduct.giftProductOptionsCount > 0 && (
                <>
                  <Label>추가옵션</Label> {OptionString}
                </>
              )}
            </div>
            <div className="info">
              <span>
                <b>
                  <NumberFormat
                    displayType={"text"}
                    className="price"
                    value={productPrice}
                    thousandSeparator={true}
                    suffix={"드림"}
                  />
                </b>
              </span>
              <Dot />
              <span>수량 : {count}개</span>
            </div>
          </div>
        </div>
      </SSOrderItem>
      <SSOrderInfo>
        <div className="flextext">
          <ul>
            <li>
              <div className="left">보유금액</div>
              <div className="right">
                <span className="primary">
                  {trustline.amount < 0 && (
                    <Labels
                      color="yellow"
                      border="radius"
                      style={{ marginRight: 5 }}
                    >
                      <b>땡겨쓴돈</b>
                    </Labels>
                  )}
                  <b>
                    <NumberFormat
                      value={Math.abs(trustline.amount)}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </b>
                  드림
                </span>
              </div>
            </li>
            <li>
              <div className="left">수량</div>
              <div className="right">
                <b>{count}</b>개
              </div>
            </li>
            <li>
              <div className="left">수신자</div>
              <div className="right">
                <b>{users.length}</b>명
              </div>
            </li>
            <li>
              <div className="left">결제금액</div>
              <div className="right">
                <b>
                  <NumberFormat
                    value={productPrice * count * users.length}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </b>
                드림
              </div>
            </li>
            <li>
              <div className="left">결제 후 잔액</div>
              <div className="right">
                {trustline.amount - productPrice * count * users.length < 0 && (
                  <Labels
                    color="yellow"
                    border="radius"
                    style={{ marginRight: 5 }}
                  >
                    <b>땡겨쓴돈</b>
                  </Labels>
                )}
                <b>
                  <NumberFormat
                    value={Math.abs(
                      trustline.amount - productPrice * count * users.length
                    )}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </b>
                드림
              </div>
            </li>
          </ul>
          <Alter color="lightgray" border="radius">
            <strong>땡겨쓴돈 안내</strong>
            <p>
              ① 정기충전회원분들은 매월 약정하신 금액에서 땡겨쓰기가 가능합니다.
            </p>
            <p>② 차월 정기충전일시에 맞추어 차감되어 충전됩니다.</p>
            <p>③ 정기충전이 아닌 분들은 땡겨쓰기 기능 사용이 불가능합니다.</p>
            <p>
              ex) 10만원 CMS 정기충전자가 9만드림을 '땡겨쓰기'를 했을 경우, 다음
              달 출금에서 9만드림 차감 후, 1만 드림만 충전
            </p>
          </Alter>
        </div>
      </SSOrderInfo>
    </>
  )
}

export default withRouter(Product)
