import React, { useState } from "react";
import { withRouter } from "react-router-dom";

import { Layout } from "../../../Components/Layouts/Layout";
import PageTitle from "../../../Components/Layouts/PageTitle";
import {
    Row,
    Col,
    SwiperSlideImg,
    ProductDetail,
    ProductTab,
    SAccordion,
    FooterButton,
} from "../../../styles/styles";
import styled, { css } from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import Alter from "../../../Components/Alter";
import IsWishlist from "../../../Components/Wishlist/Product/Common/Detail/IsWishlist";
import Intro from "../../../Components/Product/Common/Intro";
import Review from "../../../Components/Review/Product/Common/Detail/Review";
import Inquiry from "../../../Components/Inquiry/Product/Common/Detail/Inquiry";
import OrderOptionSelectModal from "../../../Components/Product/Ticket/Detail/OrderOptionSelectModal";
import OrderUserSearchModal from "../../../Components/Product/Ticket/Detail/OrderUserSearchModal";
import Button from "../../../Components/Buttons";
import Tabs, { TabPane } from "rc-tabs";
import { StickyContainer, Sticky } from "react-sticky-17";
import NumberFormat from "react-number-format";
import { graphqlUrl, orderinfo } from "../../../Config/Env";
import { toast } from "react-toastify";
import moment from "moment";

import { useReactiveVar } from "@apollo/client";
import { isLoggedInVar } from "../../../apollo";
const SProductDetail = styled(ProductDetail)`
    ${({ theme }) => {
        return css`
            .detail_top {
                padding: ${theme.margins.base};
                margin-bottom: ${theme.margins.base};
                flex: 0 0 50%;
                .category {
                    color: ${theme.colors.primary};
                    font-size: ${theme.fonts.size.sm};
                    font-weight: 400;
                }
                h3 {
                    margin-top: 5px;
                    overflow: hidden;

                    font-size: ${theme.fonts.size.base};
                    line-height: 1.3;
                }
                .left {
                    flex-direction: column;
                }
                .right {
                    flex: 0;
                    display: inline-flex;
                    .like {
                        background-color: white;
                        border: solid 1px #ddd;
                        width: 40px;
                        height: 40px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0;
                        ::before {
                            content: "\f004";
                            font-family: ${theme.fonts.family.fontAwesomeL};
                            display: inline-block;
                            color: ${theme.colors.gray};
                            transition: color 0.15s ease-in-out;
                        }
                        &:hover,
                        &:focus,
                        &:active {
                            border: solid 2px ${theme.colors.danger};
                            ::before {
                                font-family: ${theme.fonts.family.fontAwesomeS};
                                color: ${theme.colors.danger};
                            }
                        }
                    }
                    .active {
                        border: solid 2px ${theme.colors.danger};
                        ::before {
                            font-family: ${theme.fonts.family.fontAwesomeS};
                            color: ${theme.colors.danger};
                        }
                    }
                }
            }
            .product_list {
                display: flex;
                margin-top: ${theme.margins.base};
                flex-direction: row;
                .cont {
                    display: flex;
                    margin-top: 0;
                    margin-left: 0;
                    flex-direction: column;
                    justify-content: center;
                    ul {
                        li {
                            margin-bottom: ${theme.margins.sm};
                            font-size: 14px;
                            color: ${theme.colors.default};
                            b {
                                font-weight: ${theme.fonts.weight.medium};
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
            .price_cont {
                margin: ${theme.margins.base} 0 ${theme.margins.lg};
                .discount {
                    color: ${theme.colors.danger};
                    font-size: ${theme.fonts.size.sm};
                    span {
                        margin-left: ${theme.margins.xs};
                        color: ${theme.colors.gray};
                        text-decoration: line-through;
                    }
                }
                .price {
                    margin: ${theme.margins.sm} 0 0;
                    font-size: ${theme.fonts.size.lg};
                    color: #333;
                }
            }
        `;
    }}
`;

const renderTabBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={80}>
        {({ style }) => (
            <ProductTab>
                <DefaultTabBar {...props} style={{ ...style }} />
            </ProductTab>
        )}
    </Sticky>
);

function Presenter({ history, match, location, data }) {
    const isLoggedIn = useReactiveVar(isLoggedInVar);

    const [orderOptionSelectModalIsOpen, setOrderOptionSelectModalIsOpen] =
        useState(false);
    const orderOptionSelectToggleModal = () => {
        if (!isLoggedIn) {
            toast.error("로그인 후 이용해주세요.");
            history.push(`/login`);
            return;
        }
        if (isSoldOut) {
            toast.error("상품이 품절되어 구입할 수 없습니다.");
            return;
        }
        setOrderOptionSelectModalIsOpen(!orderOptionSelectModalIsOpen);
    };

    const [orderUserSearchModalIsOpen, setOrderUserSearchModalIsOpen] =
        useState(false);
    const orderUserSearcToggleModal = () => {
        if (!isLoggedIn) {
            toast.error("로그인 후 이용해주세요.");
            history.push(`/login`);
            return;
        }
        if (isSoldOut) {
            toast.error("상품이 품절되어 선물할 수 없습니다.");
            return;
        }
        setOrderUserSearchModalIsOpen(!orderUserSearchModalIsOpen);
    };

    const {
        GiftProductDetail: {
            id,
            giftCategory,
            productType,
            coverImage,
            isSoldOut,
            isDiscount,
            discountPrice,
            price,
            name,
            contents,

            limit,
            giftOrderProductCount,
            schedule,
            place,

            giftProductOptionsCount,
            giftProductOptions,
            giftProductReviewsCount,
            giftProductReviews,
            giftProductInquiriesCount,
            giftProductInquiries,
        },
    } = data;

    const CoverImage =
        coverImage === null
            ? "../assets/img/product/product-default.jpg"
            : `${graphqlUrl}${coverImage}`;
    const Percent = 100 - (price - discountPrice) / 100;

    const [isDetail, setIsDetail] = useState(false);
    const toggleDetail = () => {
        setIsDetail(!isDetail);
    };

    const ticketDateObject = giftProductOptions[0];
    const ticketTimeObject = giftProductOptions[1];
    const ticketDatetime = new Date(
        Date.parse(
            `${ticketDateObject.giftProductOptionDetails[0].name} ${ticketTimeObject.giftProductOptionDetails[0].name}`
        )
    );

    return (
        <Layout isNav={false} isFooter={true} isFooterButton={true}>
            <PageTitle title={name} />
            <SProductDetail>
                <div className="swiper-banner">
                    <Swiper
                        pagination={{
                            type: "fraction",
                        }}
                        navigation={false}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <SwiperSlideImg>
                                <img src={CoverImage} alt={name} />
                            </SwiperSlideImg>
                        </SwiperSlide>
                    </Swiper>
                    {isSoldOut && (
                        <div className="complete_icon">
                            <div>매진</div>
                        </div>
                    )}
                </div>
                <div className="detail_top">
                    <Row>
                        <Col className="left">
                            <span className="category">
                                {giftCategory.parentCategory &&
                                    `${giftCategory.parentCategory.name} > `}
                                {giftCategory.name}
                            </span>
                            <h3>{name}</h3>
                        </Col>
                        <Col className="right">
                            <IsWishlist isLoggedIn={isLoggedIn} />
                        </Col>
                    </Row>
                    <div className="product_list">
                        <div className="cont">
                            {productType === "Ticket" && (
                                <>
                                    <ul>
                                        <li>
                                            <b>정원 :</b>{" "}
                                            {giftOrderProductCount}명/{limit}명
                                        </li>
                                        <li>
                                            <b>일정 :</b>{" "}
                                            {schedule ? schedule : `-`}
                                        </li>
                                        <li>
                                            <b>장소 :</b> {place ? place : `-`}
                                        </li>
                                    </ul>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="price_cont">
                        {isDiscount ? (
                            <>
                                <div className="discount">
                                    {Percent}%
                                    <span>
                                        <b>
                                            <NumberFormat
                                                value={price}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                            />
                                        </b>
                                        드림
                                    </span>
                                </div>
                                <div className="price">
                                    <b>
                                        <NumberFormat
                                            value={price - discountPrice}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                        />
                                    </b>
                                    드림
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="price">
                                    <b>
                                        <NumberFormat
                                            value={price}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                        />
                                    </b>
                                    드림
                                </div>
                            </>
                        )}
                    </div>

                    <Alter color="lightgray" border="radius">
                        <strong>이용안내</strong>
                        <p>
                            *상품배송은 불가하며 가게에서 직접수령만 가능합니다.
                        </p>
                    </Alter>
                </div>
            </SProductDetail>
            <StickyContainer>
                <Tabs defaultActiveKey="1" renderTabBar={renderTabBar}>
                    <TabPane tab={<span>상세정보</span>} key="1">
                        <Intro contents={contents} />
                        <SAccordion>
                            <div className="saccordion_continer">
                                <button onClick={toggleDetail}>
                                    {orderinfo.Refund.title}
                                    <span
                                        className={`${
                                            !isDetail ? `active` : ``
                                        }`}
                                    ></span>
                                </button>
                                <div
                                    className={`saccordion_text ${
                                        !isDetail ? `hide` : ``
                                    }`}
                                >
                                    {orderinfo.TicketRefund.content}
                                </div>
                            </div>
                        </SAccordion>
                    </TabPane>
                    <TabPane
                        tab={<span>선물후기 ({giftProductReviewsCount})</span>}
                        key="2"
                    >
                        <Review giftProductReviews={giftProductReviews} />
                    </TabPane>
                    <TabPane
                        tab={
                            <span>선물문의 ({giftProductInquiriesCount})</span>
                        }
                        key="3"
                    >
                        <Inquiry
                            giftProductInquiries={giftProductInquiries}
                            uri="/tickets"
                        />
                    </TabPane>
                </Tabs>
            </StickyContainer>

            {moment().isBefore(moment(ticketDatetime)) ? (
                <>
                    {!isSoldOut || limit - giftOrderProductCount < 0 ? (
                        <>
                            <FooterButton>
                                <div className="inner">
                                    <Button
                                        onClick={orderOptionSelectToggleModal}
                                        color="lightwarning"
                                        width="full"
                                    >
                                        나에게 선물하기
                                    </Button>
                                    <Button
                                        onClick={orderUserSearcToggleModal}
                                        color="lightprimary"
                                        width="full"
                                    >
                                        선물하기
                                    </Button>
                                </div>
                            </FooterButton>
                            <OrderOptionSelectModal
                                isOpen={orderOptionSelectModalIsOpen}
                                toggleModal={orderOptionSelectToggleModal}
                                id={id}
                                name={name}
                                isDiscount={isDiscount}
                                discountPrice={discountPrice}
                                price={
                                    isDiscount ? price - discountPrice : price
                                }
                                limit={limit}
                                giftOrderProductCount={giftOrderProductCount}
                                giftProductOptionsCount={
                                    giftProductOptionsCount
                                }
                                giftProductOptions={giftProductOptions}
                            />
                            <OrderUserSearchModal
                                isOpen={orderUserSearchModalIsOpen}
                                toggleModal={orderUserSearcToggleModal}
                                id={id}
                                name={name}
                                isDiscount={isDiscount}
                                discountPrice={discountPrice}
                                price={
                                    isDiscount ? price - discountPrice : price
                                }
                                limit={limit}
                                giftOrderProductCount={giftOrderProductCount}
                                giftProductOptionsCount={
                                    giftProductOptionsCount
                                }
                                giftProductOptions={giftProductOptions}
                            />
                        </>
                    ) : (
                        <FooterButton>
                            <div className="inner">
                                <Button color="lightdanger" width="full">
                                    매진
                                </Button>
                            </div>
                        </FooterButton>
                    )}
                </>
            ) : (
                <FooterButton>
                    <div className="inner">
                        <Button color="lightdanger" width="full">
                            일정종료
                        </Button>
                    </div>
                </FooterButton>
            )}
        </Layout>
    );
}

export default withRouter(Presenter);
