import React, { useState } from "react"
import { withRouter } from "react-router-dom"

import styled, { css } from "styled-components"
import { Col, Dot } from "../../../../../styles/styles"
import Link from "../../../../Links"
import Button from "../../../../Buttons"
import ReactMoment from "react-moment"
import { graphqlUrl } from "../../../../../Config/Env"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLockAlt, faGift } from "@fortawesome/pro-light-svg-icons"
import {
  faCommentAlt,
  faCommentAltCheck,
} from "@fortawesome/pro-regular-svg-icons"
import nl2br from "react-nl2br"
import Links from "../../../../Links"

const SItem = styled.div`
  ${({ theme }) => {
    return css`
      .inquiry_header {
        display: flex;
        flex-direction: row;
        dl {
          display: flex;
          flex: 1;
          flex-direction: column;
          dt {
            span {
              display: inline-block;
              border-radius: 3px;
              color: ${theme.colors.black04};
              font-size: 1rem;
            }
          }
          dd {
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;
            font-size: ${theme.fonts.size.sm};
            color: #666;
            span {
              color: #ddd;
              margin-right: ${theme.margins.sm};
              color: ${theme.colors.black04};
              &.active {
                color: ${theme.colors.primary};
              }
            }
          }
        }
        .arrow {
          ::before {
            content: "\f106";
            font-family: ${theme.fonts.family.fontAwesomeL};
            font-size: ${theme.fonts.size.lg};
            color: ${theme.colors.black01};
          }
          &.open {
            ::before {
              content: "\f107";
              color: #bbb;
            }
          }
        }
        .text {
          margin: ${theme.margins.base} 0;
          font-size: ${theme.fonts.size.sm};
          color: ${theme.colors.black04};
        }
      }
      .inquiry_body {
        background-color: #fbfbfb;
        border-left: solid 2px ${theme.colors.primary};
        margin-top: ${theme.margins.sm};
        padding: ${theme.paddings.base};
        .text {
          margin: ${theme.margins.base} 0;
          font-size: ${theme.fonts.size.sm};
          color: ${theme.colors.black04};
        }
      }
      .hide {
        display: none;
      }
    `
  }}
`

function Item({ history, match, location, item, uri, onDelete }) {
  const [isDetail, setIsDetail] = useState(false)
  const toggleView = () => {
    setIsDetail(!isDetail)
  }
  const avatarImage =
    item.user.avatar === "" || item.user.avatar === null
      ? "/assets/img/user/user-default.jpg"
      : `${graphqlUrl}${item.user.avatar}`

  return (
    <SItem>
      <div onClick={toggleView}>
        <div className="inquiry_header">
          <dl>
            <dt>
              {!item.isSecret || (item.isSecret && item.isMe) ? (
                <div className="text">{nl2br(item.question)}</div>
              ) : (
                <div className="text">
                  {/* <FontAwesomeIcon icon={faGift} />
                  &nbsp;선물문의 입니다. */}
                  <FontAwesomeIcon icon={faLockAlt} />
                  &nbsp;비밀문의 입니다.
                </div>
              )}
            </dt>
            <dd>
              <Col left>
                {item.commonStatus === "S" && <span>답변대기</span>}
                {item.commonStatus === "C" && (
                  <span className="active">답변완료</span>
                )}
                <Dot />
                {item.user.name}
                <Dot />
                <ReactMoment format="YYYY. MM. DD">
                  {item.createdAt}
                </ReactMoment>
              </Col>
              {item.commonStatus === "C" &&
                (!item.isSecret || (item.isSecret && item.isMe)) && (
                  <span className={`arrow ${!isDetail && `open`}`}></span>
                )}
            </dd>
          </dl>
        </div>
        {(!item.isSecret || (item.isSecret && item.isMe)) && (
          <div className={`inquiry_body ${!isDetail && `hide`}`}>
            {item.commonStatus === "C" && (
              <div className="text">{nl2br(item.answer)}</div>
            )}
            {item.isMe && (
              <Col right>
                <Links
                  to={`/products/${item.giftProduct.id}/inquiries/${item.id}/edit?redirect=${uri}`}
                  color="white"
                  size="xs"
                >
                  수정
                </Links>
                <Button
                  style={{ marginLeft: 5 }}
                  type="button"
                  color="white"
                  size="xs"
                  onClick={() => onDelete(item.id)}
                >
                  삭제
                </Button>
              </Col>
            )}
          </div>
        )}
      </div>
    </SItem>
  )
}
export default withRouter(Item)
