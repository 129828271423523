import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { STORES_QUERY } from "../../../Config/Queries";

import Presenter from "./Presenter";
import qs from "qs";

function Container({ history, match, location }) {
  const orderByOption = [
    {
      value: "giftedAt_DESC",
      label: "최신순",
    },
    {
      value: "name_ASC",
      label: "가나다순",
    },
  ];

  const queryString = qs.parse(location.search.substr(1));
  const storeCategory = queryString.storeCategory
    ? queryString.storeCategory
    : null;
  const orderBy = queryString.orderBy
    ? orderByOption.find((find) => find.value === queryString.orderBy)
      ? queryString.orderBy
      : "giftedAt_DESC"
    : "giftedAt_DESC";
  const page = queryString.page ? queryString.page : 1;
  const blockSize = 5;
  const first = 16;
  const skip = first * (page - 1);

  const baseUrl =
    "?" +
    (storeCategory ? "storeCategory=" + storeCategory + "&" : "") +
    (orderBy ? "orderBy=" + orderBy + "&" : "");

  const { data, loading } = useQuery(STORES_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      storeCategory: storeCategory,
      orderBy,
      skip,
      first,
    },
  });
  const [orderByDefault, setOrderByDefault] = useState({
    value: "giftedAt_DESC",
    label: "최신순",
  });
  const orderByChange = (item) => {
    setOrderByDefault({
      value: item.value,
      label: item.label,
    });

    const url =
      "?" +
      (storeCategory ? "storeCategory=" + storeCategory + "&" : "") +
      (item.value ? "orderBy=" + item.value + "&" : "");
    history.push(`/stores${url}`);
  };

  useEffect(() => {
    const orderByValue = orderByOption.find((find) => find.value === orderBy);
    if (orderByValue) {
      setOrderByDefault(orderByValue);
    } else {
      setOrderByDefault({
        value: "giftedAt_DESC",
        label: "최신순",
      });
    }
  }, [orderBy]);

  return (
    <Presenter
      loading={loading}
      data={data}
      page={page}
      orderByOption={orderByOption}
      orderByDefault={orderByDefault}
      orderByChange={orderByChange}
      blockSize={blockSize}
      first={first}
      baseUrl={baseUrl}
    />
  );
}

export default withRouter(Container);
