import React, { useState } from "react";
import styled, { css } from "styled-components";
import Button from "../../Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-light-svg-icons";
import renderHTML from "react-render-html";
import "../../../styles/tiny_mce.css";

const InfoProductContent = styled.div`
  ${({ theme }) => {
    return css`
      margin-bottom: ${theme.margins.base};
      img {
        max-width: 100%;
        height: auto;
        object-fit: cover;
      }
      padding: ${theme.paddings.base};
      .intropage {
        min-height: 300px;
      }
      .open {
        height: auto;
        overflow: auto;
      }
      .close {
        height: 300px;
        overflow: hidden;
      }
    `;
  }}
`;
function Intro({ contents }) {
  const [isFull, setIsFull] = useState(false);
  const toggleView = () => {
    setIsFull(!isFull);
  };

  return (
    <>
      <InfoProductContent>
        <div
          className={isFull === true ? "intropage open" : " intropage close"}
        >
          <div className="mce-content">{renderHTML(contents)}</div>
        </div>
        <Button
          color="white"
          border="radius"
          width="full"
          onClick={() => toggleView()}
        >
          {isFull ? (
            <>
              상품정보 접기 &nbsp;
              <FontAwesomeIcon icon={faAngleUp} />
            </>
          ) : (
            <>
              상품정보 펼치기 &nbsp;
              <FontAwesomeIcon icon={faAngleDown} />
            </>
          )}
        </Button>
      </InfoProductContent>
    </>
  );
}
export default Intro;
