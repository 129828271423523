import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { PRODUCTS_QUERY } from "../../../../Config/Queries";

import { Ready } from "../../../../styles/styles";
import styled, { css } from "styled-components";
import Item from "./Item";

const SItemlist = styled.div`
    ${({ theme }) => {
        return css`
            flex-wrap: wrap;
            border-bottom: 0;
            ul {
                width: 100%;
                li {
                    flex: 1 0 100%;
                    a {
                        display: block;
                    }
                }
            }
        `;
    }}
`;

function Product({ giftProducts }) {
    const [items, setItems] = useState(giftProducts);
    const [page, setPage] = useState(2);
    const first = 6;
    const skip = first * (page - 1);
    const [loadmore, setLoadmore] = useState(false);

    const { data, loading, refetch } = useQuery(PRODUCTS_QUERY, {
        variables: {
            productType: "Product",
            orderBy: "id_DESC",
            skip: skip,
            first: first,
        },
    });

    const handleLoadMore = () => {
        setLoadmore(true);
        setPage(page + 1);
        refetch();
        if (!loading && data?.GiftProductList?.giftProducts) {
            setItems(items.concat(data?.GiftProductList?.giftProducts));
        }
        setLoadmore(false);
    };

    return (
        <>
            <SItemlist>
                {items ? (
                    <ul>
                        {items &&
                            items.map((item, index) => {
                                return (
                                    <li key={index}>
                                        <Link to={`/products/${item.id}`}>
                                            <Item
                                                rank={index + 1}
                                                item={item}
                                            />
                                        </Link>
                                    </li>
                                );
                            })}
                    </ul>
                ) : (
                    <Ready>
                        <div className="list_none">등록된 상품이 없습니다.</div>
                    </Ready>
                )}
            </SItemlist>
            {/* {!loadmore && !loading && data.GiftProductList.giftProducts.length > 0 && (
        <Button
          color="white"
          border="radius"
          width="full"
          onClick={() => handleLoadMore()}
        >
          더 보기 &nbsp;
          <FontAwesomeIcon className="icon" icon={faAngleDown} />
        </Button>
      )} */}
        </>
    );
}

export default Product;
