import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { STORES_CATEGORIES_QUERY } from "../../../Config/Queries";

import styled, { css } from "styled-components";
import qs from "qs";

const SCategory = styled.div`
    ${({ theme }) => {
        return css`
            background-color: ${theme.colors.lightgray};
            padding: 15px 24px;
            ul {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                li {
                    margin: 0 10px 10px 0;
                    a {
                        display: block;
                        background-color: white;
                        border: solid 1px #eee;
                        overflow: hidden;
                        border-radius: 20px;
                        justify-content: center;
                        text-align: center;
                        line-height: 34px;
                        padding: 0 12px;
                        font-size: 14px;
                        color: ${theme.colors.black02};
                        font-weight: ${theme.fonts.weight.medium};
                        &.active {
                            color: ${theme.colors.primary};
                            border: solid 1px ${theme.colors.primary};
                            font-weight: ${theme.fonts.weight.bold};
                        }
                    }
                }
            }
        `;
    }}
`;

function Category({ history, match, location }) {
    const queryString = qs.parse(location.search.substr(1));
    const storeCategory = queryString.storeCategory
        ? queryString.storeCategory
        : null;

    const { data, loading } = useQuery(STORES_CATEGORIES_QUERY, {
        fetchPolicy: "network-only",
    });

    if (!loading) {
        return (
            <>
                {data.GiftStoreCategoryList.count > 0 && (
                    <SCategory>
                        <ul>
                            <li>
                                <a
                                    href={`/stores`}
                                    className={
                                        null === storeCategory ? "active" : ""
                                    }
                                >
                                    전체
                                </a>
                            </li>
                            {data.GiftStoreCategoryList.storeCategories.map(
                                (item, index) => {
                                    return (
                                        <li key={index}>
                                            <a
                                                href={`/stores?storeCategory=${item.id}`}
                                                className={
                                                    item.id === storeCategory
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                {item.name}
                                            </a>
                                        </li>
                                    );
                                }
                            )}
                        </ul>
                    </SCategory>
                )}
            </>
        );
    } else {
        return <div></div>;
    }
}
export default withRouter(Category);
