import React from "react";
import { withRouter, Link } from "react-router-dom";

import styled, { css } from "styled-components";
import NumberFormat from "react-number-format";
import { graphqlUrl } from "../../../../Config/Env";

const SRowitem = styled.div`
  ${({ theme }) => {
    return css`
      padding: ${theme.paddings.base};
      border-bottom: solid 1px #eee;
      a {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .img {
        position: relative;
        flex: 0 0 auto;
        background-color: ${theme.colors.lightgray};
        width: 96px;
        height: 160px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          max-width: 100%;
          height: auto;
        }
        :before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          border: 1px solid rgba(0, 0, 0, 0.05);
          border-radius: 5px;
        }
      }
      .complete_icon {
        position: absolute;
        background-color: rgba(255, 255, 255, 0.8);
        border: 1px solid rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 99;
        display: flex;
        justify-content: center;
        align-items: center;
        div {
          position: relative;
          transform: rotate(-30deg);
          width: 64px;
          height: 64px;
          border: solid 2px #555;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          color: #777;
          ::before {
            content: "";
            position: absolute;
            width: 54px;
            height: 54px;
            z-index: 99;
            border-radius: 50%;
            border: solid 1px #bbb;
          }
        }
      }
      .cont {
        display: flex;
        flex-direction: column;
        margin-left: ${theme.margins.base};
        .category {
          margin-top: 0;
          color: ${theme.colors.gray};
          font-size: ${theme.fonts.size.sm};
          font-weight: 400;
        }
        h3 {
          overflow: hidden;
          margin-top: ${theme.margins.sm};
          font-size: ${theme.fonts.size.base};
          color: ${theme.colors.default};
          font-weight: ${theme.fonts.weight.medium};
        }
        .info {
          margin-top: ${theme.margins.lg};
          font-size: ${theme.fonts.size.sm};
          span {
            display: block;
            position: relative;
            margin-left: 0;
            color: #777;
          }
        }
        .discount {
          margin-top: ${theme.margins.base};
          color: ${theme.colors.danger};
          font-size: ${theme.fonts.size.sm};
          span {
            color: ${theme.colors.gray};
            text-decoration: line-through;
          }
        }
        .price {
          margin-top: ${theme.margins.xs};
          font-size: ${theme.fonts.size.base};
          color: #333;
        }
      }
    `;
  }}
`;

function Item({ history, match, location, item }) {
  const CoverImage =
    item.coverImage === null
      ? "/assets/img/product/product-default.jpg"
      : `${graphqlUrl}${item.coverImage}`;
  const Percent = 100 - (item.price - item.discountPrice) / 100;
  return (
    <SRowitem>
      <Link to={`/tickets/${item.id}`}>
        <div className="img">
          <img src={CoverImage} alt={item.name} />
          {(item.isSoldOut || item.giftOrderProductCount >= item.limit) && (
            <div className="complete_icon">
              <div>
                <span>매진</span>
              </div>
            </div>
          )}
        </div>
        <div className="cont">
          <span className="category">{item.giftCategory.name}</span>
          <h3>{item.name}</h3>
          <div className="info">
            <span>
              <b>{item.giftOrderProductCount}</b>명/{item.limit}명
            </span>
            <span>{item.schedule}</span>
            <span>{item.place}</span>
          </div>
          {item.isDiscount ? (
            <>
              <div className="discount">
                {Percent}%{" "}
                <span>
                  <NumberFormat
                    value={item.price}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                  드림
                </span>
              </div>
              <div className="price">
                <b>
                  <NumberFormat
                    value={item.price - item.discountPrice}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </b>
                드림
              </div>
            </>
          ) : (
            <>
              <div className="price">
                <b>
                  <NumberFormat
                    value={item.price}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </b>
                드림
              </div>
            </>
          )}
        </div>
      </Link>
    </SRowitem>
  );
}

export default withRouter(Item);
