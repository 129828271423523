import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { PRODUCT_QUERY } from "../../../Config/Queries";

import Presenter from "./Presenter";
import Lodings from "../../../Components/Loading";

function Container({ history, match, location }) {
  const id = match.params.id;

  const { data, loading } = useQuery(PRODUCT_QUERY, {
    variables: {
      productType: "Ticket",
      id,
    },
  });

  if (!loading) {
    return <Presenter data={data} />;
  } else {
    return (
      <>
        <Lodings desc="로딩..." />
      </>
    );
  }
}
export default withRouter(Container);
