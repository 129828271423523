import React from "react";
import { withRouter } from "react-router-dom";

import styled, { css } from "styled-components";
import { Row, Col, SOrderItem, SOrderInfo } from "../../../styles/styles";
import SAlter from "../../Alter";
import Label from "../../Labels";
import { graphqlUrl } from "../../../Config/Env";
import { dayOfWeekName } from "../../../Config/Util";
import NumberFormat from "react-number-format";
import ReactMoment from "react-moment";

const SSOrderItem = styled(SOrderItem)`
  ${({ theme }) => {
    return css`
      padding: ${theme.paddings.base};
      box-shadow: none;
      .product_list {
        padding: ${theme.paddings.base};
        border: 1px solid #eee;
        border-radius: 5px;
        .data {
          font-size: 12px;
        }
        ::after {
          display: none;
        }
      }
    `;
  }}
`;
const SSOrderInfo = styled(SOrderInfo)`
  ${({ theme }) => {
    return css`
      padding-top: 0;
      .flextext {
        border-bottom: solid 1px #ddd;
        ul {
          padding: 0;
          li {
            :nth-child(1) {
              border-bottom: 0;
            }
            :last-child {
              border-bottom: 0;
            }
          }
        }
      }
    `;
  }}
`;

function Ticket({
  history,
  match,
  location,
  users,
  giftProductOptionDetail1,
  giftProductOptionDetail2,
  giftProductOptionDetail3,
  giftProductOptionDetail4,
  giftProductOptionDetail5,
  count,

  data,
}) {
  const {
    GiftOrderInit: { giftProduct },
  } = data;

  const CoverImage =
    giftProduct.coverImage === "" || giftProduct.coverImage === null
      ? "/assets/img/product/product-default.jpg"
      : `${graphqlUrl}${giftProduct.coverImage}`;

  let productPrice = giftProduct.isDiscount
    ? giftProduct.price - giftProduct.discountPrice
    : giftProduct.price;
  for (let i = 0; i < giftProduct.giftProductOptions.length; i++) {
    const option = giftProduct.giftProductOptions[i];
    for (let j = 0; j < option.giftProductOptionDetails.length; j++) {
      const detail = option.giftProductOptionDetails[j];
      if (eval(`giftProductOptionDetail${i + 1}`) === detail.id) {
        productPrice += detail.price;
      }
    }
  }

  const ticketDatetime = new Date(
    Date.parse(
      `${giftProduct.giftProductOptions[0].giftProductOptionDetails[0].name} ${giftProduct.giftProductOptions[1].giftProductOptionDetails[0].name}`
    )
  );
  const ticketDay = ticketDatetime.getDay();

  return (
    <>
      <SSOrderItem>
        <div className="title">선물내역</div>
        <div className="product_list">
          <div className="img">
            <img src={CoverImage} alt={giftProduct.name} />
          </div>
          <div className="cont">
            <Row>
              <Col>
                <span className="category">{giftProduct.store.name}</span>
              </Col>
              <Col right>
                <span className="data">
                  <ReactMoment format="YYYY. MM. DD"></ReactMoment>
                </span>
              </Col>
            </Row>
            <strong>{giftProduct.name}</strong>
            <div className="option">
              {giftProduct.giftProductOptionsCount > 0 && (
                <>
                  <Label>행사시간</Label>{" "}
                  <ReactMoment format="YYYY. MM. DD">
                    {ticketDatetime}
                  </ReactMoment>{" "}
                  ({dayOfWeekName(ticketDay)}){" "}
                  <ReactMoment format="HH:mm">{ticketDatetime}</ReactMoment>
                </>
              )}
            </div>
            <div className="info">
              <span>
                <b>
                  <NumberFormat
                    displayType={"text"}
                    className="price"
                    value={productPrice}
                    thousandSeparator={true}
                    suffix={"드림"}
                  />
                </b>
              </span>
              <span>
                <b>{count}</b>개
              </span>
            </div>
          </div>
        </div>
      </SSOrderItem>
      <SSOrderInfo>
        <div className="flextext">
          <ul>
            <li>
              <div className="left">수량</div>
              <div className="right">
                <b>{count}</b>개
              </div>
            </li>
            <li>
              <div className="left">선물 받는 인원</div>
              <div className="right">
                <b>{users.length}</b>명
              </div>
            </li>
          </ul>
          <ul className="total">
            <li>
              <div className="left">결제금액</div>
              <div className="right">
                <b>
                  <NumberFormat
                    value={productPrice * count * users.length}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </b>
                드림
              </div>
            </li>
          </ul>
        </div>
        <SAlter color="lightgray" border="radius">
          <strong>선물구입 안내</strong>
          <p>
            - 구매하신 상품은 <b>선물함 &gt; 구매내역</b>에서 확인 가능합니다.
          </p>
        </SAlter>
      </SSOrderInfo>
    </>
  );
}

export default withRouter(Ticket);
