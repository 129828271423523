import React from "react";
import styled, { css } from "styled-components";

import ReactMoment from "react-moment";
import DatePicker, { registerLocale } from "react-datepicker";
import ko from "date-fns/locale/ko";
import "react-datepicker/dist/react-datepicker.css";
import { Col, Row } from "../../../../styles/styles";
registerLocale("ko", ko);

const SDatePicker = styled.div`
  ${({ theme }) => {
    return css`
      padding: ${theme.paddings.base};
      .react-datepicker {
        display: flex;
        flex: 1 0 auto;
        width: 100%;
        border: 0;
        justify-content: center;
        align-items: center;
        .react-datepicker__month-container {
          width: 100%;
          .react-datepicker__header {
            background-color: transparent;
            padding: 0;
            border: 0;
          }
        }
      }
      .react-datepicker__day-names {
        display: flex;
        justify-content: center;
        .react-datepicker__day-name {
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          color: #666;
        }
      }
      .custom_header {
        display: flex;
        height: 40px;
        justify-content: center;
        align-items: center;
        .custom_header_month {
          font-size: ${theme.fonts.size.base};
          padding: 0 ${theme.paddings.base};
        }
        .prev_button {
          background-color: transparent;
          :before {
            content: "\f104";
            font-family: ${theme.fonts.family.fontAwesome};
            font-size: ${theme.fonts.size.lg};
          }
        }
        .next_button {
          background-color: transparent;
          :before {
            content: "\f105";
            font-family: ${theme.fonts.family.fontAwesome};
            font-size: ${theme.fonts.size.lg};
          }
        }
      }
      .react-datepicker__week {
        display: flex;
        justify-content: center;
        .react-datepicker__day {
          position: relative;
          display: flex;
          height: 40px;
          width: 40px;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          color: ${theme.colors.default};
          &.react-datepicker__day--outside-month {
            color: ${theme.colors.gray};
          }
          &.react-datepicker__day:hover,
          &.react-datepicker__quarter-text:hover,
          &.react-datepicker__month-text:hover,
          &.react-datepicker__year-text:hover {
            background-color: ${theme.colors.lightgray};
            color: ${theme.colors.default};
            border-radius: 50%;
          }
          &.react-datepicker__day--selected {
            background-color: ${theme.colors.primary};
            color: white;
            border-radius: 50%;
          }
          &.react-datepicker__day--keyboard-selected {
            opacity: 0.5;
            background-color: ${theme.colors.lightprimary};
            color: ${theme.colors.primary};
            border-radius: 50%;
          }
          &.react-datepicker__day--today {
            background-color: ${theme.colors.lightprimary};
            color: ${theme.colors.primary};
            border-radius: 50%;
          }
          .react-datepicker__day--keyboard-selected,
          .react-datepicker__month-text--keyboard-selected,
          .react-datepicker__quarter-text--keyboard-selected,
          .react-datepicker__year-text--keyboard-selected {
            border-radius: 0.3rem;
            background-color: #aaa;
            color: ${theme.colors.primary};
          }
        }
      }
    `;
  }}
`;
const SItemlist = styled.div`
  ${({ theme }) => {
    return css`
      padding: ${theme.paddings.base} 0;
      .title {
        padding: 0 ${theme.paddings.base};
        margin-bottom: ${theme.margins.base};
        font-size: ${theme.fonts.size.sm};
        color: ${theme.colors.default};
        font-weight: ${theme.fonts.weight.medium};
      }
      ul {
        border-bottom: solid 5px #eee;
        height: 100px;
        overflow-x: hidden;
        overflow-y: scroll;
        white-space: nowrap;
        z-index: 999;
        ::-webkit-scrollbar {
          display: none;
        }
        li {
          margin-bottom: ${theme.margins.sm};
        }
      }
    `;
  }}
`;
const TicketingItem = styled.label`
  ${({ theme }) => {
    return css`
      display: block;
      background-color: ${theme.colors.lightgray};
      border-radius: 5px;
      padding: 12px ${theme.paddings.base};
      &.disabled {
        opacity: 0.6;
      }
      &.active {
        background-color: ${theme.colors.lightprimary};
        strong {
          color: ${theme.colors.primary};
        }
      }
      strong {
        font-size: 14px;
        line-height: 1.3;
        font-weight: ${theme.fonts.weight.medium};
        color: ${theme.colors.default};
      }
      .cont {
        font-size: 12px;
        color: ${theme.colors.default};
        .left {
        }
        .right {
        }
      }
    `;
  }}
`;

function TicketDate({ limit, giftOrderProductCount, giftProductOptions }) {
  const ticketDateObject = giftProductOptions[0];
  const ticketDate = new Date(
    ticketDateObject.giftProductOptionDetails[0].name
  );
  const ticketTimeObject = giftProductOptions[1];
  const ticketDatetime = new Date(
    Date.parse(
      `${ticketDateObject.giftProductOptionDetails[0].name} ${ticketTimeObject.giftProductOptionDetails[0].name}`
    )
  );
  const ticketIsSoldOut =
    ticketDateObject.giftProductOptionDetails[0].isSoldOut;
  const ticketPrice =
    ticketDateObject.giftProductOptionDetails[0].price +
    ticketTimeObject.giftProductOptionDetails[0].price;

  const CustomInput = ({ value, onClick }, ref) => {
    return (
      <TicketingItem className="active" onClick={onClick}>
        <Row className="cont">
          <Col left className="left">
            <strong>
              {ticketIsSoldOut && "[매진]"}{" "}
              {ticketDatetime.getHours() < 12 ? (
                <>
                  <ReactMoment format="yyyy년 MM월 DD일">
                    {ticketDatetime}
                  </ReactMoment>{" "}
                  오전{" "}
                  <ReactMoment format=" HH시 mm분">
                    {ticketDatetime}
                  </ReactMoment>
                </>
              ) : (
                <>
                  <ReactMoment format="yyyy년 MM월 DD일">
                    {ticketDatetime}
                  </ReactMoment>{" "}
                  오후{" "}
                  <ReactMoment format="HH시 mm분">{ticketDatetime}</ReactMoment>
                </>
              )}
            </strong>
          </Col>
          <Col right className="right">
            남은티켓&nbsp; <b>{limit - giftOrderProductCount}</b>매
          </Col>
        </Row>
        {ticketPrice > 0 && (
          <span>
            (
            <b>
              +
              {ticketPrice.toLocaleString("ko-KR", {
                maximumFractionDigits: 4,
              })}
            </b>
            드림)
          </span>
        )}
        <input hidden type="checkbox" />
      </TicketingItem>
    );
  };
  const CustomHeader = ({ date, decreaseMonth, increaseMonth }) => {
    return (
      <div className="custom_header">
        <button className="prev_button" onClick={decreaseMonth}>
          <span></span>
        </button>
        <div className="custom_header_month">
          <ReactMoment format="yyyy년 MM월">{date}</ReactMoment>
        </div>
        <button className="next_button" onClick={increaseMonth}>
          <span></span>
        </button>
      </div>
    );
  };
  return (
    <>
      <SItemlist>
        <ul>
          <li>
            <SDatePicker>
              <DatePicker
                customInput={<CustomInput />}
                renderCustomHeader={CustomHeader}
                locale="ko"
                selected={ticketDate}
                dateFormat="yyyy-MM-dd"
                withPortal
              />
            </SDatePicker>
          </li>
        </ul>
      </SItemlist>
    </>
  );
}
export default TicketDate;
