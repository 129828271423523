import React from "react";
import styled from "styled-components";
import theme from "../../../styles/Theme";
import { SearchNormal1 } from "iconsax-react";
import Button from "../../Buttons";
import { SearchContainer } from "../../../styles/styles";
function Search() {
  return (
    <SearchContainer>
      <form>
        <div className="inner">
          <Button className="searchBtn">
            <SearchNormal1
              color={theme.colors.black01}
              variant="Linear"
              size={24}
            />
          </Button>
          <input
            type="text"
            name="keyword"
            placeholder="상점 검색어를 입력해주세요."
            autoComplete="off"
          />
        </div>
      </form>
    </SearchContainer>
  );
}

export default Search;
