import React from "react"
import { withRouter, Link } from "react-router-dom"
import { useQuery, useMutation } from "@apollo/client"
import {
  ACCOUNT_INQUIRIES_QUERY,
  INQUIRYDELETE_MUTATION,
} from "../../Config/Queries"

import { Layout } from "../../Components/Layouts/Layout"
import PageTitle from "../../Components/Layouts/PageTitle"
import styled, { css } from "styled-components"
import {
  Container,
  SOrderItem,
  Ready,
  MainTitle,
  MainText,
} from "../../styles/styles"
import ProductItem from "../../Components/Inquiry/Account/Inquiry/List/Item"
import Item from "../../Components/Inquiry/Product/Common/Detail/Item"
import Pagination from "../../Components/Pagination"
import SLoading from "../../Components/Loading"
import qs from "qs"

const SContainer = styled(Container)`
  ${({ theme }) => {
    return css`
      padding: 0 ${theme.paddings.xl};
    `
  }}
`
const SItemlist = styled.div`
  ${({ theme }) => {
    return css`
      li {
        margin-bottom: ${theme.margins.base};
        border: solid 1px #eee;
        padding: ${theme.paddings.base};
        border-radius: 8px;
      }
    `
  }}
`

function Inquiry({ history, match, location }) {
  const queryString = qs.parse(location.search.substr(1))
  const page = queryString.page ? queryString.page : 1
  const blockSize = 5
  const first = 10
  const skip = first * (page - 1)

  const baseUrl = "?"

  const { data, loading } = useQuery(ACCOUNT_INQUIRIES_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      orderBy: "id_DESC",
      skip: skip,
      first: first,
    },
  })

  const [inquiryDeleteMutation] = useMutation(INQUIRYDELETE_MUTATION, {
    refetchQueries: () => [
      {
        query: ACCOUNT_INQUIRIES_QUERY,
        variables: {
          orderBy: "id_DESC",
          skip: skip,
          first: first,
        },
      },
    ],
  })

  const onDelete = async (id) => {
    if (!window.confirm("선택한 선물문의를 삭제하시겠습니까?")) {
      return
    }

    try {
      const {
        data: { GiftProductInquiryDelete },
      } = await inquiryDeleteMutation({
        variables: { id },
      })

      if (GiftProductInquiryDelete) {
        // refetch();
        // setItems([...items.filter((item) => item.id !== id)]);
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <Layout isNav={true} isFooter={true}>
        <PageTitle title="선물문의" />
        <SContainer>
          <MainTitle>
            <MainText>작성한 선물문의가</MainText>
            <MainText>
              <span className="TextBlue">
                {!loading &&
                  data.GiftAccountInquiryList &&
                  data.GiftAccountInquiryList.count}
              </span>
              건 있어요.
            </MainText>
          </MainTitle>
          {!loading ? (
            <>
              {data.GiftAccountInquiryList.count > 0 ? (
                <>
                  <SItemlist>
                    <ul>
                      {data.GiftAccountInquiryList.giftProductInquiries.map(
                        (item, index) => {
                          const uri =
                            item.giftProduct.productType === "Product"
                              ? `/products/${item.giftProduct.id}`
                              : `/tickets/${item.giftProduct.id}`
                          return (
                            <li key={index}>
                              <Link to={uri}>
                                <ProductItem item={item.giftProduct} />
                              </Link>
                              <Item
                                item={item}
                                uri={"/account/inquiries"}
                                onDelete={onDelete}
                              />
                            </li>
                          )
                        }
                      )}
                    </ul>
                  </SItemlist>
                  <Pagination
                    totalRecord={data.GiftAccountInquiryList.count}
                    blockSize={blockSize}
                    pageSize={first}
                    currentPage={page}
                    baseUrl={baseUrl}
                  />
                </>
              ) : (
                <Ready className="list_none">등록된 선물이 없습니다.</Ready>
              )}
            </>
          ) : (
            <SLoading />
          )}
        </SContainer>
      </Layout>
    </>
  )
}
export default withRouter(Inquiry)
