import React from "react";

import { Layout } from "../../Components/Layouts/Layout";
import PageTitle from "../../Components/Layouts/PageTitle";
import { SProvision } from "../../styles/styles";
import { provision } from "../../Config/Env";

function ThirdParty() {
    return (
        <Layout isNav={false} isFooter={true}>
            <PageTitle title={`${provision.ThirdParty.title}`} />
            <SProvision>
                <h1>{provision.ThirdParty.title}</h1>
                <div className="inner">{provision.ThirdParty.content}</div>
            </SProvision>
        </Layout>
    );
}

export default ThirdParty;
