import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { ACCOUNT_ORDER_QUERY } from "../../../Config/Queries"

import { Layout } from "../../../Components/Layouts/Layout"
import PageTitle from "../../../Components/Layouts/PageTitle"
import styled, { css } from "styled-components"
import { Container, PageLocation } from "../../../styles/styles"
import Accordions from "../../../Components/Accordions"
import SLoding from "../../../Components/Loading"
import Payment from "../../../Components/Order/Account/Order/Detail/Payment"
import Refund from "../../../Components/Order/Account/Order/Detail/Refund"
import { orderinfo } from "../../../Config/Env"
import Product from "../../../Components/Order/Account/Order/Detail/Product"
import Ticket from "../../../Components/Order/Account/Order/Detail/Ticket"

const SContainer = styled(Container)`
  ${({ theme }) => {
    return css`
      .detail_top {
      }
      .detail_content {
        .orderinfo {
          padding: ${theme.paddings.base} 0;
          font-size: ${theme.fonts.size.sm};
        }
      }
    `
  }}
`
const SAccordion = styled(Accordions)`
  ${({ theme }) => {
    return css`
      padding: 0;
    `
  }}
`

function Detail({ history, match, location }) {
  const id = match.params.id

  const [items, setItems] = useState([])

  const { data, loading } = useQuery(ACCOUNT_ORDER_QUERY, {
    fetchPolicy: "network-only",
    variables: { id },
  })

  useEffect(() => {
    if (!loading) {
      const {
        GiftAccountOrderDetail: { giftProduct, isRefund },
      } = data
      if (isRefund) {
        setItems([
          {
            uuid: "0",
            heading: "결제정보",
            content: <Payment data={data} />,
          },
          {
            uuid: "1",
            heading: "환불정보",
            content: <Refund data={data} />,
          },
          {
            uuid: "2",
            heading: "환불안내",
            content:
              (giftProduct.productType === "Product" && (
                <div>{orderinfo.Refund.content}</div>
              )) ||
              (giftProduct.productType === "Ticket" && (
                <div>{orderinfo.Refund.content}</div>
              )),
          },
        ])
      } else {
        setItems([
          {
            uuid: "0",
            heading: "결제정보",
            content: <Payment data={data} />,
          },
          {
            uuid: "1",
            heading: "환불안내",
            content:
              (giftProduct.productType === "Product" && (
                <div className="orderinfo">{orderinfo.Refund.content}</div>
              )) ||
              (giftProduct.productType === "Ticket" && (
                <div className="orderinfo">{orderinfo.Refund.content}</div>
              )),
          },
        ])
      }
    }
  }, [data, loading])

  if (!loading) {
    const {
      GiftAccountOrderDetail: { giftProduct, isRefund },
    } = data

    return (
      <Layout isNav={true} isFooter={true}>
        <PageTitle title="구매내역" />
        <SContainer>
          <div className="detail_top">
            {giftProduct.productType === "Product" && <Product data={data} />}
            {giftProduct.productType === "Ticket" && <Ticket data={data} />}
          </div>
          <div className="detail_content">
            <SAccordion
              preExpanded={isRefund ? ["0", "1"] : ["0"]}
              items={items}
            />
          </div>
        </SContainer>
      </Layout>
    )
  } else {
    return <SLoding />
  }
}

export default withRouter(Detail)
