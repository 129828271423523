import React, { useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import {
    ACCOUNT_READYORDERPRODUCTS_QUERY,
    ACCOUNT_ORDERPRODUCT_ISREADSEDIT_MUTATION,
    ACCOUNT_QUERY,
} from "../../../Config/Queries";

import styled, { css } from "styled-components";
import {
    Container,
    TabsNav,
    Ready,
    MainTitle,
    MainText,
} from "../../../styles/styles";
import { Layout } from "../../../Components/Layouts/Layout";
import PageTitle from "../../../Components/Layouts/PageTitle";
import ProductItem from "../../../Components/OrderProduct/Account/OrderProduct/List/ProductItem";
import TicketItem from "../../../Components/OrderProduct/Account/OrderProduct/List/TicketItem";
import Pagination from "../../../Components/Pagination";
import SLoading from "../../../Components/Loading";
import qs from "qs";
import { toast } from "react-toastify";

const SContainer = styled(Container)`
    ${({ theme }) => {
        return css`
            padding: 0 ${theme.paddings.xl};
        `;
    }}
`;
const SItemlist = styled.div`
    ${({ theme }) => {
        return css`
            margin: ${theme.margins.lg} -${theme.margins.base} 0;
            ul {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                li {
                    display: flex;
                    padding: 0 ${theme.paddings.base};
                    ${theme.device.desktop} {
                        flex: 0 0 33.3%;
                    }
                    ${theme.device.mobile} {
                        flex: 0 0 50%;
                    }
                    margin-bottom: ${theme.margins.lg};
                }
            }
        `;
    }}
`;

function List({ history, match, location }) {
    const isApp = localStorage.getItem("isApp");

    const queryString = qs.parse(location.search.substr(1));
    const page = queryString.page ? queryString.page : 1;
    const blockSize = 5;
    const first = 12;
    const skip = first * (page - 1);

    const baseUrl = "?";

    const { data, loading } = useQuery(ACCOUNT_READYORDERPRODUCTS_QUERY, {
        fetchPolicy: "network-only",
        variables: {
            orderBy: "id_DESC",
            skip: skip,
            first: first,
        },
    });

    const [orderProductIsReadEditMutation] = useMutation(
        ACCOUNT_ORDERPRODUCT_ISREADSEDIT_MUTATION,
        {
            refetchQueries: () => [{ query: ACCOUNT_QUERY }],
        }
    );

    useEffect(() => {
        const onRead = async () => {
            if (isApp) {
                const data = {
                    method: "orderProducts",
                };
                window.ReactNativeWebView.postMessage(JSON.stringify(data));
            }
            // try {
            const {
                data: { GiftOrderProductIsReadsEdit },
            } = await orderProductIsReadEditMutation({
                variables: {
                    isRead: true,
                },
            });
            // } catch (e) {
            //   console.log(e);
            //   var error = e.toString();
            //   error = error.replace("Error: GraphQL error:", "");
            //   toast.error(error);
            // }
        };
        onRead();
    }, []);

    return (
        <Layout isNav={true} isFooter={true}>
            <PageTitle title="받은 선물함" />
            <SContainer>
                <MainTitle>
                    <MainText>사용가능한 상품/서비스가</MainText>
                    <MainText>
                        <span className="TextBlue">
                            {!loading &&
                                data.GiftAccountOrderProductReadyList.count}
                            개
                        </span>{" "}
                        남아있어요.
                    </MainText>
                </MainTitle>
                <TabsNav>
                    <ul>
                        <li className="active">
                            <Link to="/account/readyOrderProducts">
                                <span>사용가능</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/account/completeOrderProducts">
                                <span>사용완료</span>
                            </Link>
                        </li>
                    </ul>
                </TabsNav>
                {!loading ? (
                    <>
                        {data.GiftAccountOrderProductReadyList.count > 0 ? (
                            <>
                                <SItemlist>
                                    <ul>
                                        {data.GiftAccountOrderProductReadyList.giftOrderProducts.map(
                                            (item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <Link
                                                            to={`/account/orderProducts/${item.id}`}
                                                        >
                                                            {item.giftProduct
                                                                .productType ===
                                                                "Product" && (
                                                                <ProductItem
                                                                    item={item}
                                                                />
                                                            )}
                                                            {item.giftProduct
                                                                .productType ===
                                                                "Ticket" && (
                                                                <TicketItem
                                                                    item={item}
                                                                />
                                                            )}
                                                        </Link>
                                                    </li>
                                                );
                                            }
                                        )}
                                    </ul>
                                </SItemlist>
                                <Pagination
                                    totalRecord={
                                        data.GiftAccountOrderProductReadyList
                                            .count
                                    }
                                    blockSize={blockSize}
                                    pageSize={first}
                                    currentPage={page}
                                    baseUrl={baseUrl}
                                />
                            </>
                        ) : (
                            <Ready>
                                <div>받은선물이 없습니다.</div>
                            </Ready>
                        )}
                    </>
                ) : (
                    <SLoading />
                )}
            </SContainer>
        </Layout>
    );
}

export default withRouter(List);
