import React from "react";
import styled, { css } from "styled-components";
import { withRouter } from "react-router-dom";
import NumberFormat from "react-number-format";
import { graphqlUrl } from "../../../../Config/Env";

export const SItem = styled.div`
    ${({ theme }) => {
        return css`
            .img {
                position: relative;
                overflow: hidden;
                border-radius: 8px;
                padding-bottom: 100%;
                height: 0;
                overflow: hidden;
                :before {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    border: 1px solid rgba(0, 0, 0, 0.05);
                    border-radius: 8px;
                }
                img {
                    max-width: 100%;
                    display: block;
                    width: 100%;
                    height: auto;
                }
            }
            .complete_icon {
                position: absolute;
                background-color: rgba(255, 255, 255, 0.8);
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 99;
                display: flex;
                justify-content: center;
                align-items: center;
                div {
                    position: relative;
                    transform: rotate(-30deg);
                    width: 64px;
                    height: 64px;
                    border: solid 2px #555;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 12px;
                    color: #777;
                    ::before {
                        content: "";
                        position: absolute;
                        width: 54px;
                        height: 54px;
                        z-index: 99;
                        border-radius: 50%;
                        border: solid 1px #bbb;
                    }
                }
            }
            .cont {
                margin-top: 10px;
                .category {
                    color: ${theme.colors.black04};
                    font-size: ${theme.fonts.size.sm};
                    font-weight: 400;
                }
                h3 {
                    margin-top: ${theme.margins.sm};
                    height: 38px;
                    overflow: hidden;
                    font-size: ${theme.fonts.size.sm};
                    color: ${theme.colors.black01};
                    font-weight: ${theme.fonts.weight.medium};
                    line-height: 1.3;
                }
            }
            .discount {
                margin-top: 10px;
                color: ${theme.colors.danger};
                font-size: ${theme.fonts.size.sm};
                span {
                    color: ${theme.colors.black04};
                    text-decoration: line-through;
                }
            }
            .price {
                margin-top: 5px;
                font-size: 16px;
                color: ${theme.colors.black01};
                font-weight: ${theme.fonts.weight.base};
            }
            .rank {
                background-color: #f1f1f1;
                width: 32px;
                height: 32px;
                justify-content: center;
                display: flex;
                align-items: center;
                border-radius: 50%;
                span {
                    font-size: ${theme.fonts.size.base};
                    color: #777;
                    font-weight: ${theme.fonts.weight.bold};
                }
            }
        `;
    }}
`;

function Item({ history, match, location, rank, item }) {
    const CoverImage =
        item.coverImage === null
            ? "/assets/img/product/product-default.jpg"
            : `${graphqlUrl}${item.coverImage}`;
    const Percent = parseInt((item.discountPrice / item.price) * 100);

    return (
        <SItem>
            <div className="img">
                <img src={CoverImage} alt={item.name} />
                {item.isSoldOut && (
                    <div className="complete_icon">
                        <div>
                            <span>품절</span>
                        </div>
                    </div>
                )}
            </div>
            <div className="cont">
                <span className="category">{item.store.name}</span>
                <h3>{item.name}</h3>
                {item.isDiscount ? (
                    <>
                        <div className="discount">
                            <b>{Percent}</b>%{" "}
                            <span>
                                <NumberFormat
                                    value={item.price}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                />
                                드림
                            </span>
                        </div>
                        <div className="price">
                            <b>
                                <NumberFormat
                                    value={item.price - item.discountPrice}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                />
                            </b>
                            드림
                        </div>
                    </>
                ) : (
                    <>
                        <div className="price">
                            <b>
                                <NumberFormat
                                    value={item.price}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                />
                            </b>
                            드림
                        </div>
                    </>
                )}
            </div>
        </SItem>
    );
}
export default withRouter(Item);
