import React from "react"
import { withRouter, Link } from "react-router-dom"
import { useQuery, useMutation } from "@apollo/client"
import {
  ACCOUNT_COMPLETEREVIEWS_QUERY,
  ACCOUNT_REVIEWDELETE_MUTATION,
} from "../../../Config/Queries"

import { Layout } from "../../../Components/Layouts/Layout"
import PageTitle from "../../../Components/Layouts/PageTitle"
import styled, { css } from "styled-components"
import {
  Container,
  TabsNav,
  Ready,
  MainTitle,
  MainText,
} from "../../../styles/styles"
import ProductItem from "../../../Components/Review/Account/Review/List/ProductItem"
import TicketItem from "../../../Components/Review/Account/Review/List/TicketItem"
import Item from "../../../Components/Review/Product/Common/Detail/Item"
import Pagination from "../../../Components/Pagination"
import SLoading from "../../../Components/Loading"
import qs from "qs"

const SContainer = styled(Container)`
  ${({ theme }) => {
    return css`
      padding: 0 ${theme.paddings.xl};
    `
  }}
`
const ReviewList = styled.div`
  ${({ theme }) => {
    return css`
      ul {
        li {
          background-color: white;
          border-radius: 8px;
          margin-bottom: ${theme.margins.base};
          border: solid 1px #eee;
          &a {
            display: block;
            padding: 0;
          }
          .review_bottom {
            padding: 0 ${theme.paddings.base} ${theme.paddings.base};
          }
        }
      }
      dl dt {
        display: none;
      }
      .complate {
        padding: ${theme.paddings.base} 0;
        border: 0 !important;
      }
      .product_list {
        border: 0 !important;
      }
    `
  }}
`

function List({ history, match, location }) {
  const queryString = qs.parse(location.search.substr(1))
  const page = queryString.page ? queryString.page : 1
  const blockSize = 5
  const first = 10
  const skip = first * (page - 1)

  const baseUrl = "?"

  const { data, loading } = useQuery(ACCOUNT_COMPLETEREVIEWS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      orderBy: "id_DESC",
      skip: skip,
      first: first,
    },
  })

  const [reviewDeleteMutation] = useMutation(ACCOUNT_REVIEWDELETE_MUTATION, {
    refetchQueries: () => [
      {
        query: ACCOUNT_COMPLETEREVIEWS_QUERY,
        fetchPolicy: "network-only",
        variables: {
          orderBy: "id_DESC",
          skip: skip,
          first: first,
        },
      },
    ],
  })

  const onDelete = async (id) => {
    if (!window.confirm("선택한 선물후기를 삭제하시겠습니까?")) {
      return
    }

    try {
      const {
        data: { GiftAccountReviewDelete },
      } = await reviewDeleteMutation({
        variables: { id },
      })

      if (GiftAccountReviewDelete) {
        // refetch();
        // setItems([...items.filter((item) => item.id !== id)]);
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Layout isNav={true} isFooter={true}>
      <PageTitle title="선물후기" />
      <SContainer>
        <MainTitle>
          <MainText>작성 완료한 후기가</MainText>
          <MainText>
            <span className="TextBlue">
              {!loading && data.GiftAccountReviewCompleteList.count}개
            </span>{" "}
            있어요.
          </MainText>
        </MainTitle>
        <TabsNav>
          <ul>
            <li>
              <Link to="/account/readyReviews">
                <span>작성 가능한 상품</span>
              </Link>
            </li>
            <li className="active">
              <Link to="/account/completeReviews">
                <span>작성 완료한 상품</span>
              </Link>
            </li>
          </ul>
        </TabsNav>
        {!loading ? (
          <>
            {data.GiftAccountReviewCompleteList.count > 0 ? (
              <>
                <ReviewList>
                  <ul>
                    {data.GiftAccountReviewCompleteList.giftProductReviews.map(
                      (item, index) => {
                        const ProductUri =
                          (item.giftOrderProduct.giftProduct.productType ===
                            "Product" &&
                            `/products/${item.giftOrderProduct.giftProduct.id}`) ||
                          (item.giftOrderProduct.giftProduct.productType ===
                            "Ticket" &&
                            `/tickets/${item.giftOrderProduct.giftProduct.id}`)

                        return (
                          <li key={index}>
                            <Link to={ProductUri}>
                              {item.giftOrderProduct.giftProduct.productType ===
                                "Product" && (
                                <ProductItem item={item.giftOrderProduct} />
                              )}
                              {item.giftOrderProduct.giftProduct.productType ===
                                "Ticket" && (
                                <TicketItem item={item.giftOrderProduct} />
                              )}
                            </Link>
                            <div className="review_bottom">
                              <Item
                                item={item}
                                uri={"/account/completeReviews"}
                                onDelete={onDelete}
                              />
                            </div>
                          </li>
                        )
                      }
                    )}
                  </ul>
                </ReviewList>
                <Pagination
                  totalRecord={data.GiftAccountReviewCompleteList.count}
                  blockSize={blockSize}
                  pageSize={first}
                  currentPage={page}
                  baseUrl={baseUrl}
                />
              </>
            ) : (
              <Ready>
                <div className="none_list">작성한 선물후기가 없습니다.</div>
              </Ready>
            )}
          </>
        ) : (
          <SLoading />
        )}
      </SContainer>
    </Layout>
  )
}
export default withRouter(List)
