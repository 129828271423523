import React, { useState, useBodyScrollLock } from "react";
import { withRouter } from "react-router-dom";

import { Layout } from "../../../Components/Layouts/Layout";
import PageTitle from "../../../Components/Layouts/PageTitle";
import styled, { css } from "styled-components";
import {
    Row,
    Col,
    SwiperSlideImg,
    ProductDetail,
    ProductTab,
    SAccordion,
    FooterButton,
} from "../../../styles/styles";
import { Swiper, SwiperSlide } from "swiper/react";
import Alter from "../../../Components/Alter";
import IsWishlist from "../../../Components/Wishlist/Product/Common/Detail/IsWishlist";
import Intro from "../../../Components/Product/Common/Intro";
import Review from "../../../Components/Review/Product/Common/Detail/Review";
import Inquiry from "../../../Components/Inquiry/Product/Common/Detail/Inquiry";
import PushModal from "../../../Components/Product/Product/Detail/PushModal";
import OrderOptionSelectModal from "../../../Components/Product/Product/Detail/OrderOptionSelectModal";
import OrderUserSearchModal from "../../../Components/Product/Product/Detail/OrderUserSearchModal";
import Button from "../../../Components/Buttons";
import Label from "../../../Components/Labels";
import Tabs, { TabPane } from "rc-tabs";
import { StickyContainer, Sticky } from "react-sticky-17";
import moment from "moment";
import NumberFormat from "react-number-format";
import { graphqlUrl, orderinfo } from "../../../Config/Env";
import { toast } from "react-toastify";

import { useReactiveVar } from "@apollo/client";
import { isLoggedInVar } from "../../../apollo";
import { EmojiHappy } from "iconsax-react";

const SAlter = styled(Alter)`
    ${({ theme }) => {
        return css`
            background-color: ${theme.colors.lightgray};
            border: solid 1px #eee;
            border-radius: 5px;
            padding: ${theme.paddings.base};
        `;
    }}
`;
const DetailInfo = styled.div`
    ${({ theme }) => {
        return css`
            padding: ${theme.paddings.xl};
        `;
    }}
`;
const DetailButton = styled.div`
    ${({ theme }) => {
        return css`
            display: flex;
            flex-direction: row;
            padding: ${theme.paddings.base} 0;
            .like {
                margin-right: 10px;
                background-color: white;
                border: solid 1px ${theme.colors.danger};
                width: 100%;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;
                font-size: 14px;
                color: ${theme.colors.danger};
                border-radius: 4px;
                span {
                    margin-left: 5px;
                }
                &.active {
                    background-color: ${theme.colors.danger};
                    color: white;
                }
            }
            .tease {
                background-color: white;
                border: solid 1px ${theme.colors.primary};
                width: 100%;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;
                font-size: 14px;
                color: ${theme.colors.primary};
                border-radius: 4px;
                font-weight: normal;
                span {
                    margin-left: 5px;
                }
                &:hover,
                &.active {
                    background-color: ${theme.colors.primary};
                    color: white;
                }
            }
        `;
    }}
`;

const renderTabBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={80}>
        {({ style }) => (
            <ProductTab>
                <DefaultTabBar {...props} style={{ ...style }} />
            </ProductTab>
        )}
    </Sticky>
);

function Presenter({ history, match, location, data }) {
    const isLoggedIn = useReactiveVar(isLoggedInVar);

    const [pushModalIsOpen, setPushModalIsOpen] = useState(false);
    const pushToggleModal = () => {
        if (!isLoggedIn) {
            toast.error("로그인 후 이용해주세요.");
            history.push(`/login`);
            return;
        }
        if (isSoldOut) {
            toast.error("상품이 품절되어 쪼르기를 할 수 없습니다.");
            return;
        }
        setPushModalIsOpen(!pushModalIsOpen);
    };

    const [orderOptionSelectModalIsOpen, setOrderOptionSelectModalIsOpen] =
        useState(false);
    const orderOptionSelectToggleModal = () => {
        if (!isLoggedIn) {
            toast.error("로그인 후 이용해주세요.");
            history.push(`/login`);
            return;
        }
        if (isSoldOut) {
            toast.error("상품이 품절되어 구입할 수 없습니다.");
            return;
        }
        setOrderOptionSelectModalIsOpen(!orderOptionSelectModalIsOpen);
    };

    const [orderUserSearchModalIsOpen, setOrderUserSearchModalIsOpen] =
        useState(false);
    const orderUserSearchToggleModal = () => {
        if (!isLoggedIn) {
            toast.error("로그인 후 이용해주세요.");
            history.push(`/login`);
            return;
        }
        if (isSoldOut) {
            toast.error("상품이 품절되어 선물할 수 없습니다.");
            return;
        }
        setOrderUserSearchModalIsOpen(!orderUserSearchModalIsOpen);
    };

    const {
        GiftProductDetail: {
            id,
            giftCategory,
            store,
            commonStatus,
            coverImage,
            isSoldOut,
            isDiscount,
            discountPrice,
            price,
            name,
            contents,

            giftProductOptionsCount,
            giftProductOptions,
            giftProductReviewsCount,
            giftProductReviews,
            giftProductInquiriesCount,
            giftProductInquiries,
        },
    } = data;

    const CoverImage =
        coverImage === null
            ? "../assets/img/product/product-default.jpg"
            : `${graphqlUrl}${coverImage}`;
    const Percent = parseInt((discountPrice / price) * 100);

    const [isDetail, setIsDetail] = useState(false);
    const toggleDetail = () => {
        setIsDetail(!isDetail);
    };

    const displayDate = new Date("2022-09-19");
    return (
        <>
            <Layout isNav={false} isFooter={true} isFooterButton={true}>
                <PageTitle title={name} />
                <ProductDetail>
                    <div className="swiper-banner">
                        <Swiper pagination={false} className="mySwiper">
                            <SwiperSlide>
                                <SwiperSlideImg>
                                    <img src={CoverImage} alt={name} />
                                </SwiperSlideImg>
                            </SwiperSlide>
                        </Swiper>
                        {isSoldOut && (
                            <div className="complete_icon">
                                <div>품절</div>
                            </div>
                        )}
                    </div>
                    <DetailInfo>
                        <Label color="primary" border="solid">
                            {store.name}
                        </Label>
                        <h3>{name}</h3>
                        <div className="cont">
                            {isDiscount ? (
                                <>
                                    <div className="discounts">
                                        <NumberFormat
                                            value={price}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                        />
                                        드림
                                    </div>
                                    <div className="price">
                                        <b className="percent">{Percent}% </b>
                                        <b>
                                            <NumberFormat
                                                value={price - discountPrice}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                            />
                                        </b>
                                        드림
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="price">
                                        <b>
                                            <NumberFormat
                                                value={price}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                            />
                                        </b>
                                        드림
                                    </div>
                                </>
                            )}
                        </div>

                        {moment().isAfter(moment(displayDate)) && (
                            <SAlter color="lightgray" border="radius">
                                <strong>이용안내</strong>
                                <p>
                                    *상품배송은 불가하며 가게에서 직접수령만
                                    가능합니다.
                                </p>
                            </SAlter>
                        )}
                        <DetailButton>
                            <Col className="left">
                                <IsWishlist isLoggedIn={isLoggedIn} />
                            </Col>
                            <Col className="right">
                                <Button
                                    className="tease"
                                    onClick={pushToggleModal}
                                >
                                    <EmojiHappy size="20" />
                                    <span>쪼르기</span>
                                </Button>
                            </Col>
                        </DetailButton>
                    </DetailInfo>
                </ProductDetail>

                <StickyContainer>
                    <Tabs defaultActiveKey="1" renderTabBar={renderTabBar}>
                        <TabPane tab={<span>상세정보</span>} key="1">
                            <Intro contents={contents} />
                            <SAccordion>
                                <div className="saccordion_continer">
                                    <button onClick={toggleDetail}>
                                        {orderinfo.Refund.title}
                                        <span
                                            className={`${
                                                !isDetail ? `active` : ``
                                            }`}
                                        ></span>
                                    </button>
                                    <div
                                        className={`saccordion_text ${
                                            !isDetail ? `hide` : ``
                                        }`}
                                    >
                                        {orderinfo.Refund.content}
                                    </div>
                                </div>
                            </SAccordion>
                        </TabPane>
                        <TabPane
                            tab={
                                <span>선물후기 {giftProductReviewsCount}</span>
                            }
                            key="2"
                        >
                            <Review
                                giftProductReviews={giftProductReviews}
                                uri="/products"
                            />
                        </TabPane>
                        <TabPane
                            tab={
                                <span>
                                    선물문의 {giftProductInquiriesCount}
                                </span>
                            }
                            key="3"
                        >
                            <Inquiry
                                giftProductInquiries={giftProductInquiries}
                                uri="/products"
                            />
                        </TabPane>
                    </Tabs>
                </StickyContainer>

                <PushModal
                    isOpen={pushModalIsOpen}
                    toggleModal={pushToggleModal}
                    id={id}
                />
                {commonStatus === "C" && (
                    <>
                        {!isSoldOut ? (
                            <>
                                <FooterButton>
                                    <div>
                                        <Button
                                            onClick={
                                                orderOptionSelectToggleModal
                                            }
                                            color="lightwarning"
                                            width="full"
                                        >
                                            구매하기
                                        </Button>
                                        <Button
                                            onClick={orderUserSearchToggleModal}
                                            color="lightprimary"
                                            width="full"
                                        >
                                            선물하기
                                        </Button>
                                    </div>
                                </FooterButton>

                                <OrderOptionSelectModal
                                    isOpen={orderOptionSelectModalIsOpen}
                                    toggleModal={orderOptionSelectToggleModal}
                                    id={id}
                                    name={name}
                                    isDiscount={isDiscount}
                                    discountPrice={discountPrice}
                                    price={
                                        isDiscount
                                            ? price - discountPrice
                                            : price
                                    }
                                    giftProductOptionsCount={
                                        giftProductOptionsCount
                                    }
                                    giftProductOptions={giftProductOptions}
                                />
                                <OrderUserSearchModal
                                    isOpen={orderUserSearchModalIsOpen}
                                    toggleModal={orderUserSearchToggleModal}
                                    id={id}
                                    name={name}
                                    isDiscount={isDiscount}
                                    discountPrice={discountPrice}
                                    price={
                                        isDiscount
                                            ? price - discountPrice
                                            : price
                                    }
                                    giftProductOptionsCount={
                                        giftProductOptionsCount
                                    }
                                    giftProductOptions={giftProductOptions}
                                />
                            </>
                        ) : (
                            <FooterButton>
                                <div className="inner">
                                    <Button
                                        color="lightdanger"
                                        width="full"
                                        disabled
                                    >
                                        품절
                                    </Button>
                                </div>
                            </FooterButton>
                        )}
                    </>
                )}
                {commonStatus === "D" && (
                    <FooterButton>
                        <div className="inner">
                            <Button color="lightdanger" width="full" disabled>
                                판매종료
                            </Button>
                        </div>
                    </FooterButton>
                )}
            </Layout>
        </>
    );
}

export default withRouter(Presenter);
