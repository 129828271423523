import React from "react"
import { withRouter, Link } from "react-router-dom"

import { Row, Col, SOrderItem } from "../../../../../styles/styles"
import styled, { css } from "styled-components"
import Links from "../../../../Links"
import Label from "../../../../Labels"
import { graphqlUrl } from "../../../../../Config/Env"
import ReactMoment from "react-moment"
import NumberFormat from "react-number-format"
import renderHTML from "react-render-html"

const SSOrderItem = styled(SOrderItem)`
  ${({ theme }) => {
    return css`
      .orderItem {
        .item_content {
          padding: 0 ${theme.paddings.base};
          .product_list {
            .img {
              border-radius: 8px;
            }
          }
        }
      }
    `
  }}
`

function Item({ history, match, location, item }) {
  const CoverImage =
    item.giftProduct.coverImage === null
      ? "/assets/img/product/product-default.jpg"
      : `${graphqlUrl}${item.giftProduct.coverImage}`

  let OptionString = ""
  if (item.giftProductOptionDetail1) {
    OptionString += ` ${item.giftProductOptionDetail1.name}(${
      item.giftProductOptionDetail1.price > 0 ? "+" : ""
    }${item.giftProductOptionDetail1.price.toLocaleString("ko-KR", {
      maximumFractionDigits: 4,
    })}드림)`
    if (item.giftProductOptionDetail2) {
      OptionString += ` / `
    }
  }
  if (item.giftProductOptionDetail2) {
    OptionString += ` ${item.giftProductOptionDetail2.name}(${
      item.giftProductOptionDetail2.price > 0 ? "+" : ""
    }${item.giftProductOptionDetail2.price.toLocaleString("ko-KR", {
      maximumFractionDigits: 4,
    })}드림)`
    if (item.giftProductOptionDetail3) {
      OptionString += ` / `
    }
  }
  if (item.giftProductOptionDetail3) {
    OptionString += ` ${item.giftProductOptionDetail3.name}(${
      item.giftProductOptionDetail3.price > 0 ? "+" : ""
    }${item.giftProductOptionDetail3.price.toLocaleString("ko-KR", {
      maximumFractionDigits: 4,
    })}드림)`
    if (item.giftProductOptionDetail4) {
      OptionString += ` / `
    }
  }
  if (item.giftProductOptionDetail4) {
    OptionString += ` ${item.giftProductOptionDetail4.name}(${
      item.giftProductOptionDetail4.price > 0 ? "+" : ""
    }${item.giftProductOptionDetail4.price.toLocaleString("ko-KR", {
      maximumFractionDigits: 4,
    })}드림)`
    if (item.giftProductOptionDetail5) {
      OptionString += ` / `
    }
  }
  if (item.giftProductOptionDetail5) {
    OptionString += ` ${item.giftProductOptionDetail5.name}(${
      item.giftProductOptionDetail5.price > 0 ? "+" : ""
    }${item.giftProductOptionDetail5.price.toLocaleString("ko-KR", {
      maximumFractionDigits: 4,
    })}드림)`
  }

  let uri = ""
  if (item.giftProduct.productType === "Product") {
    uri += `/products/${item.giftProduct.id}`
  }
  if (item.giftProduct.productType === "Ticket") {
    uri += `/tickets/${item.giftProduct.id}`
  }

  return (
    <SSOrderItem>
      <div className="orderItem">
        <Row className="item_top">
          <Col className="left">
            <span className="date">
              구매일자 :{" "}
              <ReactMoment format="YYYY.MM.DD HH:mm">
                {item.createdAt}
              </ReactMoment>
            </span>
          </Col>
          <Col className="right">
            <Link to={`/account/orders/${item.id}`}>상세정보</Link>
          </Col>
        </Row>
        <div className="item_content">
          <div className="product_list">
            <Link to={uri}>
              <div className="img">
                <img src={CoverImage} alt={item.giftProduct.name} />
              </div>
              <div className="cont">
                <span className="category">{item.giftProduct.store.name}</span>
                <strong>{item.giftProduct.name}</strong>
                {OptionString !== "" && (
                  <div className="option">
                    추가옵션 :<span className="text">{OptionString}</span>
                  </div>
                )}
              </div>
            </Link>
          </div>
        </div>
        <Row className="item_bottom">
          <Col left className="left">
            <span>
              To.
              {item.receiver[0]?.name}님
              {renderHTML(
                item.receiver.length > 1
                  ? `외 <b>${item.receiver.length - 1}</b>명`
                  : ``
              )}
            </span>
          </Col>
          <Col right className="right">
            <b>
              <NumberFormat
                displayType={"text"}
                value={item.totalPrice}
                thousandSeparator={true}
                suffix={"드림"}
              />
            </b>
          </Col>
        </Row>
      </div>
    </SSOrderItem>
  )
}
export default withRouter(Item)
