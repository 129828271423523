import React from "react"
import { Link } from "react-router-dom"

import { Layout } from "../../../Components/Layouts/Layout"
import PageTitle from "../../../Components/Layouts/PageTitle"
import styled, { css } from "styled-components"
import { Container, Row, Col, Ready, SortOption } from "../../../styles/styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSortAlt, faStoreAlt } from "@fortawesome/pro-light-svg-icons"
import Dropdown from "react-dropdown"
import "react-dropdown/style.css"
import Item from "../../../Components/Product/Product/List/Item"
import { graphqlUrl } from "../../../Config/Env"

const StoreTop = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-direction: row;
      padding: ${theme.paddings.lg} ${theme.paddings.base};
      border-bottom: solid 5px #eee;
      .img {
        width: 56px;
        height: 56px;
        border-radius: 8px;
        border: solid 1px #eee;
        overflow: hidden;
        margin-right: 10px;
        img {
          max-width: 100%;
        }
      }
      .cont {
        display: flex;
        flex: 1 0 auto;
        h3 {
          margin-top: 5px;
          display: flex;
          align-items: center;
          text-align: left;
          overflow: hidden;
          font-size: ${theme.fonts.size.base};
          line-height: 1.3;
          color: ${theme.colors.black01};
        }
        .info {
          margin-top: 5px;
          font-size: ${theme.fonts.size.sm};
          line-height: 1.3;
          color: ${theme.colors.black04};
        }
      }
      .store-btn {
        display: flex;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        background-color: #f5f5f5;
        svg {
          color: ${theme.colors.black04};
          font-size: 16px;
        }
        &:hover {
          background-color: ${theme.colors.lightprimary};
          svg {
            color: ${theme.colors.primary};
          }
        }
      }
      .discount {
        margin-top: 10px;
        color: ${theme.colors.danger};
        font-size: ${theme.fonts.size.sm};
        span {
          color: ${theme.colors.black04};
          text-decoration: line-through;
        }
      }
      .price {
        margin-top: 5px;
        font-size: 16px;
        color: #333;
        font-weight: 500;
      }
    `
  }}
`
const Sort = styled(Row)`
  ${({ theme }) => {
    return css`
      padding: ${theme.paddings.lg} ${theme.paddings.base};
      font-size: ${theme.fonts.size.base};
      .left {
        align-items: center;
        span {
          font-weight: ${theme.fonts.weight.medium};
          color: ${theme.colors.black01};
          b {
            color: ${theme.colors.warning};
          }
        }
      }
      .right {
        flex: 0 0 auto;
        justify-content: flex-end;
      }
    `
  }}
`
const SItemlist = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-wrap: wrap;
      padding: 0 ${theme.paddings.base};
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          display: flex;
          flex: 0 0 33.3%;
          padding: 0 ${theme.paddings.sm};
          margin-bottom: ${theme.margins.lg};
        }
      }
    `
  }}
`

function Detail({ data, orderByOption, orderByDefault, orderByChange }) {
  const {
    GiftStoreDetail: {
      id,
      commonStatus,
      storeCategory,
      storeLogo,
      name,
      storeInfo,

      createdAt,
      updatedAt,

      giftProducts,
    },
  } = data
  const storeLogoImage =
    storeLogo === "" || storeLogo === null
      ? "../assets/img/store-default.png"
      : `${graphqlUrl}${storeLogo}`
  return (
    <>
      <Layout isNav={true} isFooter={true}>
        <PageTitle title="상품" />
        <Container>
          <StoreTop>
            <div className="img">
              <img src={storeLogoImage} alt={name} />
            </div>
            <div className="cont">
              <h3>{name}</h3>
            </div>
          </StoreTop>
          <Sort>
            <Col className="left">
              <span>
                상품 <b>{giftProducts.length}</b>건
              </span>
            </Col>
            <Col className="right">
              <SortOption>
                <Dropdown
                  options={orderByOption}
                  value={orderByDefault}
                  onChange={(item) => {
                    orderByChange(item)
                  }}
                />
              </SortOption>
            </Col>
          </Sort>
          {giftProducts.length > 0 ? (
            <>
              <SItemlist>
                <ul>
                  {giftProducts.map((item, index) => {
                    return (
                      <li key={index}>
                        <Link to={`/products/${item.id}`}>
                          <Item item={item} />
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </SItemlist>
            </>
          ) : (
            <Ready>
              <div className="list_none">등록된 상품이 없습니다.</div>
            </Ready>
          )}
        </Container>
      </Layout>
    </>
  )
}
export default Detail
