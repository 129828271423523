import React, { useState } from "react";
import { withRouter } from "react-router-dom";

import styled, { css } from "styled-components";
import Button from "../../../Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faPlus,
  faMinus,
} from "@fortawesome/pro-regular-svg-icons";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import TicketDate from "./TicketDate";

const Modals = styled.div`
  ${({ theme }) => {
    return css`
      .modal {
        display: none;
        position: fixed;
        bottom: 0;
        left: 0;
        right: -0;
        z-index: 99;
        .title {
          font-size: ${theme.fonts.size.sm};
          margin-bottom: ${theme.margins.sm};
        }
        section {
          box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.1);
          width: 100%;
          max-width: 750px;
          margin: 0 auto;
          border-top-right-radius: 8px;
          border-top-left-radius: 8px;
          background-color: #fff;
          /* 팝업이 열릴때 스르륵 열리는 효과 */
          animation: modal-show 0.3s;
          overflow: hidden;
        }
        header {
          position: relative;
          height: 40px;
          font-weight: 700;
          justify-content: center;
          align-items: center;
          display: flex;
          border-bottom: 1px solid #dee2e6;
          button {
            font-size: 21px;
            font-weight: 700;
            text-align: center;
            border: 0;
            color: #999;
            background-color: transparent;
          }
        }
        footer {
          height: 50px;
          display: flex;
        }
      }
      .modal.openModal {
        display: flex;
        align-items: center;
      }
      @keyframes modal-show {
        from {
          margin-top: -100px;
        }
        to {
          margin-top: 0;
        }
      }
    `;
  }}
`;
const SPayment = styled.div`
  ${({ theme }) => {
    return css`
      padding: ${theme.paddings.base};
      .title {
        margin: ${theme.margins.sm} 0;
        font-size: ${theme.fonts.size.sm};
      }
      .priceList {
        background-color: ${theme.colors.lightgray};
        margin-bottom: ${theme.margins.sm};
        border: solid 1px #eee;
        border-radius: 5px;
        margin-top: 1rem;
        .name {
          margin-bottom: 10px;
          font-size: ${theme.fonts.size.base};
        }
      }
      .flextext {
        flex: 1;
        padding: ${theme.paddings.base};
        ul {
          li {
            display: flex;
            flex-direction: row;
            padding: ${theme.paddings.sm} 0;
            .left {
              flex: 1;
              font-size: 1.3rem;
              color: #666;
            }
            .right {
              flex: 1;
              text-align: right;
              justify-content: flex-end;
              font-size: 1.4rem;
            }
          }
        }
      }
      .price_total {
        background-color: white;
        padding: ${theme.paddings.lg} ${theme.paddings.base};
        font-size: ${theme.fonts.size.base};
        justify-content: flex-end;
        text-align: right;
        color: #444;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        b {
          padding-left: 0.5rem;
          font-weight: bold;
        }
      }
    `;
  }}
`;
const InputGroup = styled.div`
  height: 40px;
  flex: 1;
  display: flex;
  font-size: 1rem;
  button {
    background-color: #fff;
    border: solid 1px #ddd;
    width: 40px;
    height: 40px;
    :first-child {
      border-right: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    :last-child {
      border-left: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  input {
    flex: 1 1 auto;
    background-color: #fff;
    text-align: center;
    border: solid 1px #ddd;
  }
`;

function Modal({
  history,
  match,
  location,
  isOpen,
  toggleModal,
  id,
  name,
  isDiscount,
  discountPrice,
  price,
  limit,
  giftOrderProductCount,
  giftProductOptionsCount,
  giftProductOptions,
}) {
  const [productCount, setProductCount] = useState(1);
  const [productPrice, setProductPrice] = useState(price);

  // GiftOrder
  const [totalProductPrice, setTotalProductPrice] = useState(price);
  // const [totalDiscountPrice, setTotalDiscountPrice] = useState(discountPrice);
  // const [totalCount, setTotalCount] = useState(1);
  const [totalPrice, setTotalPrice] = useState(totalProductPrice);

  // GiftOrderProduct
  // isDiscount
  const giftProductOptionDetail1 = giftProductOptions[0]
    ? giftProductOptions[0].giftProductOptionDetails[0].id
    : null;
  const giftProductOptionDetailPrice1 = giftProductOptions[0]
    ? giftProductOptions[0].giftProductOptionDetails[0].price
    : 0;
  const giftProductOptionDetail2 = giftProductOptions[1]
    ? giftProductOptions[1].giftProductOptionDetails[0].id
    : null;
  const giftProductOptionDetailPrice2 = giftProductOptions[1]
    ? giftProductOptions[1].giftProductOptionDetails[0].price
    : 0;
  const giftProductOptionDetail3 = giftProductOptions[2]
    ? giftProductOptions[2].giftProductOptionDetails[0].id
    : null;
  const giftProductOptionDetailPrice3 = giftProductOptions[2]
    ? giftProductOptions[2].giftProductOptionDetails[0].price
    : 0;
  const giftProductOptionDetail4 = giftProductOptions[3]
    ? giftProductOptions[3].giftProductOptionDetails[0].id
    : null;
  const giftProductOptionDetailPrice4 = giftProductOptions[3]
    ? giftProductOptions[3].giftProductOptionDetails[0].price
    : 0;
  const giftProductOptionDetail5 = giftProductOptions[4]
    ? giftProductOptions[4].giftProductOptionDetails[0].id
    : null;
  const giftProductOptionDetailPrice5 = giftProductOptions[4]
    ? giftProductOptions[4].giftProductOptionDetails[0].price
    : 0;
  // price
  const [count, setCount] = useState(1);

  const minus = () => {
    if (count <= 1) return;
    setCount(count - 1);
    setTotalProductPrice((count - 1) * price);
    // setTotalDiscountPrice((count - 1) * discountPrice);

    setProductCount(count - 1);
    // setTotalCount(count - 1);
    setTotalPrice((count - 1) * price);
    totalSum({
      price,
      optionPrice1: giftProductOptionDetailPrice1,
      optionPrice2: giftProductOptionDetailPrice2,
      optionPrice3: giftProductOptionDetailPrice3,
      optionPrice4: giftProductOptionDetailPrice4,
      optionPrice5: giftProductOptionDetailPrice5,
      count: count - 1,
    });
  };
  const plus = () => {
    setCount(count + 1);
    setTotalProductPrice((count + 1) * price);
    // setTotalDiscountPrice((count + 1) * discountPrice);

    setProductCount(count + 1);
    // setTotalCount(count + 1);
    setTotalPrice((count + 1) * price);
    totalSum({
      price,
      optionPrice1: giftProductOptionDetailPrice1,
      optionPrice2: giftProductOptionDetailPrice2,
      optionPrice3: giftProductOptionDetailPrice3,
      optionPrice4: giftProductOptionDetailPrice4,
      optionPrice5: giftProductOptionDetailPrice5,
      count: count + 1,
    });
  };
  const totalSum = ({
    price,
    optionPrice1,
    optionPrice2,
    optionPrice3,
    optionPrice4,
    optionPrice5,
    count,
  }) => {
    const productPrice =
      price +
      optionPrice1 +
      optionPrice2 +
      optionPrice3 +
      optionPrice4 +
      optionPrice5;
    const totalPrice =
      (price +
        optionPrice1 +
        optionPrice2 +
        optionPrice3 +
        optionPrice4 +
        optionPrice5) *
      count;

    setProductPrice(productPrice);
    setTotalPrice(totalPrice);
  };

  const onSubmit = () => {
    if (giftProductOptionsCount > 0 && !giftProductOptionDetail1) {
      toast.error(`${giftProductOptions[0].name} 옵션을 선택해주세요.`);
      return;
    }
    if (giftProductOptionsCount > 1 && !giftProductOptionDetail2) {
      toast.error(`${giftProductOptions[1].name} 옵션을 선택해주세요.`);
      return;
    }
    if (giftProductOptionsCount > 2 && !giftProductOptionDetail3) {
      toast.error(`${giftProductOptions[2].name} 옵션을 선택해주세요.`);
      return;
    }
    if (giftProductOptionsCount > 3 && !giftProductOptionDetail4) {
      toast.error(`${giftProductOptions[3].name} 옵션을 선택해주세요.`);
      return;
    }
    if (giftProductOptionsCount > 4 && !giftProductOptionDetail5) {
      toast.error(`${giftProductOptions[4].name} 옵션을 선택해주세요.`);
      return;
    }

    if (limit - (giftOrderProductCount + productCount) < 0) {
      const orderAbleCount = limit - giftOrderProductCount;
      toast.error(`${orderAbleCount}매 이상 예매할 수 없습니다.`);
      return;
    }

    history.push(`/order`, {
      productType: "Ticket",
      orderType: "me",
      id,
      giftProductOptionDetail1,
      giftProductOptionDetail2,
      giftProductOptionDetail3,
      giftProductOptionDetail4,
      giftProductOptionDetail5,
      count,
    });
  };

  return (
    <Modals>
      <div className={isOpen ? "openModal modal" : "modal"}>
        {isOpen ? (
          <section>
            <header>
              <button onClick={toggleModal}>
                <FontAwesomeIcon icon={faChevronDown} />
              </button>
            </header>
            <main>
              <TicketDate
                limit={limit}
                giftOrderProductCount={giftOrderProductCount}
                giftProductOptions={giftProductOptions}
              />
              <SPayment>
                <InputGroup>
                  <button onClick={minus}>
                    <FontAwesomeIcon icon={faMinus} />
                  </button>
                  <input value={count} readOnly={true} />
                  <button onClick={plus}>
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </InputGroup>
                <div className="priceList">
                  <div className="flextext">
                    <div className="name">{name}</div>
                    <ul>
                      <li>
                        <div className="left">금액</div>
                        <div className="right">
                          <NumberFormat
                            value={productPrice}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix="드림"
                          />
                        </div>
                      </li>
                      <li>
                        <div className="left">수량</div>
                        <div className="right">
                          <NumberFormat
                            value={productCount}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix="매"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="price_total">
                    총금액
                    <b>
                      <NumberFormat
                        value={totalPrice}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </b>
                    드림
                  </div>
                </div>
              </SPayment>
            </main>
            <footer>
              <Button onClick={onSubmit} color="lightwarning" width="full">
                나에게 선물하기
              </Button>
            </footer>
          </section>
        ) : null}
      </div>
    </Modals>
  );
}

export default withRouter(Modal);
