import React from "react"
import { withRouter, Link } from "react-router-dom"

import styled, { css } from "styled-components"
import { Row, Col, SOrderItem, Dot } from "../../../../../styles/styles"
import SLabel from "../../../../Labels"
import ReactMoment from "react-moment"
import NumberFormat from "react-number-format"
import renderHTML from "react-render-html"
import ProductOrderProduct from "./ProductOrderProduct"
import { graphqlUrl } from "../../../../../Config/Env"

const SOrderDetail = styled(SOrderItem)`
  ${({ theme }) => {
    return css`
      background-color: ${theme.colors.lightgray};
      padding: ${theme.paddings.xl};
      border-radius: 0;
      .orderItem {
        background-color: white;
        border: solid 1px #eee;
        border-radius: 8px;
        .item_content {
          padding: 0 ${theme.paddings.base};
          .product_list {
            .img {
              border-radius: 8px;
            }
          }
        }
      }
      .item_top {
      }
      .cont {
        flex: 1 0 100%;
      }
    `
  }}
`
function Product({ history, match, location, data }) {
  const {
    GiftAccountOrderDetail: {
      receiver,
      giftProduct,

      isDiscount,
      discountPrice,
      price,

      giftProductOptionDetail1,
      giftProductOptionDetailPrice1,
      giftProductOptionDetail2,
      giftProductOptionDetailPrice2,
      giftProductOptionDetail3,
      giftProductOptionDetailPrice3,
      giftProductOptionDetail4,
      giftProductOptionDetailPrice4,
      giftProductOptionDetail5,
      giftProductOptionDetailPrice5,

      count,

      createdAt,
    },
  } = data

  let productPrice = isDiscount ? price - discountPrice : price
  productPrice += giftProductOptionDetailPrice1
  productPrice += giftProductOptionDetailPrice2
  productPrice += giftProductOptionDetailPrice3
  productPrice += giftProductOptionDetailPrice4
  productPrice += giftProductOptionDetailPrice5

  const Uri =
    (giftProduct.productType === "Product" && `/products/${giftProduct.id}`) ||
    (giftProduct.productType === "Ticket" && `/tickets/${giftProduct.id}`)
  const CoverImage =
    giftProduct.coverImage === null
      ? "../../assets/img/product/product-default.jpg"
      : `${graphqlUrl}${giftProduct.coverImage}`
  let OptionString = ""
  if (giftProductOptionDetail1) {
    OptionString += ` ${giftProductOptionDetail1.name}(${
      giftProductOptionDetail1.price > 0 ? "+" : ""
    }${giftProductOptionDetail1.price.toLocaleString("ko-KR", {
      maximumFractionDigits: 4,
    })}드림)`
    if (giftProductOptionDetail2) {
      OptionString += ` / `
    }
  }
  if (giftProductOptionDetail2) {
    OptionString += ` ${giftProductOptionDetail2.name}(${
      giftProductOptionDetail2.price > 0 ? "+" : ""
    }${giftProductOptionDetail2.price.toLocaleString("ko-KR", {
      maximumFractionDigits: 4,
    })}드림)`
    if (giftProductOptionDetail3) {
      OptionString += ` / `
    }
  }
  if (giftProductOptionDetail3) {
    OptionString += ` ${giftProductOptionDetail3.name}(${
      giftProductOptionDetail3.price > 0 ? "+" : ""
    }${giftProductOptionDetail3.price.toLocaleString("ko-KR", {
      maximumFractionDigits: 4,
    })}드림)`
    if (giftProductOptionDetail4) {
      OptionString += ` / `
    }
  }
  if (giftProductOptionDetail4) {
    OptionString += ` ${giftProductOptionDetail4.name}(${
      giftProductOptionDetail4.price > 0 ? "+" : ""
    }${giftProductOptionDetail4.price.toLocaleString("ko-KR", {
      maximumFractionDigits: 4,
    })}드림)`
    if (giftProductOptionDetail5) {
      OptionString += ` / `
    }
  }
  if (giftProductOptionDetail5) {
    OptionString += ` ${giftProductOptionDetail5.name}(${
      giftProductOptionDetail5.price > 0 ? "+" : ""
    }${giftProductOptionDetail5.price.toLocaleString("ko-KR", {
      maximumFractionDigits: 4,
    })}드림)`
  }

  return (
    <SOrderDetail>
      <div className="orderItem">
        <Row className="item_top">
          <Col left className="left">
            <span className="date">
              구매일자 :
              <ReactMoment format="YYYY. MM. DD HH:mm">{createdAt}</ReactMoment>
            </span>
          </Col>
        </Row>
        <div className="item_content">
          <div className="product_list">
            <Link to={Uri}>
              <div className="img">
                <img src={CoverImage} alt={giftProduct.name} />
              </div>
              <div className="cont">
                <span className="category">{giftProduct.store.name}</span>
                <strong>{giftProduct.name}</strong>
                <div className="option">
                  {OptionString !== "" && <>추가옵션 : {OptionString}</>}
                </div>
              </div>
            </Link>
          </div>
        </div>
        <Row className="item_bottom">
          <Col left className="left">
            <span>
              To.
              {receiver[0].name}님
              {renderHTML(
                receiver.length > 1 ? `외 <b>${receiver.length - 1}</b>명` : ``
              )}
            </span>
          </Col>
          <Col right className="right">
            <span>
              <b>
                <NumberFormat
                  value={productPrice}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </b>
              드림
            </span>
            <Dot />
            <span>
              <b>{count}</b>개
            </span>
          </Col>
        </Row>
      </div>
      <ProductOrderProduct data={data} />
    </SOrderDetail>
  )
}

export default withRouter(Product)
