import { gql } from "@apollo/client";

// 로그인
export const LOGIN_MUTATION = gql`
  mutation GiftAuthSignIn($userId: String!, $password: String!) {
    GiftAuthSignIn(userId: $userId, password: $password)
  }
`;

// 메인
export const MAIN_QUERY = gql`
  query GiftMain {
    GiftMain {
      giftBanners {
        id
        coverImage
        title
      }
      giftCategories {
        id
        iconImage
        name
        giftProductsCount
      }
      giftProducts {
        id
        giftCategory {
          id
          name
          parentCategory {
            id
            name
          }
        }
        store {
          id
          name
        }
        commonStatus
        productType
        coverImage
        isSoldOut
        isDiscount
        discountPrice
        price
        name
        contents
        createdAt
        updatedAt
      }
      stores {
        id
        commonStatus
        storeCategory {
          id
        }
        storeLogo
        name

        createdAt
        updatedAt
      }
      # giftTickets {
      #   id
      #   giftCategory {
      #     id
      #     name
      #     parentCategory {
      #       id
      #       name
      #     }
      #   }
      #   store {
      #     id
      #     name
      #   }
      #   commonStatus
      #   productType
      #   coverImage
      #   isSoldOut
      #   isDiscount
      #   discountPrice
      #   price
      #   name
      #   contents

      #   limit
      #   giftOrderProductCount
      #   schedule
      #   place

      #   createdAt
      #   updatedAt
      # }
    }
  }
`;

// 검색 > 초기화면 > 분류
export const CATEGORIES_QUERY = gql`
  query GiftProductCategoryList($productType: String!, $parentId: String) {
    GiftProductCategoryList(productType: $productType, parentId: $parentId) {
      giftCategories {
        id
        commonStatus
        iconImage
        name
        parentId
        order
        createdAt
        updatedAt

        giftProductsCount
        childrenCount
        children {
          id
          commonStatus
          iconImage
          name
          parentId
          order
          createdAt
          updatedAt

          giftProductsCount
          childrenCount
        }
      }
      count
    }
  }
`;

// 상품 > 목록
export const PRODUCTS_QUERY = gql`
  query GiftProductList(
    $productType: String
    $parentGiftCategory: String
    $giftCategory: String
    $priceStart: String
    $priceEnd: String
    $keyword: String
    $orderBy: String
    $skip: Int!
    $first: Int!
  ) {
    GiftProductList(
      productType: $productType
      parentGiftCategory: $parentGiftCategory
      giftCategory: $giftCategory
      priceStart: $priceStart
      priceEnd: $priceEnd
      keyword: $keyword
      orderBy: $orderBy
      skip: $skip
      first: $first
    ) {
      giftProducts {
        id
        giftCategory {
          id
          name
          parentCategory {
            id
            name
          }
        }
        store {
          id
          storeLogo
          name
        }
        commonStatus
        productType
        coverImage
        isSoldOut
        isDiscount
        discountPrice
        price
        name
        contents

        limit
        giftOrderProductCount
        schedule
        place

        createdAt
        updatedAt
      }
      count
    }
  }
`;

// 상품 > 상세정보
export const PRODUCT_QUERY = gql`
  query GiftProductDetail($productType: String!, $id: String!) {
    GiftProductDetail(productType: $productType, id: $id) {
      id
      giftCategory {
        id
        name
        parentCategory {
          id
          name
        }
      }
      store {
        id
        storeLogo
        name
      }
      commonStatus
      productType
      coverImage
      isSoldOut
      isDiscount
      discountPrice
      price
      name
      contents
      createdAt
      updatedAt

      limit
      giftOrderProductCount
      schedule
      place

      giftProductReviews {
        id
        giftProduct {
          id
        }
        giftOrderProduct {
          id
          giftProduct {
            id
            store {
              id
              storeLogo
              name
            }
          }
        }
        user {
          id
          avatar
          name
        }
        commonStatus
        isSecret
        score
        contents
        comment
        createdAt
        updatedAt

        isMe
      }
      giftProductReviewsCount
      giftProductInquiries {
        id
        giftProduct {
          id
        }
        user {
          id
          avatar
          name
        }
        commonStatus
        isSecret
        question
        answer
        createdAt
        updatedAt

        isMe
      }
      giftProductInquiriesCount
      giftProductOptions {
        id
        commonStatus
        name
        giftProductOptionDetails {
          id
          commonStatus
          isSoldOut
          name
          price
        }
      }
      giftProductOptionsCount
      giftProductOptionDetailsCount
    }
  }
`;

// 계정 > 위시리스트 > 상세정보 > 위시리스트
// 상품 > 상세정보 > 위시리스트
// 티켓 > 상세정보 > 위시리스트
export const TOGGLEWISHLIST_MUTATION = gql`
  mutation GiftProductToggleWishlist($id: String!) {
    GiftProductToggleWishlist(id: $id)
  }
`;

// 계정 > 위시리스트 > 상세정보 > 위시리스트 유무
// 상품 > 상세정보 > 위시리스트 유무
// 티켓 > 상세정보 > 위시리스트 유무
export const ISWISHLIST_QUERY = gql`
  query GiftProductIsWishlist($id: String!) {
    GiftProductIsWishlist(id: $id)
  }
`;

// 상품 > 상세정보 > 쪼르기
export const PRODUCTORDER_PUSH_MUTATION = gql`
  mutation GiftProductOrderPush(
    $users: [String!]
    $id: String!
    $message: String!
  ) {
    GiftProductOrderPush(users: $users, id: $id, message: $message)
  }
`;

// 상품 > 상세정보 > 선물후기 > 목록
// 티켓 > 상세정보 > 선물후기 > 목록
export const REVIEWS_QUERY = gql`
  query GiftProductReviewList(
    $giftProduct: String!
    $orderBy: String
    $skip: Int!
    $first: Int!
  ) {
    GiftProductReviewList(
      giftProduct: $giftProduct
      orderBy: $orderBy
      skip: $skip
      first: $first
    ) {
      giftProductReviews {
        id
        giftProduct {
          id
        }
        giftOrderProduct {
          id
          giftProduct {
            id
            store {
              id
              storeLogo
              name
            }
          }
        }
        user {
          id
          avatar
          name
        }
        commonStatus
        isSecret
        score
        contents
        createdAt
        updatedAt

        isMe
      }
      count
    }
  }
`;

// 상품 > 상세정보 > 선물문의 > 목록
// 티켓 > 상세정보 > 선물문의 > 목록
export const INQUIRIES_QUERY = gql`
  query GiftProductInquiryList(
    $giftProduct: String!
    $orderBy: String
    $skip: Int!
    $first: Int!
  ) {
    GiftProductInquiryList(
      giftProduct: $giftProduct
      orderBy: $orderBy
      skip: $skip
      first: $first
    ) {
      giftProductInquiries {
        id
        giftProduct {
          id
        }
        user {
          id
          avatar
          name
        }
        commonStatus
        isSecret
        question
        answer
        createdAt
        updatedAt

        isMe
      }
      count
    }
  }
`;

// 상품 > 상세정보 > 선물문의 > 작성
// 티켓 > 상세정보 > 선물문의 > 작성
// 계정 > 선물문의 > 작성
export const INQUIRYWRITE_MUTATION = gql`
  mutation GiftProductInquiryWrite(
    $giftProduct: String!
    $isSecret: Boolean
    $question: String!
  ) {
    GiftProductInquiryWrite(
      giftProduct: $giftProduct
      isSecret: $isSecret
      question: $question
    )
  }
`;

// 상품 > 상세정보 > 선물문의 > 상세정보
// 티켓 > 상세정보 > 선물문의 > 상세정보
// 계정 > 선물문의 > 상세정보
export const INQUIRY_QUERY = gql`
  query GiftProductInquiryDetail($id: String!) {
    GiftProductInquiryDetail(id: $id) {
      id
      giftProduct {
        id
      }
      commonStatus
      isSecret
      question
      answer
      createdAt
      updatedAt
    }
  }
`;

// 상품 > 상세정보 > 선물문의 > 수정
// 티켓 > 상세정보 > 선물문의 > 수정
// 계정 > 선물문의 > 수정
export const INQUIRYEDIT_MUTATION = gql`
  mutation GiftProductInquiryEdit(
    $id: String!
    $isSecret: Boolean
    $question: String!
  ) {
    GiftProductInquiryEdit(id: $id, isSecret: $isSecret, question: $question)
  }
`;

// 상품 > 상세정보 > 선물문의 > 삭제
// 티켓 > 상세정보 > 선물문의 > 삭제
// 계정 > 선물문의 > 삭제
export const INQUIRYDELETE_MUTATION = gql`
  mutation GiftProductInquiryDelete($id: String!) {
    GiftProductInquiryDelete(id: $id)
  }
`;

// 상품 > 선물하기 > 초기화면
// 티켓 > 선물하기 > 초기화면
export const ORDERINIT_QUERY = gql`
  query GiftOrderInit($id: String!) {
    GiftOrderInit(id: $id) {
      giftProduct {
        id
        giftCategory {
          id
          name
        }
        store {
          id
          name
        }
        commonStatus
        productType
        coverImage
        isSoldOut
        isDiscount
        discountPrice
        price
        name
        contents
        createdAt
        updatedAt

        giftProductOptionsCount
        giftProductOptions {
          id
          commonStatus
          name
          giftProductOptionDetails {
            id
            commonStatus
            isSoldOut
            name
            price
          }
        }
      }
      account {
        id
        avatar
        userId
        name
        trustlines {
          id
          commonStatus
          currencyType
          currency
          amount
          createdAt
          updatedAt
        }

        isCms
        cmsValue
        cmsDay
      }
    }
  }
`;

// 상품 > 선물하기 > 처리
// 티켓 > 선물하기 > 처리
export const ORDERPROCESSING_MUTATION = gql`
  mutation GiftOrderProcessing(
    $authPassword: String!
    $orderType: String!
    $users: [String!]
    $id: String!
    $giftProductOptionDetail1: String
    $giftProductOptionDetail2: String
    $giftProductOptionDetail3: String
    $giftProductOptionDetail4: String
    $giftProductOptionDetail5: String
    $count: Int!
    $message: String!
  ) {
    GiftOrderProcessing(
      authPassword: $authPassword
      orderType: $orderType
      users: $users
      id: $id
      giftProductOptionDetail1: $giftProductOptionDetail1
      giftProductOptionDetail2: $giftProductOptionDetail2
      giftProductOptionDetail3: $giftProductOptionDetail3
      giftProductOptionDetail4: $giftProductOptionDetail4
      giftProductOptionDetail5: $giftProductOptionDetail5
      count: $count
      message: $message
    )
  }
`;

// 가게 >  분류
export const STORES_CATEGORIES_QUERY = gql`
  query GiftStoreCategoryList {
    GiftStoreCategoryList {
      storeCategories {
        id
        name
        storesCount
      }
      count
    }
  }
`;

// 가게 > 목록
export const STORES_QUERY = gql`
  query GiftStoreList(
    $storeCategory: String
    $keyword: String
    $orderBy: String
    $skip: Int!
    $first: Int!
  ) {
    GiftStoreList(
      storeCategory: $storeCategory
      keyword: $keyword
      orderBy: $orderBy
      skip: $skip
      first: $first
    ) {
      stores {
        id
        commonStatus
        storeCategory {
          id
        }
        storeLogo
        name

        createdAt
        updatedAt
      }
      count
    }
  }
`;

// 가게 > 상세정보
export const STORE_QUERY = gql`
  query GiftStoreDetail($id: String!, $orderBy: String!) {
    GiftStoreDetail(id: $id, orderBy: $orderBy) {
      id
      commonStatus
      storeCategory {
        id
        name
      }
      storeLogo
      name

      createdAt
      updatedAt

      giftProducts {
        id
        giftCategory {
          id
          name
          parentCategory {
            id
            name
          }
        }
        store {
          id
          name
        }
        commonStatus
        productType
        coverImage
        isSoldOut
        isDiscount
        discountPrice
        price
        name
        contents
        createdAt
        updatedAt
      }
    }
  }
`;

// 계정
export const ACCOUNT_QUERY = gql`
  query GiftAccountUser {
    GiftAccountUser {
      user {
        avatar
        userId
        name
        email
        phone
        birthDate
        zipcode
        roadAddress
        jibunAddress
        addressDetail
        latitude
        longitude

        role
        trustlines {
          id
          commonStatus
          currencyType
          currency
          amount
          createdAt
          updatedAt
        }
        wallet {
          id
        }
        store {
          id
          storeLogo
          name
          storeCEO
          storeNumber
          storeZipcode
          storeRoadAddress
          storeJibunAddress
          storeAddressDetail
          storeCategory {
            id
            name
          }
        }
        visitedAt
        createdAt
        updatedAt

        giftOrderProductCount
      }
      giftOrderProductsCount
      giftOrdersCount
      giftWishlistsCount
    }
  }
`;

// 계정 > 받은선물함 > 사용가능 목록
export const ACCOUNT_READYORDERPRODUCTS_QUERY = gql`
  query GiftAccountOrderProductReadyList(
    $orderBy: String
    $skip: Int
    $first: Int
  ) {
    GiftAccountOrderProductReadyList(
      orderBy: $orderBy
      skip: $skip
      first: $first
    ) {
      giftOrderProducts {
        id
        commonStatus
        user {
          id
          avatar
          name
        }
        sender {
          id
          avatar
          name
        }
        giftOrder {
          id
          user {
            id
            avatar
            name
          }
        }
        giftProduct {
          id
          giftCategory {
            id
            name
          }
          store {
            id
            storeLogo
            name
          }
          commonStatus
          productType
          coverImage
          isSoldOut
          isDiscount
          discountPrice
          price
          name
          contents
          createdAt
          updatedAt
        }

        giftProductOptionDetail1 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        giftProductOptionDetail2 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        giftProductOptionDetail3 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        giftProductOptionDetail4 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        giftProductOptionDetail5 {
          id
          commonStatus
          isSoldOut
          name
          price
        }

        startDate
        endDate
        extensionCount

        createdAt
        updatedAt
      }
      count
    }
  }
`;

// 계정 > 받은선물함 > 읽기 처리
export const ACCOUNT_ORDERPRODUCT_ISREADSEDIT_MUTATION = gql`
  mutation GiftOrderProductIsReadsEdit($isRead: Boolean) {
    GiftOrderProductIsReadsEdit(isRead: $isRead)
  }
`;

// 계정 > 받은선물함 > 사용완료 목록
export const ACCOUNT_COMPLETEORDERPRODUCTS_QUERY = gql`
  query GiftAccountOrderProductCompleteList(
    $orderBy: String
    $skip: Int
    $first: Int
  ) {
    GiftAccountOrderProductCompleteList(
      orderBy: $orderBy
      skip: $skip
      first: $first
    ) {
      giftOrderProducts {
        id
        commonStatus
        user {
          id
          avatar
          name
        }
        sender {
          id
          avatar
          name
        }
        giftOrder {
          id
          user {
            id
            avatar
            name
          }
        }
        giftProduct {
          id
          giftCategory {
            id
            name
          }
          store {
            id
            storeLogo
            name
          }
          commonStatus
          productType
          coverImage
          isSoldOut
          isDiscount
          discountPrice
          price
          name
          contents
          createdAt
          updatedAt
        }

        giftProductOptionDetail1 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        giftProductOptionDetail2 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        giftProductOptionDetail3 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        giftProductOptionDetail4 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        giftProductOptionDetail5 {
          id
          commonStatus
          isSoldOut
          name
          price
        }

        isRefund

        usedAt
        createdAt
        updatedAt
      }
      count
    }
  }
`;

// 계정 > 받은선물함 > 상세정보
export const ACCOUNT_ORDERPRODUCT_QUERY = gql`
  query GiftAccountOrderProductDetail($id: String!) {
    GiftAccountOrderProductDetail(id: $id) {
      id
      commonStatus
      user {
        id
        avatar
        name
      }
      giftOrder {
        id
        commonStatus
        user {
          id
          avatar
          name
        }
        receiver {
          id
          avatar
          name
        }
        message
      }
      giftProduct {
        id
        giftCategory {
          id
          name
          parentCategory {
            id
            name
          }
        }
        store {
          id
          storeLogo
          name
        }
        commonStatus
        productType
        coverImage
        isSoldOut
        isDiscount
        discountPrice
        price
        name
        contents

        limit
        giftOrderProductCount
        schedule
        place

        createdAt
        updatedAt

        giftProductOptions {
          id
          commonStatus
          name
          giftProductOptionDetails {
            id
            commonStatus
            isSoldOut
            name
            price
          }
        }
        giftProductOptionsCount
      }

      giftProductOptionDetail1 {
        id
        commonStatus
        isSoldOut
        name
        price
      }
      giftProductOptionDetail2 {
        id
        commonStatus
        isSoldOut
        name
        price
      }
      giftProductOptionDetail3 {
        id
        commonStatus
        isSoldOut
        name
        price
      }
      giftProductOptionDetail4 {
        id
        commonStatus
        isSoldOut
        name
        price
      }
      giftProductOptionDetail5 {
        id
        commonStatus
        isSoldOut
        name
        price
      }

      startDate
      endDate
      extensionCount

      isRefund

      cashier {
        id
        userId
        name
      }

      usedAt
      createdAt
      updatedAt

      isMe
    }
  }
`;

// 계정 > 받은선물함 > 상세정보 상품 연장
export const ACCOUNT_ORDERPRODUCTEXTENSION_MUTATION = gql`
  mutation GiftAccountOrderProductExtension($id: String!) {
    GiftAccountOrderProductExtension(id: $id)
  }
`;

// 계정 > 받은선물함 > 상세정보 상품 환불
export const ACCOUNT_ORDERPRODUCTREFUND_MUTATION = gql`
  mutation GiftAccountOrderProductRefund($id: String!) {
    GiftAccountOrderProductRefund(id: $id)
  }
`;

// 계정 > 받은선물함 > 상세정보 상품 거부
export const ACCOUNT_ORDERPRODUCTREJECT_MUTATION = gql`
  mutation GiftAccountOrderProductReject($id: String!) {
    GiftAccountOrderProductReject(id: $id)
  }
`;

// 계정 > 받은선물함 > 상세정보 티켓 환불
export const ACCOUNT_ORDERPRODUCTTICKETREFUND_MUTATION = gql`
  mutation GiftAccountOrderProductTicketRefund($id: String!) {
    GiftAccountOrderProductTicketRefund(id: $id)
  }
`;

// 계정 > 받은선물함 > 상세정보 > 보내기
export const ACCOUNT_ORDERPRODUCTSEND_MUTATION = gql`
  mutation GiftAccountOrderProductSend($id: String!, $user: String!) {
    GiftAccountOrderProductSend(id: $id, user: $user)
  }
`;

// 계정 > 받은선물함 > 상세정보 > 보내기 > 수신자 검색
// 상품 > 선물하기 > 수신자 검색
export const NAME_BY_USERS_MUTATION = gql`
  mutation GiftProductUserSeach($keyword: String, $skip: Int, $first: Int) {
    GiftProductUserSeach(
      keyword: $keyword
      orderBy: "name_DESC"
      skip: $skip
      first: $first
    ) {
      users {
        id
        role
        avatar
        userId
        name
        store {
          id
          name
        }
      }
      count
    }
  }
`;

// 계정 > 구매내역 > 목록
export const ACCOUNT_ORDERS_QUERY = gql`
  query GiftAccountOrderList($orderBy: String, $skip: Int, $first: Int) {
    GiftAccountOrderList(orderBy: $orderBy, skip: $skip, first: $first) {
      giftOrders {
        id
        commonStatus
        receiver {
          id
          avatar
          name
        }
        giftProduct {
          id
          giftCategory {
            id
            name
          }
          store {
            id
            storeLogo
            name
          }
          commonStatus
          productType
          coverImage
          isSoldOut
          isDiscount
          discountPrice
          price
          name
          contents
          createdAt
          updatedAt
        }

        isDiscount
        discountPrice
        price

        giftProductOptionDetail1 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        giftProductOptionDetailPrice1
        giftProductOptionDetail2 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        giftProductOptionDetailPrice2
        giftProductOptionDetail3 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        giftProductOptionDetailPrice3
        giftProductOptionDetail4 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        giftProductOptionDetailPrice4
        giftProductOptionDetail5 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        giftProductOptionDetailPrice5

        count

        totalCount
        totalPrice

        createdAt
        updatedAt
      }
      count
    }
  }
`;

// 계정 > 구매내역 > 상세정보
export const ACCOUNT_ORDER_QUERY = gql`
  query GiftAccountOrderDetail($id: String!) {
    GiftAccountOrderDetail(id: $id) {
      id
      giftOrderProducts {
        id
        commonStatus
        user {
          id
          avatar
          userId
          name
        }
        startDate
        endDate
        isRefund
        meRefund
      }
      commonStatus
      user {
        id
        avatar
        name
      }
      receiver {
        id
        avatar
        name
      }
      giftProduct {
        id
        giftCategory {
          id
          name
        }
        store {
          id
          storeLogo
          name
        }
        commonStatus
        productType
        coverImage
        isSoldOut
        isDiscount
        discountPrice
        price
        name
        contents
        createdAt
        updatedAt

        giftProductOptionsCount
      }

      isDiscount
      discountPrice
      price

      giftProductOptionDetail1 {
        id
        commonStatus
        isSoldOut
        name
        price
      }
      giftProductOptionDetailPrice1
      giftProductOptionDetail2 {
        id
        commonStatus
        isSoldOut
        name
        price
      }
      giftProductOptionDetailPrice2
      giftProductOptionDetail3 {
        id
        commonStatus
        isSoldOut
        name
        price
      }
      giftProductOptionDetailPrice3
      giftProductOptionDetail4 {
        id
        commonStatus
        isSoldOut
        name
        price
      }
      giftProductOptionDetailPrice4
      giftProductOptionDetail5 {
        id
        commonStatus
        isSoldOut
        name
        price
      }
      giftProductOptionDetailPrice5

      count
      totalCount
      totalPrice

      message
      isRefund

      refundReceiver {
        id
      }
      refundTotalCount
      refundTotalPrice

      createdAt
      updatedAt
    }
  }
`;

// 계정 > 구매내역 > 상세정보 > 환불하기
export const ACCOUNT_ORDERREFUND_MUTATION = gql`
  mutation GiftAccountOrderRefund(
    $id: String!
    $giftOrderProductIds: [String!]!
  ) {
    GiftAccountOrderRefund(id: $id, giftOrderProductIds: $giftOrderProductIds)
  }
`;

// 계정 > 위시리스트 > 목록
export const ACCOUNT_WISHLISTS_QUERY = gql`
  query GiftAccountWishlistList($orderBy: String, $skip: Int!, $first: Int!) {
    GiftAccountWishlistList(orderBy: $orderBy, skip: $skip, first: $first) {
      giftWishlists {
        id
        giftProduct {
          id
          giftCategory {
            id
            name
          }
          store {
            id
            storeLogo
            name
          }
          commonStatus
          productType
          coverImage
          isSoldOut
          isDiscount
          discountPrice
          price
          name
          contents
          createdAt
          updatedAt
        }
        user {
          id
          avatar
          name
        }
        createdAt
        updatedAt
      }
      count
    }
  }
`;

// 계정 > 선물후기 > 작성가능한 상품 목록
export const ACCOUNT_READYREVIEWS_QUERY = gql`
  query GiftAccountReviewReadyList(
    $orderBy: String
    $skip: Int!
    $first: Int!
  ) {
    GiftAccountReviewReadyList(orderBy: $orderBy, skip: $skip, first: $first) {
      giftOrderProducts {
        id
        commonStatus
        user {
          id
          avatar
          name
        }
        # giftOrder {
        #   id
        # }
        giftProduct {
          id
          giftCategory {
            id
            name
            parentCategory {
              id
              name
            }
          }
          store {
            id
            storeLogo
            name
          }
          commonStatus
          productType
          coverImage
          isSoldOut
          isDiscount
          discountPrice
          price
          name
          contents
          createdAt
          updatedAt

          giftProductOptions {
            id
            commonStatus
            name
            giftProductOptionDetails {
              id
              commonStatus
              isSoldOut
              name
              price
            }
          }
          giftProductOptionsCount
        }
        giftProductReviews {
          id
          commonStatus
          isSecret
          score
          contents
          createdAt
          updatedAt
        }
        isDiscount
        discountPrice
        price

        giftProductOptionDetail1 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        giftProductOptionDetail2 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        giftProductOptionDetail3 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        giftProductOptionDetail4 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        giftProductOptionDetail5 {
          id
          commonStatus
          isSoldOut
          name
          price
        }

        totalPrice
        startDate
        endDate
        extensionCount

        createdAt
        updatedAt
      }
      count
    }
  }
`;

// 계정 > 선물후기 > 작성가능한 상품 작성
export const ACCOUNT_REVIEWWRITE_MUTATION = gql`
  mutation GiftAccountReviewWrite(
    $giftOrderProduct: String!
    $score: Int!
    $contents: String!
  ) {
    GiftAccountReviewWrite(
      giftOrderProduct: $giftOrderProduct
      score: $score
      contents: $contents
    )
  }
`;

// 계정 > 선물후기 > 작성완료한 상품후기 목록
export const ACCOUNT_COMPLETEREVIEWS_QUERY = gql`
  query GiftAccountReviewCompleteList(
    $orderBy: String
    $skip: Int!
    $first: Int!
  ) {
    GiftAccountReviewCompleteList(
      orderBy: $orderBy
      skip: $skip
      first: $first
    ) {
      giftProductReviews {
        id
        giftOrderProduct {
          id
          commonStatus
          user {
            id
            avatar
            name
          }
          giftProduct {
            id
            giftCategory {
              id
              name
              parentCategory {
                id
                name
              }
            }
            store {
              id
              storeLogo
              name
            }
            commonStatus
            productType
            coverImage
            isSoldOut
            isDiscount
            discountPrice
            price
            name
            contents
            createdAt
            updatedAt
          }
          giftProductOptionDetail1 {
            id
            commonStatus
            isSoldOut
            name
            price
          }
          giftProductOptionDetail2 {
            id
            commonStatus
            isSoldOut
            name
            price
          }
          giftProductOptionDetail3 {
            id
            commonStatus
            isSoldOut
            name
            price
          }
          giftProductOptionDetail4 {
            id
            commonStatus
            isSoldOut
            name
            price
          }
          giftProductOptionDetail5 {
            id
            commonStatus
            isSoldOut
            name
            price
          }
        }
        user {
          id
          avatar
          name
        }
        commonStatus
        isSecret
        score
        contents

        isComment
        comment
        commentDate

        createdAt
        updatedAt

        isMe
      }
      count
    }
  }
`;

// 계정 > 선물후기 > 작성완료한 상품후기 상세정보
export const ACCOUNT_REVIEW_QUERY = gql`
  query GiftAccountReviewDetail($id: String!) {
    GiftAccountReviewDetail(id: $id) {
      id
      giftProduct {
        id
      }
      commonStatus
      isSecret
      score
      contents
      createdAt
      updatedAt
    }
  }
`;

// 계정 > 선물후기 > 작성완료한 상품 후기수정
export const ACCOUNT_REVIEWEDIT_MUTATION = gql`
  mutation GiftAccountReviewEdit(
    $id: String!
    $score: Int!
    $contents: String!
  ) {
    GiftAccountReviewEdit(id: $id, score: $score, contents: $contents)
  }
`;

// 계정 > 선물후기 > 작성완료한 상품 후기삭제
export const ACCOUNT_REVIEWDELETE_MUTATION = gql`
  mutation GiftAccountReviewDelete($id: String!) {
    GiftAccountReviewDelete(id: $id)
  }
`;

// 계정 > 선물문의 > 목록
export const ACCOUNT_INQUIRIES_QUERY = gql`
  query GiftAccountInquiryList($orderBy: String, $skip: Int!, $first: Int!) {
    GiftAccountInquiryList(orderBy: $orderBy, skip: $skip, first: $first) {
      giftProductInquiries {
        id
        giftProduct {
          id
          giftCategory {
            id
            name
            parentCategory {
              id
              name
            }
          }
          store {
            id
            storeLogo
            name
          }
          commonStatus
          productType
          coverImage
          isSoldOut
          isDiscount
          discountPrice
          price
          name
          contents
          createdAt
          updatedAt

          giftProductOptions {
            id
            commonStatus
            name
            giftProductOptionDetails {
              id
              commonStatus
              isSoldOut
              name
              price
            }
          }
          giftProductOptionsCount
        }
        user {
          id
          avatar
          name
        }
        commonStatus
        isSecret
        question
        answer
        createdAt
        updatedAt

        isMe
      }
      count
    }
  }
`;

// 공지사항 > 목록
export const NOTICES_QUERY = gql`
  query GiftNoticeList($orderBy: String, $skip: Int!, $first: Int!) {
    GiftNoticeList(orderBy: $orderBy, skip: $skip, first: $first) {
      notices {
        id
        title
        contents
        readCount
        createdAt
        updatedAt
      }
      count
    }
  }
`;
