import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { PRODUCTS_QUERY } from "../../../Config/Queries";

import Presenter from "./Presenter";
import qs from "qs";

function Container({ history, match, location }) {
  const orderByOption = [
    {
      value: "id_DESC",
      label: "최신순",
    },
    {
      value: "orderCount_DESC",
      label: "인기순",
    },
    {
      value: "totalPrice_ASC",
      label: "낮은 가격순",
    },
    {
      value: "totalPrice_DESC",
      label: "높은 가격순",
    },
  ];

  const queryString = qs.parse(location.search.substr(1));
  const depth1 = queryString.depth1 ? queryString.depth1 : null;
  const depth2 = queryString.depth2 ? queryString.depth2 : null;
  const orderBy = queryString.orderBy
    ? orderByOption.find((find) => find.value === queryString.orderBy)
      ? queryString.orderBy
      : "id_DESC"
    : "id_DESC";
  const page = queryString.page ? queryString.page : 1;
  const blockSize = 5;
  const first = 12;
  const skip = first * (page - 1);

  const baseUrl =
    "?" +
    (depth1 ? "depth1=" + depth1 + "&" : "") +
    (depth2 ? "depth2=" + depth2 + "&" : "") +
    (orderBy ? "orderBy=" + orderBy + "&" : "");

  const { data, loading } = useQuery(PRODUCTS_QUERY, {
    variables: {
      productType: "Ticket",
      parentGiftCategory: depth1,
      giftCategory: depth2,
      orderBy,
      skip,
      first,
    },
  });
  const [orderByDefault, setOrderByDefault] = useState({
    value: "id_DESC",
    label: "최신순",
  });
  const orderByChange = (item) => {
    setOrderByDefault({
      value: item.value,
      label: item.label,
    });

    const url =
      "?" +
      (depth1 ? "depth1=" + depth1 + "&" : "") +
      (depth2 ? "depth2=" + depth2 + "&" : "") +
      (item.value ? "orderBy=" + item.value + "&" : "");
    history.push(`/tickets${url}`);
  };

  useEffect(() => {
    const orderByValue = orderByOption.find((find) => find.value === orderBy);
    if (orderByValue) {
      setOrderByDefault(orderByValue);
    } else {
      setOrderByDefault({
        value: "id_DESC",
        label: "최신순",
      });
    }
  }, [orderBy]);

  return (
    <Presenter
      loading={loading}
      data={data}
      page={page}
      orderByOption={orderByOption}
      orderByDefault={orderByDefault}
      orderByChange={orderByChange}
      blockSize={blockSize}
      first={first}
      baseUrl={baseUrl}
    />
  );
}

export default withRouter(Container);
