import React from "react";

import { Ready } from "../../../styles/styles";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";
import { graphqlUrl } from "../../../Config/Env";

const SwiperSlideImg = styled.div`
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    :before {
        content: "";
        display: block;
        padding-top: 85%;
    }
    :after {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        content: "";
    }
    img {
        max-width: 100%;
        display: block;
        width: 100%;
        height: auto;
        &.sr-only {
            position: absolute;
            width: 1px;
            height: 1px;
            padding: 0;
            margin: -1px;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            border: 0;
        }
    }
`;

SwiperCore.use([Pagination, Navigation]);
const SlideImgReady = styled.div`
    position: relative;
    height: 400px;
    border-radius: 5px;
    border: solid 1px #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: #999;
`;

function Banner({ giftBanners }) {
    return (
        <div className="swiper-banner">
            <Swiper
                pagination={{
                    type: "fraction",
                }}
                navigation={false}
                className="mySwiper"
            >
                {giftBanners ? (
                    <>
                        {giftBanners.map((item, index) => {
                            const CoverImage =
                                item.coverImage === ""
                                    ? "/assets/img/user/user-default.jpg"
                                    : `${graphqlUrl}${item.coverImage}`;
                            return (
                                <SwiperSlide key={index}>
                                    <SwiperSlideImg
                                        style={{
                                            backgroundImage:
                                                "url(" + CoverImage + ")",
                                        }}
                                    >
                                        <img
                                            src={CoverImage}
                                            alt={item.title}
                                            className="sr-only"
                                        />
                                    </SwiperSlideImg>
                                </SwiperSlide>
                            );
                        })}
                    </>
                ) : (
                    <SlideImgReady>
                        <div>등록된 배너가 없습니다.</div>
                    </SlideImgReady>
                )}
            </Swiper>
        </div>
    );
}

export default Banner;
