import styled, { css } from "styled-components";
import { withRouter } from "react-router-dom";
import { Roundbox, Col } from "../../../../styles/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faTimes } from "@fortawesome/pro-regular-svg-icons";
import Button from "../../../Buttons";
import { graphqlUrl } from "../../../../Config/Env";
import NumberFormat from "react-number-format";
import TicketDate from "./TicketDate";

const SPayment = styled.div`
  ${({ theme }) => {
    return css`
      .title {
        font-size: ${theme.fonts.size.sm};
        margin-bottom: ${theme.margins.sm};
      }
      .price_content {
        padding: ${theme.paddings.base};
        .flextext {
          flex: 1;
          margin: ${theme.margins.base} 0;
          background-color: ${theme.colors.lightgray};
          border: solid 1px #ddd;
          border-radius: 5px;
          .name {
            margin-bottom: ${theme.margins.base};
            font-size: ${theme.fonts.size.lg};
            font-weight: ${theme.fonts.weight.bold};
          }
          ul {
            padding: ${theme.paddings.base};
            li {
              display: flex;
              flex-direction: row;
              padding: ${theme.paddings.sm} 0;
              .left {
                flex: 1;
                font-size: 14px;
                color: #666;
              }
              .right {
                flex: 1;
                text-align: right;
                justify-content: flex-end;
                font-size: 14px;
              }
            }
          }
          .total {
            background-color: white;
            padding: ${theme.paddings.lg} ${theme.paddings.base};
            font-size: ${theme.fonts.size.base};
            justify-content: flex-end;
            text-align: right;
            color: #444;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            b {
              padding-left: 0.5rem;
              font-weight: bold;
            }
          }
        }
      }
      .priceList_bottom {
        height: 50px;
        display: flex;
      }
      .check_list {
        margin-top: ${theme.margins.xs};
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        ::-webkit-scrollbar {
          display: none;
        }
        display: flex;
        flex-direction: row;
        li {
          display: flex;
          flex-direction: column;
          padding: ${theme.paddings.base};
          align-items: center;
          justify-content: center;
          p {
            margin-top: ${theme.margins.xs};
            font-size: ${theme.fonts.size.sm};
            font-weight: normal;
            color: ${theme.colors.default};
          }
          .close {
            background: rgba(0, 0, 0, 0.5);
            position: absolute;
            z-index: 11;
            top: -7px;
            right: -7px;
            display: flex;
            width: 12px;
            height: 12px;
            border-radius: 16px;
            align-items: center;
            justify-content: center;
            :hover {
              background: rgba(0, 0, 0, 0.6);
            }
            ::before {
              content: "\f00d";
              font-family: ${theme.fonts.family.fontAwesomeL};
              font-size: 13px;
              line-height: 0;
              color: #fff;
            }
          }
        }
        .check_list_done {
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          p {
            margin: 0 0 0 0.5rem;
            font-size: 1.3rem;
            font-weight: normal;
            color: #777;
          }
        }
      }
    `;
  }}
`;
const InputGroup = styled.div`
  ${({ theme }) => {
    return css`
      height: 40px;
      flex: 1;
      display: flex;
      font-size: 1rem;
      button {
        background-color: #fff;
        border: solid 1px #ddd;
        width: 40px;
        height: 40px;
        :first-child {
          border-right: 0;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        :last-child {
          border-left: 0;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
      input {
        flex: 1 1 auto;
        background-color: #fff;
        text-align: center;
        border: solid 1px #ddd;
        font-size: ${theme.fonts.size.base};
      }
    `;
  }}
`;

function Payment({
  history,
  match,
  location,
  users,
  setUsers,
  selectUsers,
  setSelectUsers,
  activeModal,
  setActiveModal,
  id,
  name,
  isDiscount,
  discountPrice,
  price,
  productCount,
  productPrice,
  limit,
  giftOrderProductCount,
  giftProductOptionsCount,
  giftProductOptions,
  totalPrice,
  count,
  minus,
  plus,
  onSubmit,
}) {
  return (
    <SPayment
      style={
        activeModal === "Payment" ? { display: "block" } : { display: "none" }
      }
    >
      <div className="price_content">
        <ul className="check_list" onClick={() => setActiveModal("Giftuser")}>
          {selectUsers.length > 0 ? (
            <>
              {selectUsers.map((item, index) => {
                const avatarImage =
                  item.avatar === ""
                    ? "/assets/img/product/product-default.jpg"
                    : `${graphqlUrl}${item.avatar}`;
                return (
                  <li key={index}>
                    <Roundbox>
                      <Button className="close"></Button>
                      <div className="img">
                        <img src={avatarImage} alt={item.name} />
                      </div>
                    </Roundbox>
                    <p>{item.name}님</p>
                  </li>
                );
              })}
            </>
          ) : (
            <li className="check_list_done">
              <Roundbox>
                <div className="icon"></div>
              </Roundbox>
              <div className="cont">
                <p>선물 받는분을 검색해주세요.</p>
              </div>
            </li>
          )}
        </ul>
        <TicketDate
          limit={limit}
          giftOrderProductCount={giftOrderProductCount}
          giftProductOptions={giftProductOptions}
        />
        <InputGroup>
          <button onClick={minus}>
            <FontAwesomeIcon icon={faMinus} />
          </button>
          <input value={count} readOnly={true} />
          <button onClick={plus}>
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </InputGroup>
        <div className="flextext">
          <ul>
            <li className="name">{name}</li>
            <li>
              <div className="left">금액</div>
              <div className="right">
                <NumberFormat
                  value={productPrice}
                  displayType={"text"}
                  thousandSeparator={true}
                  suffix="드림"
                />
              </div>
            </li>
            <li>
              <div className="left">수량(수신인원*수량)</div>
              <div className="right">
                <FontAwesomeIcon icon={faTimes} color={"#bbb"} />{" "}
                <NumberFormat
                  value={productCount}
                  displayType={"text"}
                  thousandSeparator={true}
                  suffix="매"
                />
              </div>
            </li>
          </ul>
          <div className="total">
            총금액
            <b>
              <NumberFormat
                value={totalPrice}
                displayType={"text"}
                thousandSeparator={true}
              />
            </b>
            드림
          </div>
        </div>
      </div>
      <Col className="price_bottom">
        <Button onClick={onSubmit} color="lightwarning" width="full">
          선물하기
        </Button>
      </Col>
    </SPayment>
  );
}

export default withRouter(Payment);
