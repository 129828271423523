import styled, { css } from "styled-components"
import { Link } from "react-router-dom"
import { useReactiveVar } from "@apollo/client"
import { isLoggedInVar, logUserOut } from "../../apollo"

const SFooter = styled.footer`
  ${({ theme }) => {
    return css`
      flex-shrink: 0;
      width: 100%;
      padding: ${theme.paddings.lg} ${theme.paddings.xl};
      ul {
        display: flex;
        li {
          color: ${theme.colors.black01};
          position: relative;
          float: left;
          padding: 0 ${theme.paddings.sm};
          font-size: ${theme.fonts.size.sm};
          color: ${theme.colors.black03};
          ::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            background-color: #cacaca;
            width: 1px;
            height: 10px;
            transform: translateY(-55%);
          }
          :first-child {
            padding-left: 0;
            ::before {
              display: none;
            }
          }
          a {
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      .info {
        margin-top: ${theme.margins.lg};
        display: block;
        color: #777;
        strong {
          font-size: ${theme.fonts.size.sm};
          font-weight: ${theme.fonts.weight.bold};
          color: ${theme.colors.black02};
        }
        .copytext {
          margin-top: ${theme.paddings.sm};
          span {
            font-size: ${theme.fonts.size.sm};
            color: ${theme.colors.black03};
            font-weight: ${theme.fonts.weight.base};
            margin-right: 5px;
          }
        }
      }
    `
  }}
`

function Footer() {
  const isLoggedIn = useReactiveVar(isLoggedInVar)
  const isApp = localStorage.getItem("isApp")
  return (
    <SFooter>
      <ul>
        {!isApp && (
          <li>
            {!isLoggedIn ? (
              <Link to="/login">로그인</Link>
            ) : (
              <Link to="#" onClick={logUserOut}>
                로그아웃
              </Link>
            )}
          </li>
        )}
        <li>
          <Link to="/provision/provision">이용약관</Link>
        </li>
        <li>
          <Link to="/provision/privacy">개인정보처리방침</Link>
        </li>
        <li>
          <Link to="/provision/thirdParty">제3자 정보제공</Link>
        </li>
      </ul>
      <div className="info">
        <strong>주식회사 한밭페이</strong>
        <div className="copytext">
          <span>
            <b>대표자</b> 이원표
          </span>{" "}
          <span>
            <b>사업자번호</b> 555-88-02087
          </span>{" "}
          <br />
          <span>
            <b>주소</b> 대전광역시 서구 도산로370번길 22-1 공간이음, 2층
          </span>{" "}
          <br />
          <span>
            <b>고객센터</b> 042-222-9114
          </span>
        </div>
      </div>
    </SFooter>
  )
}
export default Footer
