import React from "react";
import styled, { css } from "styled-components";

const colorStyles = css`
    ${({ theme, color }) => {
        const selected = theme.colors[color];
        return css`
            background: ${selected};

            ${(props) =>
                props.color === "white" &&
                css`
                    border: solid 1px #ddd;
                    color: #555;
                `}
            ${(props) =>
                props.color === "yellow" &&
                css`
                    color: #333;
                `}
              ${(props) =>
                props.color === "lightgray" &&
                css`
                    color: ${theme.colors.black04};
                `}
            ${(props) =>
                props.color === "lightprimary" &&
                css`
                    color: ${theme.colors.primary};
                `}
            ${(props) =>
                props.color === "primary" &&
                css`
                    color: ${theme.colors.white};
                    &:active,
                    &:hover {
                        background: #13249a;
                        color: ${theme.colors.white};
                    }
                `}
            ${(props) =>
                props.color === "warning" &&
                css`
                    color: ${theme.colors.white};
                    &:active,
                    &:hover {
                        background: #db6d0f;
                        color: ${theme.colors.white};
                    }
                `}
            ${(props) =>
                props.color === "lightwarning" &&
                css`
                    color: ${theme.colors.warning};
                    &:active,
                    &:hover {
                        background: ${theme.colors.warning};
                        color: ${theme.colors.white};
                    }
                `}
            ${(props) =>
                props.color === "danger" &&
                css`
                    color: ${theme.colors.white};
                    &:active,
                    &:hover {
                        background: #f46969;
                        color: ${theme.colors.white};
                    }
                `}
            ${(props) =>
                props.color === "lightdanger" &&
                css`
                    color: ${theme.colors.danger};
                    &:active,
                    &:hover {
                        background: ${theme.colors.danger};
                        color: ${theme.colors.white};
                    }
                `}
        `;
    }}
`;
const borderStyles = css`
    ${({ theme, color }) => {
        const selected = theme.colors[color];
        return css`
            ${(props) =>
                props.border === "solid" &&
                css`
                    background-color: white;
                    border: solid 1px ${selected};
                    color: ${selected};
                `}
            ${(props) =>
                props.border === "radius" &&
                css`
                    border-radius: 3rem;
                `}
        `;
    }}
`;

const StyledLabel = styled.span`
    ${({ theme }) => {
        return css`
            padding: 5px;
            font-family: ${theme.fonts.family.base};
            font-size: ${theme.fonts.size.sm};
        `;
    }}
    /* 공통 스타일 */
  border-radius: 5px;
    display: inline-flex;
    line-height: 1;
    color: white;

    /* 색상 */
    ${colorStyles}

    /*스타일 */
    ${borderStyles}
`;

function Labels({ children, color, border, ...rest }) {
    return (
        <StyledLabel border={border} color={color} {...rest}>
            {children}
        </StyledLabel>
    );
}
Labels.defaultProps = {
    color: "white",
};

export default Labels;
