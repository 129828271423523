import React, { useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
  ACCOUNT_ORDERPRODUCT_QUERY,
  ACCOUNT_ORDERPRODUCTTICKETREFUND_MUTATION,
} from "../../../../../Config/Queries";

import styled, { css } from "styled-components";
import {
  Comment,
  FooterButton,
  SOrderItem,
  SOrderInfo,
} from "../../../../../styles/styles";
import SLabel from "../../../../Labels";
import Alter from "../../../../Alter";
import Button from "../../../../Buttons";
import SendOrderProductModal from "../../../../OrderProduct/Account/OrderProduct/Detail/SendOrderProductModal";
import ReactMoment from "react-moment";
import { graphqlUrl, orderinfo } from "../../../../../Config/Env";
import { dayOfWeekName } from "../../../../../Config/Util";
import { toast } from "react-toastify";

const SSOrderItem = styled(SOrderItem)`
  ${({ theme }) => {
    return css`
      background-color: white;
      overflow: hidden;
      box-shadow: none;
      .title {
        margin: 0 0 ${theme.margins.base};
        font-size: 14px;
        font-weight: ${theme.fonts.weight.bold};
      }
      .product_list {
        display: flex;
        width: 100%;
        align-items: center;
        border-radius: 5px;
        padding: ${theme.paddings.base};
        border: solid 1px #eee;
      }
    `;
  }}
`;
const SSOrderInfo = styled(SOrderInfo)`
  ${({ theme }) => {
    return css`
      padding-top: 0;
      .flextext {
        margin-bottom: ${theme.margins.base};
      }
      .primary {
        color: ${theme.colors.primary};
      }
      .gray {
        color: ${theme.colors.gray};
      }
      .danger {
        color: ${theme.colors.danger};
      }
    `;
  }}
`;

function Ticket({ history, match, location, isApp, data }) {
  const [orderProductTicketRefundMutation] = useMutation(
    ACCOUNT_ORDERPRODUCTTICKETREFUND_MUTATION,
    {
      refetchQueries: () => [
        {
          query: ACCOUNT_ORDERPRODUCT_QUERY,
          variables: {
            id,
          },
        },
      ],
    }
  );
  const onRefund = async () => {
    console.log("onRefund");
    if (!window.confirm("선물을 환불하시겠습니까?")) {
      return;
    }

    try {
      const {
        data: { GiftAccountOrderProductTicketRefund },
      } = await orderProductTicketRefundMutation({
        variables: { id },
      });

      if (GiftAccountOrderProductTicketRefund) {
        history.replace(`/account/completeOrderProducts`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [sendOrderProductModalIsOpen, setSendOrderProductModalIsOpen] =
    useState(false);
  const sendOrderProductToggleModal = () => {
    setSendOrderProductModalIsOpen(!sendOrderProductModalIsOpen);
  };

  const {
    GiftAccountOrderProductDetail: {
      id,
      commonStatus,
      giftOrder,
      giftProduct,
      giftProductOptionDetail1,
      giftProductOptionDetail2,

      startDate,

      isRefund,

      createdAt,
    },
  } = data;

  const Uri =
    (giftProduct.productType === "Product" && `/products/${giftProduct.id}`) ||
    (giftProduct.productType === "Ticket" && `/tickets/${giftProduct.id}`);

  const AvatarImage =
    giftOrder.user.avatar === null
      ? "/assets/img/product/product-default.jpg"
      : `${graphqlUrl}${giftOrder.user.avatar}`;

  const CoverImage =
    giftProduct.coverImage === null
      ? "/assets/img/product/product-default.jpg"
      : `${graphqlUrl}${giftProduct.coverImage}`;

  const toDay = new Date();
  let ticketDatetime = new Date(
    Date.parse(
      `${giftProductOptionDetail1.name} ${giftProductOptionDetail2.name}`
    )
  );
  console.log({ ticketDatetime });
  // ticketDatetime.setHours(ticketDatetime.getHours() + 9);
  const ticketDay = ticketDatetime.getDay();

  let refundAbleDate = ticketDatetime;
  refundAbleDate.setDate(refundAbleDate.getDate() - 1);

  return (
    <>
      {giftOrder.receiver.find(
        (item) => item.id === giftOrder.user.id && giftOrder.message !== ""
      ) && (
        <Comment>
          <h4>{giftOrder.user.name}님의 선물</h4>
          <ul className="user_photo">
            <li>
              <div className="img">
                <img src={AvatarImage} alt={giftOrder.user.name} />
              </div>
            </li>
          </ul>
          <div className="massge_write">
            <p>보낸 메시지</p>
            <div className="inner">{giftOrder.message}</div>
          </div>
        </Comment>
      )}

      <SSOrderItem>
        <div className="item_content">
          <div className="title">선물내역</div>
          <div className="product_list">
            <Link to={Uri}>
              <div className="img">
                <img src={CoverImage} alt={giftProduct.name} />
              </div>
              <div className="cont">
                <span className="category">{giftProduct.store.name}</span>
                <strong>{giftProduct.name}</strong>
                <div className="option">
                  {giftProduct.giftProductOptionsCount > 0 && (
                    <>
                      <SLabel>행사시간</SLabel>{" "}
                      <ReactMoment format="YYYY. MM. DD">
                        {ticketDatetime}
                      </ReactMoment>{" "}
                      ({dayOfWeekName(ticketDay)}){" "}
                      <ReactMoment format="HH:mm">{ticketDatetime}</ReactMoment>
                    </>
                  )}
                </div>
              </div>
            </Link>
          </div>
        </div>
      </SSOrderItem>

      <SSOrderInfo>
        <div className="flextext">
          <ul>
            <li>
              <div className="left">정원</div>
              <div className="right">{giftProduct.limit}명</div>
            </li>
            <li>
              <div className="left">일정</div>
              <div className="right">{giftProduct.schedule}</div>
            </li>
            <li>
              <div className="left">장소</div>
              <div className="right ">{giftProduct.place}</div>
            </li>
            <li>
              <div className="left">주문번호</div>
              <div className="right">
                <ReactMoment format="YYMMDD-HHmmssSSS">{createdAt}</ReactMoment>
              </div>
            </li>
            <li>
              <div className="left">주최/주관</div>
              <div className="right">{giftProduct.store.name}</div>
            </li>
            <li>
              <div className="left">선물수신일자</div>
              <div className="right">
                <ReactMoment format="YYYY. MM. DD">{startDate}</ReactMoment>
              </div>
            </li>
            <li>
              <div className="left">상태</div>
              <div className="right">
                {commonStatus === "S" && (
                  <>
                    <span className="gray">사용가능</span>
                  </>
                )}
                {commonStatus === "C" && (
                  <>
                    {isRefund === true && <span className="danger">환불</span>}
                    {isRefund === false && (
                      <span className="primary">사용완료</span>
                    )}
                  </>
                )}
              </div>
            </li>
            {commonStatus === "S" && (
              <li>
                <div className="left">환불</div>
                <div className="right">
                  <>
                    {toDay <= refundAbleDate ? (
                      <Button
                        onClick={() => onRefund()}
                        color="danger"
                        border="solid"
                        size="xs"
                      >
                        환불하기
                      </Button>
                    ) : (
                      <span className="gray">기간만료</span>
                    )}
                  </>
                </div>
              </li>
            )}
          </ul>
        </div>

        <Alter color="lightgray" border="radius">
          <strong>환불 안내</strong>
          {orderinfo.TicketRefund.smallcontent}
        </Alter>
      </SSOrderInfo>

      {commonStatus === "S" && toDay <= ticketDatetime && (
        <>
          <FooterButton>
            <div className="inner">
              <Button
                color="lightprimary"
                onClick={() => {
                  if (isApp) {
                    const data = {
                      method: "payment",
                      data: { id },
                    };
                    window.ReactNativeWebView.postMessage(JSON.stringify(data));
                  } else {
                    toast.error("선물사용은 앱을 이용하여 사용할수 있습니다.");
                    return;
                  }
                }}
                width="full"
              >
                사용하기
              </Button>
              <Button
                color="lightwarning"
                onClick={sendOrderProductToggleModal}
                width="full"
              >
                보내기
              </Button>
            </div>
          </FooterButton>
          <SendOrderProductModal
            isOpen={sendOrderProductModalIsOpen}
            toggleModal={sendOrderProductToggleModal}
          />
        </>
      )}
    </>
  );
}

export default withRouter(Ticket);
