import React from "react"

import styled, { css } from "styled-components"
import { ProductInfo } from "../../../../../styles/styles"
import NumberFormat from "react-number-format"
import RowInfo from "../../../../RowInfo"

const SProductInfo = styled(ProductInfo)`
  ${({ theme }) => {
    return css`
      padding: 0;
      .flextext {
        margin: 0;
        ul {
          padding: 0;
          li {
          }
        }
      }
    `
  }}
`

function Payment({ data }) {
  const {
    GiftAccountOrderDetail: {
      isDiscount,
      discountPrice,
      price,

      giftProductOptionDetailPrice1,
      giftProductOptionDetailPrice2,
      giftProductOptionDetailPrice3,
      giftProductOptionDetailPrice4,
      giftProductOptionDetailPrice5,

      totalCount,
      totalPrice,
    },
  } = data

  let productPrice = isDiscount ? price - discountPrice : price
  productPrice += giftProductOptionDetailPrice1
  productPrice += giftProductOptionDetailPrice2
  productPrice += giftProductOptionDetailPrice3
  productPrice += giftProductOptionDetailPrice4
  productPrice += giftProductOptionDetailPrice5

  return (
    <>
      <SProductInfo>
        <RowInfo
          textLeft={"총 상품금액"}
          textRight={
            <>
              <b>
                <NumberFormat
                  displayType={"text"}
                  value={productPrice}
                  thousandSeparator={true}
                />
              </b>
              드림
            </>
          }
        />
        <RowInfo
          border={"bottom"}
          textLeft={"수량"}
          textRight={
            <>
              <b>{totalCount}</b>개
            </>
          }
        />
        <RowInfo
          total={"total"}
          textLeft={"총 결제금액"}
          textRight={
            <>
              <b>
                <NumberFormat
                  displayType={"text"}
                  value={totalPrice}
                  thousandSeparator={true}
                />
              </b>
              드림
            </>
          }
        />
      </SProductInfo>
    </>
  )
}

export default Payment
