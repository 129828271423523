import React from "react";
import styled, { css } from "styled-components";
import { withRouter } from "react-router-dom";
import ReactMoment from "react-moment";
import { graphqlUrl } from "../../../../../Config/Env";
import Label from "../../../../Labels";

export const SItem = styled.div`
  ${({ theme }) => {
    return css`
      margin-bottom: ${theme.margins.base};
      .img {
        position: relative;
        height: 0;
        padding-bottom: 100%;
        border-radius: 5px;
        overflow: hidden;
        :before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          border: 1px solid rgba(0, 0, 0, 0.02);
        }
        img {
          max-width: 100%;
          display: block;
          width: 100%;
          height: auto;
        }
      }
      .complete_icon {
        position: absolute;
        background-color: rgba(255, 255, 255, 0.8);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 99;
        display: flex;
        justify-content: center;
        align-items: center;
        div {
          position: relative;
          transform: rotate(-30deg);
          width: 64px;
          height: 64px;
          border: solid 2px #555;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          color: #777;
          ::before {
            content: "";
            position: absolute;
            width: 54px;
            height: 54px;
            z-index: 99;
            border-radius: 50%;
            border: solid 1px #bbb;
          }
        }
      }
      .status_ready {
        position: absolute;
        top: 5px;
        left: 5px;
        color: ${theme.colors.gray};
      }
      .cont {
        margin-top: 10px;
        .category {
          color: ${theme.colors.gray};
          font-size: 1.1rem;
          font-weight: 400;
        }
        h3 {
          margin-top: ${theme.margins.sm};
          height: 40px;
          overflow: hidden;
          font-size: 15px;
          color: ${theme.colors.default};
          font-weight: ${theme.fonts.weight.medium};
          line-height: 1.3;
        }
      }
      .name {
        margin-top: ${theme.margins.base};
        font-size: ${theme.fonts.size.sm};
        color: #444;
        font-size: ${theme.fonts.size.sm};
      }
      .date {
        margin-top: ${theme.margins.sm};
        color: ${theme.colors.gray};
        font-size: ${theme.fonts.size.sm};
      }
    `;
  }}
`;

function Item({ history, match, location, item }) {
  const CoverImage =
    item.giftProduct.coverImage === null
      ? "/assets/img/product/product-default.jpg"
      : `${graphqlUrl}${item.giftProduct.coverImage}`;

  const toDay = new Date();
  const ticketDatetime = new Date(
    Date.parse(
      `${item.giftProductOptionDetail1.name} ${item.giftProductOptionDetail2.name}`
    )
  );
  const gap = ticketDatetime.getTime() - toDay.getTime();
  const dDay = (Math.ceil(gap / (1000 * 60 * 60 * 24)) - 1) * -1;

  return (
    <SItem>
      <div className="img">
        <img src={CoverImage} alt={item.giftProduct.name} />
        {item.commonStatus === "S" && (
          <Label className="status_ready">행사일 {dDay}일</Label>
        )}
        {item.commonStatus === "C" && (
          <div className="complete_icon">
            <div>
              {item.isRefund === true && (
                <span>
                  환불
                  <br />
                  완료
                </span>
              )}
              {item.isRefund === false && (
                <span>
                  사용
                  <br />
                  완료
                </span>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="cont">
        <span className="category">{item.giftProduct.giftCategory.name}</span>
        <h3>{item.giftProduct.name}</h3>
        <div className="info">
          <div className="name">From. {item.giftOrder.user.name}</div>
          <div className="date">
            <ReactMoment format="YYYY.MM.DD HH:mm:ss">
              {item.createdAt}
            </ReactMoment>
          </div>
        </div>
      </div>
    </SItem>
  );
}
export default withRouter(Item);
