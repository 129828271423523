import React from "react"
import { withRouter, Link } from "react-router-dom"

import styled, { css } from "styled-components"
import { SOrderItem } from "../../../../styles/styles"
import NumberFormat from "react-number-format"
import { graphqlUrl } from "../../../../Config/Env"
import IsWishlist from "./IsWishlist"

const SSOrderItem = styled(SOrderItem)`
  ${({ theme }) => {
    return css`
      margin-bottom: ${theme.margins.base};
      .product_list {
        flex-direction: row;

        .img {
          width: 90px;
          height: 90px;
          border-radius: 8px;
        }
        .discount {
          margin-top: 10px;
          color: ${theme.colors.danger};
          font-size: 14px;
          span {
            color: ${theme.colors.black04};
            font-size: 13px;
            text-decoration: line-through;
          }
        }
        .cont {
          strong {
            width: 150px;
          }
        }
        .price {
          font-size: 15px;
          color: #333;
          font-weight: 500;
        }
      }
    `
  }}
`

function Item({ history, match, location, item }) {
  const CoverImage =
    item.giftProduct.coverImage === null
      ? "/assets/img/product/product-default.jpg"
      : `${graphqlUrl}${item.giftProduct.coverImage}`
  const Percent = parseInt(
    (item.giftProduct.discountPrice / item.giftProduct.price) * 100
  )
  const Uri =
    (item.giftProduct.productType === "Product" &&
      `/products/${item.giftProduct.id}${location.search}`) ||
    (item.giftProduct.productType === "Ticket" &&
      `/tickets/${item.giftProduct.id}${location.search}`)

  return (
    <>
      <SSOrderItem>
        <div className="item_content">
          <div className="product_list">
            <Link to={Uri}>
              <div className="img">
                <img
                  src={CoverImage}
                  alt={item.giftProduct.giftCategory.name}
                />
              </div>
              <div className="cont">
                <span className="category">
                  {item.giftProduct.giftCategory.name}
                </span>
                <strong>{item.giftProduct.name}</strong>
                {item.giftProduct.isDiscount ? (
                  <>
                    <div className="discount">
                      <b>{Percent}</b>%{" "}
                      <span>
                        <NumberFormat
                          value={item.giftProduct.price}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                        드림
                      </span>
                    </div>
                    <div className="price">
                      <b>
                        <NumberFormat
                          value={
                            item.giftProduct.price -
                            item.giftProduct.discountPrice
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </b>
                      드림
                    </div>
                  </>
                ) : (
                  <>
                    <div className="price">
                      <b>
                        <NumberFormat
                          value={item.giftProduct.price}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </b>
                      드림
                    </div>
                  </>
                )}
              </div>
            </Link>
            <IsWishlist id={item.giftProduct.id} />
          </div>
        </div>
      </SSOrderItem>
    </>
  )
}

export default withRouter(Item)
