import { Link } from "react-router-dom";

import { Ready } from "../../../styles/styles";
import styled, { css } from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";
import { graphqlUrl } from "../../../Config/Env";

const SSwiper = styled(Swiper)`
    ${({ theme }) => {
        return css`
            padding: 0 ${theme.paddings.xl};
        `;
    }}
`;
const CateItems = styled.div`
    ${({ theme }) => {
        return css`
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                font-family: ${theme.fonts.family.base};
                .round_box {
                    background-color: ${theme.colors.lightwarning};
                    display: flex;
                    width: 58px;
                    height: 58px;
                    align-items: center;
                    justify-content: center;
                    border-radius: 8px;
                    overflow: hidden;
                    img {
                        max-width: 100%;
                    }
                }
                p {
                    margin-top: 5px;
                    color: ${theme.colors.black01};
                    font-weight: ${theme.fonts.weight.medium};
                    font-size: 14px;
                    text-align: center;
                    b {
                        color: ${theme.colors.warning};
                        font-weight: ${theme.fonts.weight.bold};
                        margin-left: 2px;
                    }
                }
            }
        `;
    }}
`;
SwiperCore.use([Pagination, Navigation]);

function Category({ giftCategories }) {
    return (
        <SSwiper spaceBetween={10} slidesPerView={4.5}>
            {giftCategories ? (
                <>
                    {giftCategories.map((item, index) => {
                        const iconImage =
                            item.iconImage === "" || item.iconImage === null
                                ? "/assets/img/category-default.png"
                                : `${graphqlUrl}${item.iconImage}`;
                        return (
                            <SwiperSlide key={index}>
                                <CateItems>
                                    <Link to={`/products?depth1=${item.id}`}>
                                        <div className="round_box">
                                            <img
                                                src={iconImage}
                                                alt={item.name}
                                            />
                                        </div>
                                        <p>
                                            {item.name}
                                            <b>{item.giftProductsCount}</b>
                                        </p>
                                    </Link>
                                </CateItems>
                            </SwiperSlide>
                        );
                    })}
                </>
            ) : (
                <Ready>
                    <div className="list_none">등록된 상품분류가 없습니다.</div>
                </Ready>
            )}
        </SSwiper>
    );
}

export default Category;
