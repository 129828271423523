import { useState } from "react";
import { withRouter } from "react-router-dom";

import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faCheck } from "@fortawesome/pro-regular-svg-icons";
import Giftuser from "./Giftuser";
import Payment from "./Payment";
import { toast } from "react-toastify";
import { ModalLayout } from "../../../Layouts/Layout";

const Stepbox = styled.div`
    ${({ theme }) => {
        return css`
            .btn {
                display: flex;
                width: 100%;
                padding: 1.2rem ${theme.paddings.sm};
                border-bottom: solid 1px #ddd;
                font-size: 1.4rem;
                color: #bbb;
                span {
                    flex: 1;
                    text-align: left;
                }
                &.active {
                    background-color: ${theme.colors.lightprimary};
                    font-weight: ${theme.fonts.weight.medium};
                    color: ${theme.colors.primary};
                    .icon {
                        color: ${theme.colors.primary};
                    }
                }
            }
        `;
    }}
`;
const Stepbtn = styled.div`
    ${({ theme }) => {
        return css`
            display: flex;
            flex-direction: row;
            border-bottom: solid 1px #eee;
            button {
                position: relative;
                background-color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                padding: 1.2rem ${theme.paddings.sm};
                font-size: 1.4rem;
                color: #bbb;
                &.active {
                    span {
                        color: ${theme.colors.black01};
                        font-size: ${theme.fonts.size.sm};
                        font-weight: ${theme.fonts.weight.bold};
                        ::before {
                            content: "";
                            position: absolute;
                            left: 24px;
                            right: 24px;
                            bottom: 0;
                            border-bottom: solid 2px ${theme.colors.black01};
                        }
                    }
                }
            }
        `;
    }}
`;

function Modal({
    history,
    match,
    location,
    isOpen,
    toggleModal,
    id,
    name,
    isDiscount,
    discountPrice,
    price,
    giftProductOptionsCount,
    giftProductOptions,
}) {
    const [activeModal, setActiveModal] = useState("Giftuser");

    const [users, setUsers] = useState([]);
    const [selectUsers, setSelectUsers] = useState([]);

    const [productCount, setProductCount] = useState(1);
    const [productPrice, setProductPrice] = useState(price);

    // GiftOrder
    const [totalProductPrice, setTotalProductPrice] = useState(price);
    // const [totalDiscountPrice, setTotalDiscountPrice] = useState(discountPrice);
    // const [totalCount, setTotalCount] = useState(1);
    const [totalPrice, setTotalPrice] = useState(totalProductPrice);

    // GiftOrderProduct
    // isDiscount
    const [giftProductOptionDetail1, setGiftProductOptionDetail1] =
        useState(null);
    const [giftProductOptionDetailPrice1, setGiftProductOptionDetailPrice1] =
        useState(0);
    const [giftProductOptionDetail2, setGiftProductOptionDetail2] =
        useState(null);
    const [giftProductOptionDetailPrice2, setGiftProductOptionDetailPrice2] =
        useState(0);
    const [giftProductOptionDetail3, setGiftProductOptionDetail3] =
        useState(null);
    const [giftProductOptionDetailPrice3, setGiftProductOptionDetailPrice3] =
        useState(0);
    const [giftProductOptionDetail4, setGiftProductOptionDetail4] =
        useState(null);
    const [giftProductOptionDetailPrice4, setGiftProductOptionDetailPrice4] =
        useState(0);
    const [giftProductOptionDetail5, setGiftProductOptionDetail5] =
        useState(null);
    const [giftProductOptionDetailPrice5, setGiftProductOptionDetailPrice5] =
        useState(0);
    // price
    const [count, setCount] = useState(1);

    const userSelect = (user) => {
        const userIds =
            users.indexOf(user.id) > -1
                ? [...users.filter((item) => item !== user.id)]
                : [...users, user.id];
        const userObjects =
            users.indexOf(user.id) > -1
                ? [...selectUsers.filter((item) => item.id !== user.id)]
                : [...selectUsers, user];

        setUsers(userIds);
        setSelectUsers(userObjects);
        setProductCount(userIds.length);

        totalSum({
            userLength: userIds.length,
            optionPrice1: giftProductOptionDetailPrice1,
            optionPrice2: giftProductOptionDetailPrice2,
            optionPrice3: giftProductOptionDetailPrice3,
            optionPrice4: giftProductOptionDetailPrice4,
            optionPrice5: giftProductOptionDetailPrice5,
            price,
            count,
        });
    };
    const userUnSelect = (user) => {
        const userIds = [...users.filter((item) => item !== user.id)];
        const userObjects = [
            ...selectUsers.filter((item) => item.id !== user.id),
        ];

        setUsers(userIds);
        setSelectUsers(userObjects);
        setProductCount(userIds.length);

        totalSum({
            userLength: userIds.length,
            optionPrice1: giftProductOptionDetailPrice1,
            optionPrice2: giftProductOptionDetailPrice2,
            optionPrice3: giftProductOptionDetailPrice3,
            optionPrice4: giftProductOptionDetailPrice4,
            optionPrice5: giftProductOptionDetailPrice5,
            price,
            count,
        });
    };
    const optionSelect = (index, value) => {
        const giftProductOption = giftProductOptions[
            index
        ].giftProductOptionDetails.find((item) => item.id === value);
        const optionId = giftProductOption ? giftProductOption.id : null;
        const optionPrice = giftProductOption ? giftProductOption.price : 0;

        eval(`setGiftProductOptionDetail${index + 1}(optionId)`);
        eval(`setGiftProductOptionDetailPrice${index + 1}(optionPrice)`);

        totalSum({
            userLength: users.length,
            optionPrice1:
                index === 0 ? optionPrice : giftProductOptionDetailPrice1,
            optionPrice2:
                index === 1 ? optionPrice : giftProductOptionDetailPrice2,
            optionPrice3:
                index === 2 ? optionPrice : giftProductOptionDetailPrice3,
            optionPrice4:
                index === 3 ? optionPrice : giftProductOptionDetailPrice4,
            optionPrice5:
                index === 4 ? optionPrice : giftProductOptionDetailPrice5,
            price,
            count,
        });
    };
    const minus = () => {
        if (count <= 1) return;
        setCount(count - 1);
        setTotalProductPrice((count - 1) * price);
        // setTotalDiscountPrice((count - 1) * discountPrice);

        setProductCount((count - 1) * users.length);
        // setTotalCount(count - 1);
        setTotalPrice((count - 1) * price);
        totalSum({
            userLength: users.length,
            optionPrice1: giftProductOptionDetailPrice1,
            optionPrice2: giftProductOptionDetailPrice2,
            optionPrice3: giftProductOptionDetailPrice3,
            optionPrice4: giftProductOptionDetailPrice4,
            optionPrice5: giftProductOptionDetailPrice5,
            price,
            count: count - 1,
        });
    };
    const plus = () => {
        setCount(count + 1);
        setTotalProductPrice((count + 1) * price);
        // setTotalDiscountPrice((count + 1) * discountPrice);

        setProductCount((count + 1) * users.length);
        // setTotalCount(count + 1);
        setTotalPrice((count + 1) * price);
        totalSum({
            userLength: users.length,
            optionPrice1: giftProductOptionDetailPrice1,
            optionPrice2: giftProductOptionDetailPrice2,
            optionPrice3: giftProductOptionDetailPrice3,
            optionPrice4: giftProductOptionDetailPrice4,
            optionPrice5: giftProductOptionDetailPrice5,
            price,
            count: count + 1,
        });
    };
    const totalSum = ({
        userLength,
        optionPrice1,
        optionPrice2,
        optionPrice3,
        optionPrice4,
        optionPrice5,
        price,
        count,
    }) => {
        const productPrice =
            price +
            optionPrice1 +
            optionPrice2 +
            optionPrice3 +
            optionPrice4 +
            optionPrice5;
        const totalPrice =
            (price +
                optionPrice1 +
                optionPrice2 +
                optionPrice3 +
                optionPrice4 +
                optionPrice5) *
            count *
            userLength;

        setProductPrice(productPrice);
        setTotalPrice(totalPrice);
    };

    const onSubmit = () => {
        if (giftProductOptionsCount > 0 && !giftProductOptionDetail1) {
            toast.error(`${giftProductOptions[0].name} 옵션을 선택해주세요.`);
            return;
        }
        if (giftProductOptionsCount > 1 && !giftProductOptionDetail2) {
            toast.error(`${giftProductOptions[1].name} 옵션을 선택해주세요.`);
            return;
        }
        if (giftProductOptionsCount > 2 && !giftProductOptionDetail3) {
            toast.error(`${giftProductOptions[2].name} 옵션을 선택해주세요.`);
            return;
        }
        if (giftProductOptionsCount > 3 && !giftProductOptionDetail4) {
            toast.error(`${giftProductOptions[3].name} 옵션을 선택해주세요.`);
            return;
        }
        if (giftProductOptionsCount > 4 && !giftProductOptionDetail5) {
            toast.error(`${giftProductOptions[4].name} 옵션을 선택해주세요.`);
            return;
        }

        history.push(`/order`, {
            productType: "Product",
            orderType: "gift",
            users,
            selectUsers,
            id,
            giftProductOptionDetail1,
            giftProductOptionDetail2,
            giftProductOptionDetail3,
            giftProductOptionDetail4,
            giftProductOptionDetail5,
            count,
        });
    };

    return (
        <ModalLayout
            isOpen={isOpen}
            toggleModal={toggleModal}
            title={"선물하기"}
        >
            <Stepbtn>
                <button
                    onClick={() => setActiveModal("Giftuser")}
                    className={` ${activeModal === "Giftuser" ? "active" : ""}`}
                >
                    <span>수신자</span>
                </button>
                <button
                    className={` ${activeModal === "Payment" ? "active" : ""}`}
                >
                    <span>옵션선택</span>
                </button>
            </Stepbtn>
            <Stepbox>
                <Giftuser
                    users={users}
                    setUsers={setUsers}
                    selectUsers={selectUsers}
                    setSelectUsers={setSelectUsers}
                    activeModal={activeModal}
                    setActiveModal={setActiveModal}
                    userSelect={userSelect}
                    userUnSelect={userUnSelect}
                />
            </Stepbox>
            <Stepbox>
                <Payment
                    users={users}
                    setUsers={setUsers}
                    selectUsers={selectUsers}
                    setSelectUsers={setSelectUsers}
                    activeModal={activeModal}
                    setActiveModal={setActiveModal}
                    id={id}
                    name={name}
                    isDiscount={isDiscount}
                    discountPrice={discountPrice}
                    price={price}
                    productCount={productCount}
                    productPrice={productPrice}
                    giftProductOptionsCount={giftProductOptionsCount}
                    giftProductOptions={giftProductOptions}
                    totalPrice={totalPrice}
                    count={count}
                    optionSelect={optionSelect}
                    minus={minus}
                    plus={plus}
                    onSubmit={onSubmit}
                />
            </Stepbox>
        </ModalLayout>
    );
}

export default withRouter(Modal);
