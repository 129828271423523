import React from "react"
import { Link } from "react-router-dom"

import { Layout } from "../../../Components/Layouts/Layout"
import PageTitle from "../../../Components/Layouts/PageTitle"
import styled, { css } from "styled-components"
import { Container, Row, Col, Ready, SortOption } from "../../../styles/styles"
import Dropdown from "react-dropdown"
import "react-dropdown/style.css"
import SLoading from "../../../Components/Loading"
import Category from "../../../Components/StoreCategory/List/Category"
import Item from "../../../Components/Store/List/Item"
import Pagination from "../../../Components/Pagination"

const Sort = styled(Row)`
  ${({ theme }) => {
    return css`
      padding: ${theme.paddings.base} ${theme.paddings.xl};
      font-size: ${theme.fonts.size.base};
      .left {
        align-items: center;
        span {
          font-weight: ${theme.fonts.weight.medium};
          color: ${theme.colors.black01};
          b {
            color: ${theme.colors.warning};
          }
        }
      }
      .right {
        flex: 0 0 auto;
        justify-content: flex-end;
      }
    `
  }}
`
const SItemlist = styled.div`
  ${({ theme }) => {
    return css`
      ul {
        display: flex;
        flex-wrap: wrap;
        padding: 0 ${theme.paddings.base};
        li {
          display: flex;
          flex: 0 0 25%;
          padding: 0 ${theme.paddings.sm};
          margin-bottom: ${theme.margins.lg};
        }
      }
    `
  }}
`

function List({
  loading,
  data,
  page,
  orderByOption,
  orderByDefault,
  orderByChange,
  blockSize,
  first,
  baseUrl,
}) {
  return (
    <>
      <Layout isNav={true} isFooter={true}>
        <PageTitle title="가게" />
        <Container>
          <Category />
          <Sort>
            <Col className="left">
              <span>
                가게 <b>{!loading && data.GiftStoreList.count}</b>곳
              </span>
            </Col>
            <Col className="right">
              <SortOption>
                <Dropdown
                  options={orderByOption}
                  value={orderByDefault}
                  onChange={(item) => {
                    orderByChange(item)
                  }}
                />
              </SortOption>
            </Col>
          </Sort>
          {!loading ? (
            <>
              {data.GiftStoreList.count > 0 ? (
                <>
                  <SItemlist>
                    <ul>
                      {data.GiftStoreList.stores.map((item, index) => {
                        return (
                          <li key={index}>
                            <Link to={`/stores/${item.id}`}>
                              <Item item={item} />
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  </SItemlist>
                  <Pagination
                    totalRecord={data.GiftStoreList.count}
                    blockSize={blockSize}
                    pageSize={first}
                    currentPage={page}
                    baseUrl={baseUrl}
                  />
                </>
              ) : (
                <Ready>
                  <div className="list_none">등록된 가게가 없습니다.</div>
                </Ready>
              )}
            </>
          ) : (
            <>
              <SLoading />
            </>
          )}
        </Container>
      </Layout>
    </>
  )
}
export default List
